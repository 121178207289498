import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import firebase from 'firebase';
import { User } from '../../types/User';

export type AuthInitializationState = 'not_initialized' | 'initializing' | 'initialized' | 'error';

const initialState = {
    initializationState: 'not_initialized' as AuthInitializationState,
    databaseUser: null as User | null,
    firebaseUser: null as firebase.User | null,
    loading: false,
};

export type AuthAppState = typeof initialState;

export const slice = createSlice<
    AuthAppState,
    {
        setInitializing: CaseReducer<AuthAppState>;
        setInitialized: CaseReducer<
            AuthAppState,
            PayloadAction<{ databaseUser: User | null; firebaseUser: any | null }>
        >;
        setErrorInitializing: CaseReducer<AuthAppState>;
        setDatabaseUser: CaseReducer<AuthAppState, PayloadAction<User | null>>;
        setLoading: CaseReducer<AuthAppState, PayloadAction<boolean>>;
        setPhotoURL: CaseReducer<AuthAppState, PayloadAction<any>>;
    }
>({
    name: 'auth',
    initialState,
    reducers: {
        setInitializing: (state) => {
            state.initializationState = 'initializing';
        },
        setInitialized: (state, { payload }) => {
            state.initializationState = 'initialized';
            state.databaseUser = payload.databaseUser;
            state.firebaseUser = payload.firebaseUser;
        },

        setPhotoURL: (state, { payload }) => {
            state.databaseUser = { ...state.databaseUser, profileImage: payload } as User;
            state.firebaseUser = { ...state.firebaseUser, photoURL: payload } as any;
        },
        setErrorInitializing: (state) => {
            state.initializationState = 'error';
        },
        setDatabaseUser: (state, { payload }) => {
            state.databaseUser = payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const useAuthSelector = buildSubStateSelector<AuthAppState>((state) => state.auth);
export const authActions = slice.actions;
export const authReducer = slice.reducer;
