import React, { useCallback, useEffect, useState } from 'react';

import styles from './DrawingCanvas.module.scss';

interface CanvasProps {
    index: number;
    onPaint: () => void;
    isEnabled: boolean;
    paintColor: string;
}

type Coordinate = {
    x: MouseEvent['clientX'];
    y: MouseEvent['clientY'];
};

const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    return { x: event.clientX - rect.left, y: event.clientY - rect.top };
};

export const DrawingCanvas = React.forwardRef<HTMLCanvasElement, CanvasProps>(
    (props: CanvasProps, ref: any) => {
        const { isEnabled } = props;
        const canvasRef = ref;
        const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(undefined);

        const startPaint = useCallback(
            (event: MouseEvent) => {
                if (!isEnabled) return;
                const coordinates = getCoordinates(event);
                if (coordinates) {
                    setMousePosition(coordinates);
                }
            },
            [isEnabled]
        );

        useEffect(() => {
            if (!canvasRef || !canvasRef.current) return;

            const canvas: HTMLCanvasElement = canvasRef.current;
            canvas.addEventListener('mousedown', startPaint);
            return () => {
                canvas.removeEventListener('mousedown', startPaint);
            };
        }, [startPaint, canvasRef]);

        const paint = useCallback(
            (event: MouseEvent) => {
                const newMousePosition = getCoordinates(event);
                if (mousePosition && newMousePosition) {
                    if (!canvasRef || !canvasRef.current) return;

                    const canvas: HTMLCanvasElement = canvasRef.current;
                    const context = canvas.getContext('2d');
                    if (context) {
                        context.strokeStyle = props.paintColor;
                        context.lineJoin = 'round';
                        context.lineWidth = 5;

                        context.beginPath();
                        context.moveTo(mousePosition.x, mousePosition.y);
                        context.lineTo(newMousePosition.x, newMousePosition.y);
                        context.closePath();

                        context.stroke();
                        props.onPaint();
                    }

                    setMousePosition(newMousePosition);
                }
            },
            [mousePosition, canvasRef, props]
        );

        useEffect(() => {
            if (!canvasRef || !canvasRef.current) return;

            const canvas: HTMLCanvasElement = canvasRef.current;
            canvas.addEventListener('mousemove', paint);
            return () => {
                canvas.removeEventListener('mousemove', paint);
            };
        }, [paint, canvasRef]);

        const exitPaint = useCallback(() => {
            setMousePosition(undefined);
        }, []);

        useEffect(() => {
            if (!canvasRef || !canvasRef.current) return;

            const canvas: HTMLCanvasElement = canvasRef.current;
            canvas.addEventListener('mouseup', exitPaint);
            canvas.addEventListener('mouseleave', exitPaint);
            return () => {
                canvas.removeEventListener('mouseup', exitPaint);
                canvas.removeEventListener('mouseleave', exitPaint);
            };
        }, [exitPaint, canvasRef]);

        return (
            <canvas
                ref={canvasRef}
                style={{ zIndex: props.index }}
                className={styles.drawingCanvas}
                id="drawingCanvas"
            />
        );
    }
);
