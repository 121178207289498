import React from 'react';

import styles from './InboxTabContainer.module.scss';
import { routeUrls } from '../../utils/constants';
import { Link } from '../link/Link';
import { useLocation } from 'react-router-dom';
import { useInboxSelector } from '../../features/inbox/inboxSlice';
import { getUnreadOrganizationInboxCount, getUnreadPersonalInboxCount } from '../../utils/helpers';

export const InboxTabContainer = () => {
    const location = useLocation();
    const inboxData = useInboxSelector((state) => state);
    const unreadPersonalInboxCount = getUnreadPersonalInboxCount(inboxData);
    const unreadOrganizationInboxCount = getUnreadOrganizationInboxCount(inboxData);

    return (
        <div className={styles.tabContainer}>
            <Link unstyled to={routeUrls.inbox}>
                <label
                    className={
                        location.pathname === routeUrls.inbox ? styles.active : styles.inActive
                    }
                >
                    Personal Inbox{' '}
                    {unreadPersonalInboxCount && unreadPersonalInboxCount > 0 ? (
                        <>({unreadPersonalInboxCount})</>
                    ) : (null)}
                </label>
            </Link>
            <Link unstyled to={routeUrls.inboxOrg}>
                <label
                    className={
                        location.pathname !== routeUrls.inbox ? styles.active : styles.inActive
                    }
                >
                    Organizations{' '}
                    {unreadOrganizationInboxCount && unreadOrganizationInboxCount > 0 ? (
                        <>({unreadOrganizationInboxCount})</>
                    ) : (null)}
                </label>
            </Link>
        </div>
    );
};
