import React, { useState } from 'react';
import { Input, InputProps, InputOnChangeData } from 'semantic-ui-react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/icon.min.css';

import styles from './TextInput.module.scss';

export const TextInput: React.FunctionComponent<InputProps> = (props) => {
    const {
        placeholder = '',
        type = 'text',
        icon,
        onChange = () => null,
        ...passthroughProps
    } = props;

    const [hasText, setHasText] = useState(!!props.value);
    // Generally this won't change, but for password fields we need to change it to text to show the password
    const [currentInputType, setCurrentInputType] = useState(type);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        onChange(event, data);
        // We need to know if the input has text so that we can keep the placeholder small even when the input is unfocused
        setHasText(!!data.value);
    };

    let useIcon = null;
    if (icon) {
        useIcon = icon;
    } else if (type === 'password') {
        if (currentInputType === 'password') {
            useIcon = (
                <i
                    className={`icon ${styles.passwordIcon}`}
                    onClick={() => setCurrentInputType('text')}
                >
                    <FiEye size={24} />
                </i>
            );
        } else {
            useIcon = (
                <i
                    className={`icon ${styles.passwordIcon}`}
                    onClick={() => setCurrentInputType('password')}
                >
                    <FiEyeOff size={24} />
                </i>
            );
        }
    }

    return (
        <div className={styles.outerContainer}>
            <Input
                className={styles.innerContainer}
                onChange={handleChange}
                data-has-text={hasText}
                type={currentInputType}
                icon={useIcon}
                readOnly={props.readOnly}
                {...passthroughProps}
            />
            {!!placeholder && <span className={styles.floatingLabel}>{placeholder}</span>}
        </div>
    );
};
