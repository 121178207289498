import React, { Component, createRef, RefObject } from 'react';
import { FiTrash } from 'react-icons/fi';
import { connect, DispatchProp } from 'react-redux';
import { Button } from '../../components/button/Button';
import { RoundIconButton } from '../../components/roundIconButton/RoundIconButton';
import { SendVideoModal } from '../../components/sendVideoModal/SendVideoModal';
import { Spacer } from '../../components/spacer/Spacer';
import { Spinner } from '../../components/spinner/Spinner';
import { AppState } from '../../store/appState';
import { routeUrls } from '../../utils/constants';
import { CanvasElement } from '../../utils/types';
import { DeleteModal } from '../videoAnnotate/DeleteModal';
import styles from './Record.module.scss';
import { recordActions, RecordAppState } from './recordSlice';

const SECOND_IN_MS = 1000;
const FRAMES_PER_SECOND = 30;
const FRAMERATE_IN_MS = SECOND_IN_MS / FRAMES_PER_SECOND;

const {
    setIsRecording,
    setIsRecordingInitialized,
    setIsReviewing,
    setIsInitializingWebcam,
    setIsDeleteModalOpen,
    resetState,
    setIsDownloadSendModalOpen,
} = recordActions;

interface VideoRecordLocalState {
    chunks: Blob[];
    videoUrl: string;
}

class VideoRecord extends Component<RecordAppState & DispatchProp, VideoRecordLocalState> {
    webcamVideoDisplay: RefObject<HTMLVideoElement>;
    webcamVideoCanvas: RefObject<CanvasElement>;
    playbackVideoDisplay: RefObject<HTMLVideoElement>;
    webcamVideoDisplayContainer: RefObject<HTMLDivElement>;
    pageRef: RefObject<HTMLDivElement>;

    mediaRecorder?: MediaRecorder;
    webcamStream?: MediaStream;

    drawInterval?: NodeJS.Timeout;

    navigateToFeedback: Boolean;

    constructor(props: any) {
        super(props);
        this.webcamVideoDisplay = createRef();
        this.webcamVideoCanvas = createRef();
        this.playbackVideoDisplay = createRef();
        this.webcamVideoDisplayContainer = createRef();
        this.pageRef = createRef();
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        this.navigateToFeedback = params['feedback'] != null && params['feedback'] === 'true';

        this.state = {
            chunks: [],
            videoUrl: '',
        };

        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.handleReviewAndSendClick = this.handleReviewAndSendClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.deleteRecording = this.deleteRecording.bind(this);
    }

    handleRecordClick() {
        const newIsRecording = !this.props.isRecording;
        if (newIsRecording && this.mediaRecorder) {
            if (this.mediaRecorder.state === 'paused') {
                this.mediaRecorder.resume();
            } else {
                this.mediaRecorder.start();
            }
            this.props.dispatch(setIsRecordingInitialized(true));
        } else {
            this.mediaRecorder!.pause();
        }
        this.props.dispatch(setIsRecording(newIsRecording));
    }

    handleReviewAndSendClick() {
        this.mediaRecorder!.stop();
        this.props.dispatch(setIsRecording(false));
        this.props.dispatch(setIsRecordingInitialized(false));
        this.props.dispatch(setIsReviewing(true));
    }

    handleSaveClick() {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('feedback')) {
            // @ts-ignore
            this.props.history.push({
                pathname: routeUrls.feedback,
                state: {
                    sourceVideoUrl: this.state.videoUrl,
                    fileType: 'video',
                },
            });
            return;
        }
        this.props.dispatch(setIsDownloadSendModalOpen(true));
    }

    deleteRecording() {
        this.props.dispatch(setIsRecordingInitialized(false));
        this.props.dispatch(setIsReviewing(false));
        this.props.dispatch(setIsRecording(false));
        if (this.mediaRecorder!.state === 'recording') {
            this.mediaRecorder!.stop();
        }
        this.setState({ chunks: [] });
        if (this.playbackVideoDisplay.current!.src) {
            URL.revokeObjectURL(this.playbackVideoDisplay.current!.src);
        }
    }

    componentDidMount() {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((webcamStream) => {
                this.webcamStream = webcamStream;
                this.webcamVideoDisplay.current!.srcObject = webcamStream;
                this.webcamVideoDisplay.current!.onloadedmetadata = (e) => {
                    const videoWidth = this.webcamVideoDisplay.current!.videoWidth;
                    const videoHeight = this.webcamVideoDisplay.current!.videoHeight;
                    const pageWidth = this.pageRef.current!.clientWidth;
                    const pageHeight = this.pageRef.current!.clientHeight;

                    const maxWidthScale = (pageWidth * 0.8) / videoWidth;
                    const maxHeightScale = (pageHeight * 0.8) / videoHeight;
                    const scale = Math.min(maxWidthScale, maxHeightScale);
                    const useWidth = videoWidth * scale;
                    const useHeight = videoHeight * scale;
                    this.webcamVideoDisplayContainer.current!.style.width = useWidth + 'px';
                    this.webcamVideoDisplayContainer.current!.style.height = useHeight + 'px';
                    this.webcamVideoCanvas.current!.width = useWidth;
                    this.webcamVideoCanvas.current!.height = useHeight;
                    this.props.dispatch(setIsInitializingWebcam(false));

                    const context = this.webcamVideoCanvas.current?.getContext('2d');
                    context?.translate(context.canvas.width, 0);
                    context?.scale(-1, 1);
                    this.webcamVideoDisplay.current?.addEventListener('play', (ev) => {
                        this.drawInterval = setInterval(() => {
                            context?.drawImage(
                                this.webcamVideoDisplay.current!,
                                0,
                                0,
                                context!.canvas.width,
                                context!.canvas.height
                            );
                        }, FRAMERATE_IN_MS);
                    });

                    this.webcamVideoDisplay.current!.play();
                };
                this.webcamVideoCanvas.current!.getContext('2d');

                // merge audio from remote stream and micStream
                const audioCtx = new AudioContext();
                const micInput = audioCtx.createMediaStreamSource(webcamStream);
                if (webcamStream && webcamStream.getAudioTracks().length) {
                    audioCtx.createMediaStreamSource(webcamStream);
                }
                const destination = audioCtx.createMediaStreamDestination();
                micInput.connect(destination);

                const outputStream = new MediaStream();
                outputStream.addTrack(destination.stream.getAudioTracks()[0]);
                outputStream.addTrack(
                    this.webcamVideoCanvas.current!.captureStream(30).getVideoTracks()[0]
                );

                this.mediaRecorder = new MediaRecorder(outputStream, {
                    audioBitsPerSecond: 128000,
                    videoBitsPerSecond: 4000000,
                    mimeType: 'video/webm;codecs=h264',
                });
                this.mediaRecorder.ondataavailable = (e: BlobEvent) => {
                    this.setState((state) => ({ chunks: [...state.chunks, e.data] }));
                };
                this.mediaRecorder.onstop = (e) => {
                    const blob = new Blob(this.state.chunks, { type: 'video/mp4' });
                    this.setState({ chunks: [] });
                    const videoUrl = window.URL.createObjectURL(blob);
                    this.setState({ videoUrl });
                    if (this.playbackVideoDisplay.current) {
                        this.playbackVideoDisplay.current!.src = videoUrl;
                    }
                };
            })
            .catch((error) => {
                console.error(error);
                this.props.dispatch(setIsInitializingWebcam(false));
            });
    }

    componentWillUnmount() {
        this.props.dispatch(resetState());
        this.webcamStream!.getTracks().forEach((track) => track.stop());
        this.playbackVideoDisplay.current!.pause();
        if (this.drawInterval) clearInterval(this.drawInterval);
    }

    render() {
        const {
            isRecording,
            isReviewing,
            isInitializingWebcam,
            isRecordingInitialized,
            isDownloadSendModalOpen,
            dispatch,
        } = this.props;

        let videoBorderClassName = styles.webcamVideoDisplayBorder;
        if (isRecording) videoBorderClassName += ` ${styles.recording}`;

        return (
            <>
                <DeleteModal
                    confirmButtonText="Delete Video"
                    message="This action will delete your recording. Are you sure you wish to delete your video?"
                    onConfirmDelete={this.deleteRecording}
                />
                {isDownloadSendModalOpen && (
                    <SendVideoModal
                        isModalOpen={true}
                        videoUri={this.state.videoUrl}
                        closeModal={() => dispatch(setIsDownloadSendModalOpen(false))}
                    />
                )}
                <Spinner fullPage isLoading={isInitializingWebcam} />
                <div
                    ref={this.pageRef}
                    className={styles.page}
                    style={isInitializingWebcam ? { visibility: 'hidden' } : {}}
                >
                    <div
                        ref={this.webcamVideoDisplayContainer}
                        className={styles.webcamVideoDisplayContainer}
                    >
                        <div className={videoBorderClassName} />
                        <canvas
                            ref={this.webcamVideoCanvas}
                            className={styles.recorderCanvas}
                        ></canvas>
                        <video
                            ref={this.webcamVideoDisplay}
                            className={styles.webcamVideoDisplay}
                            style={{ transform: 'scaleX(-1)' }}
                            playsInline
                            muted
                            id="recordWebcamVideo"
                        />
                        <video
                            ref={this.playbackVideoDisplay}
                            style={isReviewing ? { zIndex: 2 } : { display: 'none' }}
                            className={styles.webcamVideoDisplay}
                            playsInline
                            controls
                            id="playbackWebcamVideo"
                        />
                    </div>
                    <Spacer size={16} />
                    <div className={styles.recordButtonsContainer}>
                        {isReviewing ? (
                            <>
                                <Button
                                    inline
                                    inverted
                                    small
                                    onClick={() => dispatch(setIsDeleteModalOpen(true))}
                                >
                                    Re-Record
                                </Button>
                                <Spacer horizontal size={12} />
                                <Button inline small onClick={this.handleSaveClick}>
                                    {this.navigateToFeedback ? 'Give Feedback' : 'Send'}
                                </Button>
                            </>
                        ) : (
                            <>
                                {isRecordingInitialized && (
                                    <RoundIconButton
                                        inverted
                                        small
                                        color="gray"
                                        onClick={() => dispatch(setIsDeleteModalOpen(true))}
                                    >
                                        <FiTrash size={20} />
                                    </RoundIconButton>
                                )}
                                <Spacer horizontal size={12} />
                                <Button
                                    inline
                                    small
                                    color={isRecording ? 'red' : 'blue'}
                                    onClick={this.handleRecordClick}
                                >
                                    {isRecording ? 'Pause' : 'Record'}
                                </Button>
                                <Spacer horizontal size={12} />
                                {isRecordingInitialized && (
                                    <Button
                                        inline
                                        small
                                        inverted
                                        onClick={this.handleReviewAndSendClick}
                                    >
                                        Review & Send
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => ({ ...state.record });

export const Record = connect(mapStateToProps)(VideoRecord);
