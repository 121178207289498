import React, { useRef, useState } from 'react';
import { FiFlag } from 'react-icons/fi';
import { useAuthSelector } from '../../features/auth/authSlice';

import styles from './Message.module.scss';
import { MessageType } from '../../types/Conversation';
import { FiPlay } from 'react-icons/fi';
import { routeUrls } from '../../utils/constants';
import { useHistory, useLocation } from 'react-router-dom';

interface MessageProps {
    firstMessageFromSender?: boolean;
    message: MessageType;
    nextMessageFromSender?: boolean;
    conversationId: string;
}

export const Message: React.FunctionComponent<MessageProps> = ({
    firstMessageFromSender,
    message,
    nextMessageFromSender,
    conversationId,
}) => {
    const history = useHistory();

    const location = useLocation();

    const [clickedVideo, setClickedVideo] = useState(false);
    const videoRef = useRef<HTMLVideoElement>();

    const { databaseUser } = useAuthSelector((state) => state);
    const userId = databaseUser!.id;
    const isCurrentUserMessage = message.senderId === userId;

    const { contents, video, image } = message;
    let azureUrl: string | undefined,
        azureThumbnailUrl: string | undefined,
        fileType: string | undefined;
    const hasVideo = !!video;
    const hasImage = !!image;
    if (hasVideo) {
        azureUrl = video.azureStreamingUrl;
        azureThumbnailUrl = video.azureThumbnailUrl;
    } else if (hasImage) {
        azureUrl = image.azureDownloadUrl;
        azureThumbnailUrl = image.azureThumbnailUrl;
        fileType = image.fileType;
    }

    const showFlagged = message.flagged ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <FiFlag size={20} color="red" />
        </div>
    ) : null;

    let messageContents = (
        <div className={isCurrentUserMessage ? styles.senderMessage : styles.receiverMessage}>
            {contents}
        </div>
    );

    if (hasVideo) {
        messageContents = (
            <div className={styles.videoContainer}>
                {!clickedVideo && (
                    <div className={styles.videoImgPlayButton}>
                        <FiPlay />
                    </div>
                )}
                <div className={styles.controlWrapper}>
                    <video
                        ref={videoRef as any}
                        poster={azureThumbnailUrl}
                        className={styles.videoImg}
                        onClick={() => {
                            setClickedVideo(true);
                            videoRef.current?.play();
                        }}
                        src={azureUrl}
                        preload="none"
                        controls={clickedVideo}
                    />
                    <div
                        className={styles.feedbackButton}
                        onClick={() => {
                            history.push({
                                pathname: routeUrls.feedback,
                                state: {
                                    conversationId,
                                    onSuccessRoute: location.pathname,
                                    sourceVideoUrl: azureUrl,
                                    fileType: 'video',
                                },
                            });
                        }}
                    >
                        Give Feedback
                    </div>
                </div>
            </div>
        );
    }

    if (hasImage) {
        messageContents = (
            <div className={styles.videoContainer}>
                <div className={styles.controlWrapper}>
                    <img
                        src={azureUrl!}
                        className={styles.videoImg}
                        style={{ cursor: 'default' }}
                        alt=""
                    />
                    <div
                        className={styles.feedbackButton}
                        onClick={() =>
                            history.push({
                                pathname: routeUrls.feedback,
                                state: {
                                    conversationId,
                                    onSuccessRoute: location.pathname,
                                    sourceVideoUrl: azureUrl,
                                    fileType,
                                },
                            })
                        }
                    >
                        Give Feedback
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={[
                styles.individualMessageContainer,
                firstMessageFromSender && styles.individualMessageContainerFirst,
                nextMessageFromSender && styles.individualMessageContainerNext,
            ].join(' ')}
        >
            {isCurrentUserMessage ? (
                <>
                    <div></div>
                    <div className={styles.flexContainer}>
                        {messageContents}

                        <div className={styles.profileAlign}>
                            <div></div>
                            {message.user.profileImage ? (
                                <img
                                    className={styles.roundImage}
                                    src={message.user.profileImage}
                                    alt="Profile"
                                />
                            ) : (
                                <div className={styles.roundNameShort}>
                                    {message.user.shortName}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.flexContainer}>
                        <div className={styles.profileAlign}>
                            <div>{message.flagged}</div>
                            {message.user.profileImage ? (
                                <img
                                    className={styles.roundImage}
                                    src={message.user.profileImage}
                                    alt="Profile"
                                />
                            ) : (
                                <div className={styles.roundNameShort}>
                                    {message.user.shortName}
                                </div>
                            )}
                        </div>
                        {messageContents}
                        {showFlagged}
                    </div>
                    <div></div>
                </>
            )}
        </div>
    );
};
