import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { Preferences } from './../../utils/constants';
export interface Preference {
    id: string;
    code: string;
    value: string | Object;
}

const initialState = {
    isReferralModal: false,
    referrers: [] as any,
    photoPreference: {
        id: '',
        value: 'picture-in-picture',
        code: Preferences.PHOTO,
    } as Preference,
    videoPreference: {
        id: '',
        value: 'picture-in-picture',
        code: Preferences.VIDEO,
    } as Preference,
    newRequestEmailPreference: {
        id: '',
        value: 'off',
        code: Preferences.REQUEST_EMAIL,
    } as Preference,
    newRequestPushNotificationPreference: {
        id: '',
        value: 'off',
        code: Preferences.REQUEST_NOTIFICATION,
    } as Preference,
    newRequestPushWebNotificationPreference: {
        id: '',
        value: 'off',
        code: Preferences.REQUEST_WEB_NOTIFICATION,
    } as Preference,
    messageEmailPreference: {
        id: '',
        value: 'off',
        code: Preferences.MESSAGE_EMAIL,
    } as Preference,
    messagePushNotificationPreference: {
        id: '',
        value: 'off',
        code: Preferences.MESSAGE_NOTIFICATION,
    } as Preference,
    messagePushWebNotificationPreference: {
        id: '',
        value: 'off',
        code: Preferences.MESSAGE_WEB_NOTIFICATION,
    } as Preference,
};

export type SettingAppState = typeof initialState;

export const slice = createSlice<
    SettingAppState,
    {
        setReferralModal: CaseReducer<SettingAppState, PayloadAction<boolean>>;
        setReferrers: CaseReducer<SettingAppState, PayloadAction<any>>;
        setPhotoPreference: CaseReducer<SettingAppState, PayloadAction<any>>;
        setVideoPreference: CaseReducer<SettingAppState, PayloadAction<any>>;
        setMessageEmail: CaseReducer<SettingAppState, PayloadAction<any>>;
        setRequestEmail: CaseReducer<SettingAppState, PayloadAction<any>>;
        setMessageWebPushNotification: CaseReducer<SettingAppState, PayloadAction<any>>;
        setRequestWebPushNotification: CaseReducer<SettingAppState, PayloadAction<any>>;
        setMessagePushNotification: CaseReducer<SettingAppState, PayloadAction<any>>;
        setRequestPushNotification: CaseReducer<SettingAppState, PayloadAction<any>>;
        resetState: CaseReducer<SettingAppState, PayloadAction<any>>;
    }
>({
    name: 'settings',
    initialState,
    reducers: {
        setReferralModal: (state, { payload }) => {
            state.isReferralModal = payload;
        },
        setReferrers: (state, { payload }) => {
            state.referrers = payload;
        },
        setPhotoPreference: (state, { payload }) => {
            state.photoPreference = payload;
        },
        setVideoPreference: (state, { payload }) => {
            state.videoPreference = payload;
        },
        setMessageEmail: (state, { payload }) => {
            state.messageEmailPreference = payload;
        },
        setRequestEmail: (state, { payload }) => {
            state.newRequestEmailPreference = payload;
        },
        setMessagePushNotification: (state, { payload }) => {
            state.messagePushNotificationPreference = payload;
        },
        setRequestPushNotification: (state, { payload }) => {
            state.newRequestPushNotificationPreference = payload;
        },
        setMessageWebPushNotification: (state, { payload }) => {
            state.messagePushWebNotificationPreference = payload;
        },
        setRequestWebPushNotification: (state, { payload }) => {
            state.newRequestPushWebNotificationPreference = payload;
        },
        resetState: (state) => {
            return initialState;
        },
    },
});

export const useSettingsSelector = buildSubStateSelector<SettingAppState>(
    (state) => state.settings
);
export const settingActions = slice.actions;
export const settingReducer = slice.reducer;
