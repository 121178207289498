import { useDispatch } from 'react-redux';
import {
    FiBell,
    FiDollarSign,
    FiFileText,
    FiHelpCircle,
    FiLogOut,
    FiShield,
    FiSliders,
    FiUser,
    FiUserPlus,
} from 'react-icons/fi';

import styles from './Settings.module.scss';
import { logout } from '../auth/authActionCreators';
import { SettingsContainer } from '../../components/settingsContainer/SettingsContainer';
import { SettingPanel } from '../../components/settingPanel/SettingPanel';
import { routeUrls } from '../../utils/constants';
import { useAuthSelector } from '../auth/authSlice';

export const Settings = () => {
    const dispatch = useDispatch();
    const { databaseUser } = useAuthSelector((s) => s);

    return (
        <SettingsContainer title="Settings">
            <SettingPanel url={routeUrls.accountsettings} settingName="My Account" Icon={FiUser} />
            <SettingPanel
                settingName="My Subscription"
                url={routeUrls.subscriptionsettings}
                Icon={FiDollarSign}
            />
            {/* <SettingPanel
                settingName="Get Teeq for Free"
                url={routeUrls.getfree}
                Icon={FiGift}
                panelClassName={styles.freePanel}
                iconClassName={styles.freePanelIcon}
            /> */}
            <SettingPanel
                url={routeUrls.feedbackpreferences}
                settingName="Feedback Preferences"
                Icon={FiSliders}
            />
            <SettingPanel
                settingName="Notifications"
                url={routeUrls.notificationsettings}
                Icon={FiBell}
            />
            <SettingPanel
                settingName="Add Contact"
                disabled={databaseUser?.orgOnly}
                url={routeUrls.addcontact}
                Icon={FiUserPlus}
            />
            <SettingPanel
                settingName="Privacy Policy"
                url={routeUrls.privacypolicy}
                Icon={FiShield}
                target="_blank"
            />
            <SettingPanel
                settingName="Terms of Use"
                url={routeUrls.termsofuse}
                Icon={FiFileText}
                target="_blank"
            />
            <SettingPanel
                settingName="Help"
                url={routeUrls.help}
                Icon={FiHelpCircle}
                target="_blank"
            />
            <SettingPanel
                settingName="Log Out"
                Icon={FiLogOut}
                onClick={() => dispatch(logout())}
                panelClassName={styles.logoutPanel}
            />
        </SettingsContainer>
    );
};
