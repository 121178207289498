import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

const initialState = {
    isRecording: false,
    isRecordingInitialized: false,
    isReviewing: false,
    isDeleteModalOpen: false,
    isInitializingWebcam: true,
    isDownloadSendModalOpen: false,
};

export type RecordAppState = typeof initialState;

export const slice = createSlice<
    RecordAppState,
    {
        setIsRecording: CaseReducer<RecordAppState, PayloadAction<boolean>>;
        setIsRecordingInitialized: CaseReducer<RecordAppState, PayloadAction<boolean>>;
        setIsReviewing: CaseReducer<RecordAppState, PayloadAction<boolean>>;
        setIsInitializingWebcam: CaseReducer<RecordAppState, PayloadAction<boolean>>;
        setIsDeleteModalOpen: CaseReducer<RecordAppState, PayloadAction<boolean>>;
        setIsDownloadSendModalOpen: CaseReducer<RecordAppState, PayloadAction<boolean>>;
        resetState: CaseReducer<RecordAppState>;
    }
>({
    name: 'videoAnnotate',
    initialState,
    reducers: {
        setIsRecording: (state, { payload }) => {
            state.isRecording = payload;
        },
        setIsRecordingInitialized: (state, { payload }) => {
            state.isRecordingInitialized = payload;
        },
        setIsReviewing: (state, { payload }) => {
            state.isReviewing = payload;
        },
        setIsInitializingWebcam: (state, { payload }) => {
            state.isInitializingWebcam = payload;
        },
        setIsDeleteModalOpen: (state, { payload }) => {
            state.isDeleteModalOpen = payload;
        },
        setIsDownloadSendModalOpen: (state, { payload }) => {
            state.isDownloadSendModalOpen = payload;
        },
        resetState: (state) => {
            return initialState;
        },
    },
});

export const useRecordSelector = buildSubStateSelector<RecordAppState>((state) => state.record);
export const recordActions = slice.actions;
export const recordReducer = slice.reducer;
