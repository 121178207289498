import react from 'react';
import { FiSearch, FiX } from 'react-icons/fi';

import styles from './Search.module.scss';

interface SearchProps {
    value: string;
    onChange: react.ChangeEventHandler<HTMLInputElement>;
    onCancel: () => void;
    placeholder?: string;
}

export const Search: React.FunctionComponent<SearchProps> = (props) => {
    const { onChange, onCancel, value, placeholder = '' } = props;

    return (
        <div className={styles.inputContainer}>
            <FiSearch className={styles.inputIcon} size={24} />
            <input
                className={styles.input}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
            {!!value.length && <FiX className={styles.inputIcon} onClick={onCancel} size={24} />}
        </div>
    );
};
