import { useParams } from 'react-router';

import styles from './Payment.module.scss';
import { Panel } from '../../../components/panel/Panel';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Spacer } from '../../../components/spacer/Spacer';
import { Button } from '../../../components/button/Button';
import { GreyText } from '../../../components/greyText/GreyText';
import { Link } from '../../../components/link/Link';

import applePayLogoSrc from '../../../assets/apple-pay-logo.svg';
import googlePayLogoSrc from '../../../assets/google-pay-logo.svg';

export const ChoosePaymentType = () => {
    const { duration } = useParams() as { duration: string };

    return (
        <OnboardingContainer>
            <Panel backUrl="/payment">
                <h2>Set up your payment</h2>
                <p>Your membership starts as soon as you set up payment.</p>
                <Spacer size={5} />
                <Link to={`/payment/${duration}/card`}>
                    <Button onClick={() => null}>Credit card or debit card</Button>
                </Link>
                <Spacer size={5} />
                <GreyText semiTransparent center>
                    OR:
                </GreyText>
                <Spacer size={18} />
                <div className={styles.paymentLogoContainer}>
                    <img src={applePayLogoSrc} onClick={() => console.log('todo')} />
                    <img src={googlePayLogoSrc} onClick={() => console.log('todo')} />
                </div>
            </Panel>
        </OnboardingContainer>
    );
};
