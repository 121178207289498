import { useRef, useState } from 'react';
import { FiCamera } from 'react-icons/fi';
import commonStyles from '../../features/inbox/inbox.module.scss';
import { drawImageScaled } from '../../features/videoAnnotate/VideoAnnotate';
import { getVideoDimensionsOf } from '../../utils/videos';

export interface FilePickerProps {
    afterFileChange: (file: File, thumbnailString: string) => void;
}

export const FilePicker = ({ afterFileChange }: FilePickerProps) => {
    const inputFile = useRef<any>();
    const video = useRef<any>();
    const image = useRef<any>();
    const canvas = useRef<any>();

    const handleFileChange = async (e: any) => {
        if (e.target.files.length) {
            const selectedFile = e.target.files[0];
            let videoURI: string, imageURI: string;
            const isVideo = selectedFile.type.indexOf('video') > -1;
            if (isVideo) {
                videoURI = URL.createObjectURL(selectedFile);
                video.current.src = videoURI;
            } else {
                imageURI = URL.createObjectURL(selectedFile);
                image.current.src = imageURI;
            }
            requestAnimationFrame(() => {
                setTimeout(
                    () =>
                        (async () => {
                            let height: number, width: number;
                            if (isVideo) {
                                const resp = await getVideoDimensionsOf(videoURI);
                                height = resp.height;
                                width = resp.width;
                            } else {
                                height = image.current.height;
                                width = image.current.width;
                            }
                            canvas.current.width = width;
                            canvas.current.height = height;
                            drawImageScaled(
                                isVideo ? video.current : image.current,
                                width,
                                height,
                                canvas.current.getContext('2d'),
                                canvas.current.width,
                                canvas.current.height,
                                false
                            );
                            requestAnimationFrame(() =>
                                afterFileChange(selectedFile, canvas.current.toDataURL('image/png'))
                            );
                        })(),
                    250
                );
            });
        }
    };

    return (
        <>
            <div className={commonStyles.sendCameraIcon} onClick={() => inputFile.current.click()}>
                <FiCamera size={24} />
                <input
                    type="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    accept="image/png, image/jpg, video/mp4, video/mov, video/quicktime"
                    onChange={handleFileChange}
                />
                <div style={{ visibility: 'hidden', position: 'absolute', top: 0, left: 0 }}>
                    <video ref={video} />
                    <img ref={image} alt="" />
                    <canvas ref={canvas}></canvas>
                </div>
            </div>
        </>
    );
};
