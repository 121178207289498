import firebase from 'firebase';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { loginActions } from './loginSlice';

export const login = (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(loginActions.setLoading(true));
        const response = await firebase.auth().signInWithEmailAndPassword(email, password);
        console.log(response.user);
    } catch (err) {
        console.error(err);
        toast.error(err.message);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};

export const loginWithGoogle = () => async (dispatch: Dispatch) => {
    try {
        dispatch(loginActions.setLoading(true));
        const provider = new firebase.auth.GoogleAuthProvider();
        await firebase.auth().signInWithPopup(provider);
    } catch (err) {
        console.error(err);
        toast.error(`Unable to login: ${err.message}`);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};

export const loginWithFacebook = () => async (dispatch: Dispatch) => {
    try {
        dispatch(loginActions.setLoading(true));
        const provider = new firebase.auth.FacebookAuthProvider();
        await firebase.auth().signInWithPopup(provider);
    } catch (err) {
        console.error(err);
        toast.error(`Unable to login: ${err.message}`);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};

export const loginWithApple = () => async (dispatch: Dispatch) => {
    try {
        dispatch(loginActions.setLoading(true));
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        await firebase.auth().signInWithPopup(provider);
    } catch (err) {
        console.error(err);
        toast.error(`Unable to login: ${err.message}`);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};
