import React from 'react';
import styles from './LabeledIcon.module.scss';

interface LabeledIconProps {
    IconComponent: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
    text: string;
    onClick: () => void;
    isActive?: boolean;
}

export const LabeledIcon: React.FunctionComponent<LabeledIconProps> = ({
    IconComponent,
    text,
    onClick,
    isActive,
}) => {
    let containerClassList = styles.container;
    if (isActive) containerClassList += ` ${styles.active}`;

    return (
        <div onClick={onClick} className={containerClassList} role="button">
            <IconComponent className={styles.icon} />
            <div>{text}</div>
            <span className={styles.isActiveDot} />
        </div>
    );
};
