import React from 'react';
import { IconType } from 'react-icons';
import { FiChevronRight } from 'react-icons/fi';

import styles from './SettingPanel.module.scss';
import { voidFn } from '../../utils/types';
import { Link } from 'react-router-dom';

interface SettingPanelProps {
    Icon: IconType;
    settingName: string;
    onClick?: voidFn;
    url?: string;
    panelClassName?: string;
    iconClassName?: string;
    target?: string;
    disabled?: boolean;
}

export const SettingPanel: React.FunctionComponent<SettingPanelProps> = (props) => {
    const {
        Icon,
        settingName,
        onClick = () => null,
        url,
        panelClassName = '',
        iconClassName = '',
        target = '',
        disabled = false,
    } = props;

    const panelClassList = `${styles.settingPanel} ${panelClassName}`;
    const iconClassList = `${styles.panelIcon} ${iconClassName}`;

    const panelContents = (
        <div className={panelClassList} onClick={disabled ? undefined : onClick}>
            <div className={styles.panelLeft}>
                <Icon className={iconClassList} />
                {settingName}
            </div>
            <FiChevronRight className={styles.panelChevron} />
        </div>
    );

    if (url) {
        if (url.indexOf('http') > -1) {
            return (
                <a
                    href={url}
                    target={target}
                    style={disabled ? { pointerEvents: 'none', opacity: 0.3 } : undefined}
                >
                    {panelContents}
                </a>
            );
        } else {
            return (
                <Link
                    to={url}
                    target={target}
                    style={disabled ? { pointerEvents: 'none', opacity: 0.3 } : undefined}
                >
                    {panelContents}
                </Link>
            );
        }
    }

    return panelContents;
};
