import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { TextInput } from '../../../components/textInput/TextInput';
import { Button } from '../../../components/button/Button';
import { Spacer } from '../../../components/spacer/Spacer';
import { changePassword, updatePasswordResetRequired } from '../../settings/settingActionsCreator';
import { useAuthSelector } from '../authSlice';
import styles from './PasswordResetRequired.module.scss';

const PasswordResetRequired = () => {
    const { databaseUser } = useAuthSelector((state) => state);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const dispatcher = useDispatch();

    const handleSubmit = async () => {
        dispatcher(
            changePassword(currentPassword, newPassword, {
                onSuccess: () => {
                    dispatcher(updatePasswordResetRequired(databaseUser?.id as string));
                },
                onError: (err: any) => {
                    toast.error(err.message);
                },
            })
        );
    };

    return (
        <OnboardingContainer>
            <Panel>
                <h2>Update your password</h2>
                <div>
                    <TextInput
                        value={currentPassword}
                        type="password"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder="Temporary Password"
                    />
                    <Spacer size={5} />
                    <TextInput
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                    />
                    <div className={styles.passwordCriteria}>
                        <div>Password Criteria:</div>
                        <div>• 8 characters or longer</div>
                        <div>• At least 1 uppercase letter</div>
                        <div>• At least 1 lowercase letter</div>
                        <div>• At least 1 number</div>
                    </div>
                    <Spacer size={5} />
                    <Button onClick={handleSubmit}>Update Password</Button>
                </div>
            </Panel>
        </OnboardingContainer>
    );
};

export default PasswordResetRequired;
