import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Settings } from './Settings';
import { Account } from './Account/Account';
import { FeedbackPreference } from './FeedbackPreference/feedbackPreference';
import { Notifications } from './Notifications/Notifications';
import { GetFree } from './GetFree/GetFree';
import { GetFreeDetails } from './GetFree/GetFreeDetails';
import { getPreferences, getReferrers } from './settingActionsCreator';
import { useDispatch } from 'react-redux';
import { useAuthSelector } from '../auth/authSlice';
import { routeUrls } from '../../utils/constants';

export const SettingPage = () => {
    const code = useAuthSelector((state) => state.databaseUser?.referralCode);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPreferences());
        dispatch(getReferrers(code));
    }, [dispatch, code]);

    return (
        <Switch>
            <Route exact path={routeUrls.settings} component={Settings} />
            <Route exact path={routeUrls.accountsettings} component={Account} />
            <Route exact path={routeUrls.feedbackpreferences} component={FeedbackPreference} />
            <Route exact path={routeUrls.notificationsettings} component={Notifications} />
            <Route exact path={routeUrls.getfree} component={GetFree} />
            <Route exact path={routeUrls.getfreedetails} component={GetFreeDetails} />
            <Redirect to="/" />
        </Switch>
    );
};
