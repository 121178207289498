import { useDispatch } from 'react-redux';

import facebookIconSrc from '../../../assets/icon-facebook.svg';
import appleIconSrc from '../../../assets/icon-apple.svg';
import googleIconSrc from '../../../assets/icon-google.svg';
import emailIconSrc from '../../../assets/icon-email.svg';

import { Button } from '../../../components/button/Button';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { Spacer } from '../../../components/spacer/Spacer';
import { Link } from '../../../components/link/Link';
import { GreyText } from '../../../components/greyText/GreyText';
import { loginWithApple, loginWithFacebook, loginWithGoogle } from './loginActionCreators';
import { useLoginSelector } from './loginSlice';
import { Spinner } from '../../../components/spinner/Spinner';

export const Login = () => {
    const dispatch = useDispatch();
    const { loading } = useLoginSelector((state) => state);

    return (
        <>
            <Spinner fullPage isLoading={loading} />
            <OnboardingContainer>
                <Panel>
                    <h2 style={{ textAlign: 'center' }}>Log In</h2>
                    <Spacer size={10} />
                    <Link to="/login-email">
                        <Button fullWidth iconSrc={emailIconSrc}>
                            Log in with email
                        </Button>
                    </Link>
                    <GreyText center style={{ fontSize: 10 }}>
                        OR:
                    </GreyText>
                    <Button
                        onClick={() => dispatch(loginWithFacebook())}
                        fullWidth
                        inverted
                        iconSrc={facebookIconSrc}
                    >
                        Continue with Facebook
                    </Button>
                    <Button
                        onClick={() => dispatch(loginWithApple())}
                        fullWidth
                        inverted
                        iconSrc={appleIconSrc}
                    >
                        Continue with Apple
                    </Button>
                    <Button
                        onClick={() => dispatch(loginWithGoogle())}
                        fullWidth
                        inverted
                        iconSrc={googleIconSrc}
                    >
                        Continue with Google
                    </Button>
                    <Spacer size={38} />
                    <p style={{ textAlign: 'center' }}>
                        Don't have an account yet? &nbsp;
                        <Link to="/signup">Sign Up</Link>
                    </p>
                </Panel>
            </OnboardingContainer>
        </>
    );
};
