export type voidFn = () => void;

export interface CanvasElement extends HTMLCanvasElement {
    captureStream(frameRate?: number): MediaStream;
}

export type buttonColors = 'blue' | 'gray' | 'red';

export type clickableTextColors = 'blue' | 'red';

export enum paintColors {
    cream = '#F2F2F2',
    black = '#171717',
    blue = '#1B5FEA',
    green = '#62C18F',
    yellow = '#F9F65E',
    red = '#EC0819',
}

// https://github.com/Microsoft/TypeScript/issues/14094#issuecomment-373782604
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
