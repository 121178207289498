import { toast } from 'react-toastify';
import { Button } from '../../components/button/Button';
import { axios } from '../../services/axiosService';
import styles from './OrganizationInviteModal.module.scss';

export const OrganizationInviteModal = ({
    token,
    callback,
}: {
    token: string;
    callback: () => void;
}) => {
    const respond = (accept: boolean) => {
        axios.post('/organizations/invite', { accept, token });
        accept ? toast.success('Accepted') : toast.error('Rejected');
        callback();
    };

    return (
        <div className={styles.container}>
            Do you want to accept this organization invite?
            <div>
                <div>
                    <Button color="blue" title="Accept" onClick={() => respond(true)}>
                        Accept
                    </Button>
                </div>
                <div>
                    <Button color="red" title="Reject" onClick={() => respond(false)}>
                        Reject
                    </Button>
                </div>
            </div>
        </div>
    );
};
