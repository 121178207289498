import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

import { loginActions, useLoginSelector } from './loginSlice';
import { login } from './loginActionCreators';
import styles from './Login.module.scss';
import { TextInput } from '../../../components/textInput/TextInput';
import { Button } from '../../../components/button/Button';
import { Panel } from '../../../components/panel/Panel';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Link } from '../../../components/link/Link';
import { Spacer } from '../../../components/spacer/Spacer';
import { Spinner } from '../../../components/spinner/Spinner';

export const LoginWithEmail: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const { email, password, stayLoggedIn, loading } = useLoginSelector((s) => s);

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            dispatch(login(email, password));
        },
        [dispatch, email, password]
    );

    const handleStayLoggedIn = () => dispatch(loginActions.setStayLoggedIn(!stayLoggedIn));

    const stayLoggedInIcon = stayLoggedIn ? <FiCheckSquare /> : <FiSquare />;

    useEffect(() => {
        return () => {
            dispatch(loginActions.resetState());
        };
    }, [dispatch]);

    return (
        <>
            <Spinner fullPage isLoading={loading} />
            <OnboardingContainer>
                <Panel backUrl="/login">
                    <form onSubmit={handleSubmit} className={styles.loginWithEmailForm}>
                        <h2 style={{ textAlign: 'center' }}>Log In</h2>
                        <Spacer size={10} />
                        <TextInput
                            value={email}
                            placeholder="email"
                            required
                            onChange={(e) => dispatch(loginActions.setEmail(e.target.value))}
                        />
                        <TextInput
                            value={password}
                            placeholder="password"
                            required
                            onChange={(e) => dispatch(loginActions.setPassword(e.target.value))}
                            type="password"
                        />
                        <div
                            className={styles.stayLoggedIn}
                            role="button"
                            onClick={handleStayLoggedIn}
                        >
                            {stayLoggedInIcon}
                            &nbsp;&nbsp;
                            <span>Stay logged in</span>
                        </div>
                        <Link to="/initiate-password-reset" style={{ marginLeft: 'auto' }}>
                            Forgot password?
                        </Link>
                        <Button type="submit">Log In</Button>
                    </form>
                </Panel>
            </OnboardingContainer>
        </>
    );
};
