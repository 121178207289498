import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './referralModal.module.scss';
import { Modal } from '../modal/Modal';
import { settingActions, useSettingsSelector } from '../../features/settings/settingsSlice';

import { FiCopy, FiMail, FiFacebook } from 'react-icons/fi';
import { useAuthSelector } from '../../features/auth/authSlice';

const { setReferralModal } = settingActions;

export const ReferralCodeModal: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isReferralModal = useSettingsSelector((state) => state.isReferralModal);
    const referralCode = useAuthSelector((state) => state.databaseUser?.referralCode) as string;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralCode);
        dispatch(setReferralModal(false));
    };
    return (
        <Modal isOpen={isReferralModal} onDimmerClick={() => dispatch(setReferralModal(false))}>
            <div>
                <div className={styles.title}>Share Referral Code</div>
                <div className={styles.buttonsContainer}>
                    <div className={styles.customHeading} onClick={() => copyToClipboard()}>
                        <FiCopy size={24} />
                        <label className={styles.referralBtn}>Copy to Clipboard</label>
                    </div>
                    <div
                        className={styles.customHeading}
                        onClick={() => dispatch(setReferralModal(false))}
                    >
                        <FiMail size={24} />
                        <label className={styles.referralBtn}>Send in email</label>
                    </div>
                    <div
                        className={styles.customHeading}
                        onClick={() => dispatch(setReferralModal(false))}
                    >
                        <FiFacebook size={24} />
                        <label className={styles.referralBtn}>Share on Facebook</label>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
