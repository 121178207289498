import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { Spacer } from '../spacer/Spacer';
import styles from './Panel.module.scss';

interface PanelProps {
    backUrl?: string;
    backFn?: () => void;
    className?: string;
}

export const Panel: React.FunctionComponent<PanelProps> = ({
    children,
    backUrl,
    backFn,
    className = '',
}) => {
    let backLink = null;
    let backLinkOffset = null;
    if (backUrl || backFn) {
        backLink = (
            <Link
                to={backUrl ?? '#'}
                className={styles.backLink}
                onClick={backFn ? backFn : () => null}
            >
                <Icon name="chevron left" size="small" className={styles.chevron} />
                <span>Back</span>
            </Link>
        );
        // Offset the height of the backlink + it's margin so that the panel is still centered
        backLinkOffset = <Spacer size={35} />;
    }

    return (
        <div>
            {' '}
            {/* Wrapper div is needed to keep backlink aligned with panel */}
            {backLink}
            <div className={`${styles.panel} ${className}`}>{children}</div>
            {backLinkOffset}
        </div>
    );
};
