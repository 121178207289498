import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import { Login } from '../features/auth/login/Login';
import { SignupMethods } from '../features/auth/signup/SignupMethods';
import { SignupEmail } from '../features/auth/signup/SignupEmail';
import { LoginWithEmail } from '../features/auth/login/LoginWithEmail';
import { VerificationRequired } from '../features/auth/verificationRequired/VerificationRequired';
import { Home } from '../features/home/Home';
import { InitiatePasswordReset } from '../features/auth/initiatepasswordReset/InitiatePasswordReset';
import { AuthRedirect } from '../features/auth/authRedirect/AuthRedirect';
import { VideoAnnotate } from '../features/videoAnnotate/VideoAnnotate';
import { useSignupSelector } from '../features/auth/signup/signupSlice';
import { SignupChooseUsername } from '../features/auth/signup/SignupChooseUsername';
import { SignupAdditionalInformation } from '../features/auth/signup/SignupAdditionalInformation';
import { SettingPage } from '../features/settings';
import { AddContact } from '../features/addContact/AddContact';
import { routeUrls } from '../utils/constants';
import { Record } from '../features/record/Record';
import { Inbox } from '../features/inbox/Inbox';
import { ChooseSubscription } from '../features/auth/payment/ChooseSubscription';
import { ChoosePaymentType } from '../features/auth/payment/ChoosePaymentType';
import { CardPayment } from '../features/auth/payment/CardPayment';
import { config } from '../config';
import { SubscriptionSettings } from '../features/settings/SubscriptionSettings/SubscriptionSettings';
import { MobileWeb } from '../features/mobileWeb/mobileWeb';
import { useAuthSelector } from '../features/auth/authSlice';
import { OrganizationInvite } from '../features/organizationInvite/OrganizationInvite';
import PasswordResetRequired from '../features/auth/passwordResetRequired/PasswordResetRequired';

export const Routes = () => {
    const { username } = useSignupSelector((state) => state);
    const { firebaseUser, databaseUser } = useAuthSelector((s) => s);

    const isAuthenticated = firebaseUser ? firebaseUser.uid.length > 0 : false;
    const isVerified = firebaseUser ? firebaseUser.emailVerified : false;

    const passwordResetRequired = databaseUser ? databaseUser.passwordResetRequired : false;

    if (passwordResetRequired) {
        return (
            <div>
                <MobileWeb />
                <PasswordResetRequired />
            </div>
        );
    }

    if (!isAuthenticated)
        return (
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/signup" component={SignupMethods} />
                <Route exact path="/signup-email" component={SignupEmail} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login-email" component={LoginWithEmail} />
                <Route exact path="/initiate-password-reset" component={InitiatePasswordReset} />
                <Route exact path="/auth-redirect" component={AuthRedirect} />
                <Route exact path="/organization-invite" component={OrganizationInvite} />

                <Redirect to="/" />
            </Switch>
        );

    if (!isVerified)
        return (
            <Switch>
                <Route exact path="/verificationRequired" component={VerificationRequired} />
                <Route exact path="/auth-redirect" component={AuthRedirect} />
                <Route exact path="/organization-invite" component={OrganizationInvite} />

                <Redirect to="/verificationRequired" />
            </Switch>
        );

    if (!databaseUser && !username) {
        return (
            <Switch>
                <Route exact path="/" component={SignupChooseUsername} />
                <Route exact path="/organization-invite" component={OrganizationInvite} />

                <Redirect to="/" />
            </Switch>
        );
    }
    if (!databaseUser) {
        return (
            <Switch>
                <Route exact path="/" component={SignupAdditionalInformation} />
                <Route exact path="/organization-invite" component={OrganizationInvite} />

                <Redirect to="/" />
            </Switch>
        );
    }

    if (
        databaseUser &&
        dayjs(databaseUser.subscriptionExpirationDate).isBefore(dayjs()) &&
        !databaseUser.orgOnly
    ) {
        const stripeKey = config.stripe.publicKey || '';
        const stripePromise = loadStripe(stripeKey);
        return (
            <Elements stripe={stripePromise}>
                <Switch>
                    <Route exact path="/organization-invite" component={OrganizationInvite} />
                    <Route exact path="/" component={ChooseSubscription} />
                    <Route exact path="/payment" component={ChooseSubscription} />
                    <Route exact path="/payment/:duration" component={ChoosePaymentType} />
                    <Route exact path="/payment/:duration/card" component={CardPayment} />

                    <Redirect to="/payment" />
                </Switch>
            </Elements>
        );
    }

    return (
        <div className={styles.authenticatedContainer}>
            <MobileWeb />
            <Switch>
                <Route exact path="/organization-invite" component={OrganizationInvite} />
                <Route exact path={routeUrls.home} component={Home} />
                <Route exact path={routeUrls.feedback} component={VideoAnnotate} />
                <Route exact path={routeUrls.record} component={Record} />
                <Route path={routeUrls.inbox} component={Inbox} />
                <Route path={routeUrls.settings} component={SettingPage} />
                <Route exact path={routeUrls.addcontact} component={AddContact} />
                <Route
                    exact
                    path={routeUrls.subscriptionsettings}
                    component={SubscriptionSettings}
                />

                <Redirect to={routeUrls.home} />
            </Switch>
        </div>
    );
};
