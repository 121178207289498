import React, { Fragment, useEffect, useState } from 'react';
import commonStyles from './inbox.module.scss';
import { FiChevronLeft, FiAlertTriangle } from 'react-icons/fi';
import { BiUserX } from 'react-icons/bi';
import { getNamesCharacters } from '../../utils/helpers';
import { inboxActions, useInboxSelector } from './inboxSlice';
import { useDispatch } from 'react-redux';
import { reportAConversation } from './inboxActionsCreator';
import { ConversationModal } from '../../components/conversationModal/conversationModal';
import { useAuthSelector } from '../auth/authSlice';
import { Loader } from 'semantic-ui-react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { routeUrls } from '../../utils/constants';
import { useHistory } from 'react-router';

dayjs.extend(utc);

export const ConversationDetail = (props: any) => {
    const currentInbox = useInboxSelector((state) => state.currentMessages);
    const user = useAuthSelector((state) => state.databaseUser);
    const sentVideos = useInboxSelector((s) => s.sentVideos);
    const receivedVideos = useInboxSelector((s) => s.receivedVideos);
    const currentInboxOrg = useInboxSelector((state) => state.currentMessagesOrg);
    const [isProfile, setProfile] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [otherUser, setOtherUser] = useState<any>();
    const [groupName, setGroupName] = useState('');
    const history = useHistory();

    const dispatcher = useDispatch();

    useEffect(() => {
        if (currentInbox.users) {
            if (props.type !== 'groups') {
                if (user?.id === currentInbox.users[0].id) {
                    if (currentInbox.users[1].profileImage) {
                        setProfile(true);
                        setProfileImage(currentInbox.users[1].profileImage);
                    } else {
                        setProfile(false);
                        setProfileImage('');
                    }
                } else {
                    if (currentInbox.users[0].profileImage) {
                        setProfile(true);
                        setProfileImage(currentInbox.users[0].profileImage);
                    } else {
                        setProfile(false);
                        setProfileImage('');
                    }
                }
            }
        }
    }, [user, currentInbox, props]);

    const isOrg = () => {
        return props.type === 'group';
    };

    const reportConversation = () => {
        if (isOrg()) {
            const id = currentInboxOrg.conversation.id;
            dispatcher(reportAConversation(id));
        } else {
            const id = currentInbox.conversation.id;
            dispatcher(reportAConversation(id));
        }
    };

    const removeUser = () => {
        if (isOrg()) {
            const id = currentInboxOrg.conversation.id;
            props.removeConversation(id);
        } else {
            const id = currentInbox.conversation.id;
            props.removeConversation(id);
        }
    };

    useEffect(() => {
        if (!isOrg()) {
            setOtherUser(currentInbox.users.filter((u: any) => u.id !== user!.id)[0]);
            setGroupName(currentInbox.name);
        } else {
            setGroupName(props.name);
            setOtherUser(null);
        }
    }, [currentInbox, user, props]);

    const isTodayOrYesterday = (dateParameter: Dayjs) => {
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (
            dateParameter.date() === today.getDate() &&
            dateParameter.month() === today.getMonth() &&
            dateParameter.year() === today.getFullYear()
        ) {
            return dayjs(dateParameter).local().format('h:mm A');
        } else if (
            dateParameter.date() === yesterday.getDate() &&
            dateParameter.month() === yesterday.getMonth() &&
            dateParameter.year() === yesterday.getFullYear()
        ) {
            return 'Yesterday';
        } else {
            return dayjs(dateParameter).local().format('MM/DD');
        }
    };

    if (!otherUser && !groupName) {
        return <Loader />;
    }

    return (
        <Fragment>
            <ConversationModal removeConversation={removeUser} />
            <div className={commonStyles.infoSection}>
                <div className={commonStyles.messageContainerHeader}>
                    <div className={commonStyles.mcHeaderSetting}>
                        <div className={commonStyles.mcHeaderLeft}>
                            <FiChevronLeft
                                size={30}
                                className={commonStyles.iconStyling}
                                onClick={() => props.toggle()}
                            />
                            {!isOrg() ? (
                                !isProfile ? (
                                    <div className={commonStyles.roundNameSelected}>
                                        {getNamesCharacters(props.name ? props.name : '')}
                                    </div>
                                ) : (
                                    <img
                                        className={commonStyles.roundImageMessageScreen}
                                        src={profileImage}
                                        alt="profile"
                                    />
                                )
                            ) : props.profilePhoto ? (
                                <img
                                    className={commonStyles.roundImageMessageScreen}
                                    src={props.profilePhoto}
                                    alt="profile"
                                />
                            ) : (
                                <div className={commonStyles.roundNameSelected}>
                                    {getNamesCharacters(props.name ? props.name : '')}
                                </div>
                            )}

                            <div className={commonStyles.mcHeaderRight}>
                                {isOrg() ? props.name : currentInbox.users ? props.name : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={commonStyles.infoBody}>
                    <div>
                        <div>
                            <label className={commonStyles.nameHeadingStyle}>
                                Media received from {isOrg() ? props.name : otherUser?.firstName}
                            </label>
                            <div className={commonStyles.scrollSectionMedia}>
                                {receivedVideos.map((item, index) => {
                                    return (
                                        <div key={index} className={commonStyles.thumbnailWrapper}>
                                            <div
                                                className={commonStyles.box}
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    maxHeight: 100,
                                                    maxWidth: 100,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: 100,
                                                        width: 100,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        history.push({
                                                            pathname: routeUrls.feedback,
                                                            state: {
                                                                sourceVideoUrl:
                                                                    item.azureStreamingUrl,
                                                                fileType: 'video',
                                                            },
                                                        })
                                                    }
                                                >
                                                    <img
                                                        src={item.azureThumbnailUrl}
                                                        style={{
                                                            width: '100%',
                                                            objectFit: 'cover',
                                                            height: '100%',
                                                            borderRadius: 4,
                                                        }}
                                                    />
                                                    <br />
                                                </div>
                                            </div>
                                            <span className={commonStyles.thumbnailTime}>
                                                {isTodayOrYesterday(
                                                    dayjs.utc(item.updatedAt, 'YYYY-MM-DD hh:mm')
                                                )}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={commonStyles.infoBody}>
                    <div>
                        <div>
                            <label className={commonStyles.nameHeadingStyle}>
                                Media sent to {isOrg() ? props.name : otherUser?.firstName}
                            </label>
                            <div className={commonStyles.scrollSectionMedia}>
                                {sentVideos.map((item, index) => {
                                    return (
                                        <div key={index} className={commonStyles.thumbnailWrapper}>
                                            <div
                                                className={commonStyles.box}
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    maxHeight: 100,
                                                    maxWidth: 100,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: 100,
                                                        width: 100,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        history.push({
                                                            pathname: routeUrls.feedback,
                                                            state: {
                                                                sourceVideoUrl:
                                                                    item.azureStreamingUrl,
                                                                fileType: 'video',
                                                            },
                                                        })
                                                    }
                                                >
                                                    <img
                                                        src={item.azureThumbnailUrl}
                                                        style={{
                                                            width: '100%',
                                                            objectFit: 'cover',
                                                            height: '100%',
                                                            borderRadius: 4,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <span className={commonStyles.thumbnailTime}>
                                                {isTodayOrYesterday(
                                                    dayjs.utc(item.updatedAt, 'YYYY-MM-DD hh:mm')
                                                )}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={commonStyles.placingAtBottom}>
                    {!!props.showRemoveConversation ? (
                        <>
                            <div className={commonStyles.infobottomSection}>
                                <div
                                    className={commonStyles.infoBottomButtonContainer}
                                    onClick={() => dispatcher(inboxActions.setDeleteModal(true))}
                                >
                                    <BiUserX size={30} className={commonStyles.greyIconColor} />
                                    <label className={commonStyles.removeHeading}>
                                        Remove {props.name} from My Contacts
                                    </label>
                                </div>
                            </div>
                            <div className={commonStyles.infobottomSection}>
                                <div
                                    className={commonStyles.infoBottomButtonContainer}
                                    onClick={() => reportConversation()}
                                >
                                    <FiAlertTriangle
                                        className={commonStyles.redIconColor}
                                        size={30}
                                    />
                                    <label className={commonStyles.reportHeading}>
                                        Report Conversation
                                    </label>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};
