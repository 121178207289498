import React from 'react';
import { buttonColors } from '../../utils/types';

import styles from './RoundIconButton.module.scss';

export interface RoundIconButtonProps {
    onClick?: () => void;
    inverted?: boolean;
    color?: buttonColors;
    small?: boolean;
}

export const RoundIconButton: React.FunctionComponent<RoundIconButtonProps> = (props) => {
    const { children, onClick = () => null, color = 'blue', inverted, small } = props;

    let className = `${styles.roundIconButton} ${styles[color]}`;
    if (inverted) className += ` ${styles.inverted}`;
    if (small) className += ` ${styles.small}`;

    return (
        <div className={className} onClick={onClick} role="button">
            {children}
        </div>
    );
};
