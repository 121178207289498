import React from 'react';
import styles from './SignupDisclaimer.module.scss';
import { GreyText } from '../greyText/GreyText';

export const SignupDisclaimer = () => (
    <>
        <GreyText semiTransparent center>
            By signing up you agree to our
        </GreyText>
        <GreyText semiTransparent center bold>
            <a target="_blank" className={styles.unstyled} href="https://teeqapp.com/privacy">
                Privacy Policy
            </a>{' '}
            and{' '}
            <a target="_blank" className={styles.unstyled} href="https://teeqapp.com/terms">
                Terms
            </a>
        </GreyText>
    </>
);
