import React from 'react';
import { useParams } from 'react-router';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { axios } from '../../../services/axiosService';
import styles from './Payment.module.scss';
import { Panel } from '../../../components/panel/Panel';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Spacer } from '../../../components/spacer/Spacer';
import { Button } from '../../../components/button/Button';
import poweredByStripeSrc from '../../../assets/powered-by-stripe.svg';
import { loadUser } from '../authActionCreators';
import { authActions } from '../authSlice';

export const CardPayment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const { duration } = useParams() as { duration: string };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(authActions.setLoading(true));
        try {
            const { token, error } = await stripe!.createToken(
                elements!.getElement(CardElement) as any
            );
            if (error) throw new Error(error.message);
            if (!token || !token.id) throw new Error('Missing token or token id');
            await axios.post('/Users/Subscribe', {
                tokenId: token.id,
                cardBrand: token.card?.brand,
                cardExpMonth: token.card?.exp_month,
                cardExpYear: token.card?.exp_year,
                cardLast4: token.card?.last4,
                subOption: duration,
                platform: 'web',
            });
            dispatch(loadUser());
        } catch (error) {
            console.error(error);
            toast.error(
                'Sorry, something went wrong. Please check your payment details and try again'
            );
        } finally {
            dispatch(authActions.setLoading(false));
        }
    };

    return (
        <OnboardingContainer>
            <Panel backUrl={`/payment/${duration}/`}>
                <h2>Set up your payment</h2>
                <p>Your membership starts as soon as you set up payment.</p>
                <Spacer size={20} />
                <form onSubmit={handleSubmit}>
                    <Spacer size={5} />
                    <CardElement />
                    <Spacer size={30} />
                    <Button type="submit" disabled={!stripe}>
                        Add
                    </Button>
                </form>
                <Spacer size={44} />
                <div className={styles.center}>
                    <img src={poweredByStripeSrc} alt="Powered by Stripe" />
                </div>
            </Panel>
        </OnboardingContainer>
    );
};
