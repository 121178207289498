import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { loginReducer } from '../features/auth/login/loginSlice';
import { actionTypes, firebaseReducer } from 'react-redux-firebase';
import { authReducer } from '../features/auth/authSlice';
import { settingReducer } from '../features/settings/settingsSlice';
import { videoAnnotateReducer } from '../features/videoAnnotate/videoAnnotateSlice';
import { signupReducer } from '../features/auth/signup/signupSlice';
import { recordReducer } from '../features/record/recordSlice';
import { inboxReducer } from '../features/inbox/inboxSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        firebase: firebaseReducer,
        login: loginReducer,
        signup: signupReducer,
        videoAnnotate: videoAnnotateReducer,
        record: recordReducer,
        inbox: inboxReducer,
        settings: settingReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
    }),
});
