import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

const initialState: {
    loading: boolean;
    username: string;
    referralCode: string | null;
} = {
    loading: false,
    username: '',
    referralCode: null,
};

export type SignupAppState = typeof initialState;

export const slice = createSlice<
    SignupAppState,
    {
        setLoading: CaseReducer<SignupAppState, PayloadAction<boolean>>;
        setUsername: CaseReducer<SignupAppState, PayloadAction<string>>;
        setReferralCode: CaseReducer<SignupAppState, PayloadAction<string | null>>;
        resetState: CaseReducer<SignupAppState>;
    }
>({
    name: 'signup',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setUsername: (state, { payload }) => {
            state.username = payload;
        },
        setReferralCode: (state, { payload }) => {
            state.referralCode = payload;
        },
        resetState: (state) => {
            return initialState;
        },
    },
});

export const useSignupSelector = buildSubStateSelector<SignupAppState>((state) => state.signup);
export const signupActions = slice.actions;
export const signupReducer = slice.reducer;
