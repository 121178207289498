import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import styles from './SettingsContainer.module.scss';

interface SettingsContainerProps {
    title: string;
    backUrl?: string;
    backFn?: () => void;
    padded?: boolean;
    popout?: any;
}

export const SettingsContainer: React.FunctionComponent<SettingsContainerProps> = ({
    children,
    title,
    backUrl = '',
    backFn,
    padded,
    popout,
}) => {
    let renderChildren = children;
    if (padded) renderChildren = <div className={styles.paddedContentContainer}>{children}</div>;

    let backChevron = null;
    if (backUrl)
        backChevron = (
            <Link to={backUrl} className={styles.chevronContainer}>
                <FiChevronLeft className={styles.chevron} size={24} />
            </Link>
        );
    if (backFn) {
        backChevron = <FiChevronLeft className={styles.chevron} size={24} onClick={backFn} />;
    }

    return (
        <div className={styles.background}>
            <div style={{ display: 'flex' }}>
                <div className={styles.settingsContainer}>
                    <div className={styles.headerBar}>
                        {backChevron}
                        {title}
                    </div>
                    {renderChildren}
                </div>
                {popout}
            </div>
        </div>
    );
};
