import dayjs from 'dayjs';
import { InboxAppState } from '../features/inbox/inboxSlice';

export const emailRegex = /.{1,}@.{1,}\..{1,}/;

export const getPasswordErrorIfInsufficient = (password: string) => {
    const alphabetLower = 'abcdefghijklmnopqrstuvwxyz';
    const alphabetUpper = alphabetLower.toUpperCase();
    const numbers = '0123456789';
    if (password.length < 8) return 'Password must be at least 8 characters';
    if (!alphabetLower.split('').some((letter) => password.includes(letter)))
        return 'Password must include a lowercase letter';
    if (!alphabetUpper.split('').some((letter) => password.includes(letter)))
        return 'Password must include an uppercase letter';
    if (!numbers.split('').some((number) => password.includes(number)))
        return 'Password must include a number';
};

export const getNamesCharacters = (name: String) => {
    var matches = name.match(/\b(\w)/g);
    if (matches) {
        return matches.join('').substr(0, 2);
    } else {
        return 'NN';
    }
};

export const formatDate = (dateString: string) => {
    return dayjs.utc(dateString, 'YYYY-MM-DD hh:mm').local().format('hh:mm A');
};

export const formatMessageSentAt = (dateString: string) => {
    if(dayjs().isSame(dateString, 'day')) {
        return dayjs.utc(dateString, 'YYYY-MM-DD hh:mm').local().format('hh:mm A');
    } else if(dayjs().subtract(1, 'day').isSame(dateString, 'day')) {
        return 'Yesterday';
    } else if(dayjs().isSame(dateString, 'week')) {
        return dayjs.utc(dateString, 'YYYY-MM-DD hh:mm').local().format('dddd');
    } else {
        return dayjs.utc(dateString, 'YYYY-MM-DD hh:mm').local().format('MM/DD/YYYY');
    }
};

export const isMobile = navigator.maxTouchPoints;

export const getUnreadPersonalInboxCount = (inboxData: InboxAppState) => {
    const { inboxUsers = [] } = inboxData;
    return inboxUsers.reduce((acc, curr) => {
        return acc + (curr.conversation ? curr.conversation.unread : 0);
    }, 0);
};

export const getUnreadOrganizationInboxCount = (inboxData: InboxAppState) => {
    const { organizations = [] } = inboxData;
    return organizations.reduce((acc, curr) => {
        return acc + (curr.organization ? curr.organization.unread : 0);
    }, 0);
};
