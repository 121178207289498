import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '../../../components/button/Button';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { Spacer } from '../../../components/spacer/Spacer';
import { TextInput } from '../../../components/textInput/TextInput';
import { useSignupSelector } from './signupSlice';
import { addReferralCode, checkIfUsernameAvailable } from './signupActionCreators';
import { PanelParagraphText } from '../../../components/panelParagraphText/PanelParagraphText';
import { toast } from 'react-toastify';

export const SignupChooseUsername = () => {
    const dispatch = useDispatch();
    const { loading } = useSignupSelector((state) => state);
    const { username: reduxUsername, referralCode: reduxReferralCode } = useSignupSelector(
        (s) => s
    );
    const [username, setUsername] = useState(reduxUsername);
    const [referralCode, setReferralCode] = useState(reduxReferralCode ?? '');

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!username) {
            return toast.error('Please enter a username');
        }
        const detectProhibitedCharacters = /[^A-Za-z0-9_]/g;
        if (detectProhibitedCharacters.test(username)) {
            return toast.error(
                'Your username can only use letters, numbers, and underscores, please try again'
            );
        }

        if (referralCode !== '') {
            dispatch(
                addReferralCode(referralCode, () => {
                    dispatch(checkIfUsernameAvailable(username));
                })
            );
        } else {
            dispatch(checkIfUsernameAvailable(username));
        }
    };

    return (
        <OnboardingContainer>
            <Panel>
                <h2>Choose a Username</h2>
                <PanelParagraphText>
                    Your username may contain lowercase letters, numbers, and underscores. We
                    recommend choosing a username that easily identifies you.
                </PanelParagraphText>
                <Spacer size={20} />
                <form onSubmit={onSubmit}>
                    <TextInput
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Your Username"
                    />
                    <Spacer size={10} />
                    <Button disabled={loading}>Continue</Button>
                </form>
            </Panel>
        </OnboardingContainer>
    );
};
