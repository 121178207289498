import React from 'react';

import { XOR } from '../../utils/types';

interface ExpandSpacerProps {
    expand?: true;
    size?: undefined;
    horizontal?: undefined;
}

interface StandardSpacerProps {
    expand?: never;
    size?: number;
    horizontal?: boolean;
    inlineFlex?: boolean;
}

type SpacerProps = XOR<ExpandSpacerProps, StandardSpacerProps>;

export const Spacer: React.FunctionComponent<SpacerProps> = (props) => {
    if (props.expand) {
        return <div style={{ flex: 1 }} />;
    }

    const style = {
        display: props.inlineFlex ? 'inline-flex' : 'flex',
        [props.horizontal ? 'width' : 'height']: props.size ?? 10,
    };

    return <div style={style} />;
};
