import React from 'react';

import styles from './Link.module.scss';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

interface CustomLinkProps extends LinkProps {
    unstyled?: boolean;
}

export const Link: React.FunctionComponent<
    CustomLinkProps & React.RefAttributes<HTMLAnchorElement>
> = (props) => {
    const { className = '', unstyled, ...passthroughProps } = props;
    let classList = unstyled ? styles.unstyled : styles.link;
    classList += ` ${className}`;
    return <ReactRouterLink className={classList} {...passthroughProps} />;
};
