import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './VerificationRequired.module.scss';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { logout, sendEmailVerification } from '../authActionCreators';
import { PanelParagraphText } from '../../../components/panelParagraphText/PanelParagraphText';
import { Link } from '../../../components/link/Link';
import { Spacer } from '../../../components/spacer/Spacer';
import { FiMail } from 'react-icons/fi';

export const VerificationRequired = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(sendEmailVerification());
    }, [dispatch]);

    const onClickResend = () => {
        dispatch(sendEmailVerification(true));
    };

    const onClickBack = async () => {
        dispatch(logout());
        history.push('/');
    };

    return (
        <OnboardingContainer>
            <Panel backFn={onClickBack}>
                <div className={styles.emailCircle}>
                    <FiMail size={50} />
                </div>
                <Spacer size={10} />
                <h2 style={{ textAlign: 'center' }}>Please Check Your Email</h2>
                <Spacer size={5} />
                <PanelParagraphText center>
                    We sent you an email to confirm your email address. Follow the link in the
                    email.
                </PanelParagraphText>
                <Spacer size={60} />
                <p className={styles.bottomText}>
                    Didn't receive an email? &nbsp;
                    <Link to="#" onClick={onClickResend}>
                        Resend email
                    </Link>
                </p>
            </Panel>
        </OnboardingContainer>
    );
};
