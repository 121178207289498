import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

const initialState = {
    loading: false,
    stayLoggedIn: false,
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
    loginError: '',
};

export type LoginAppState = typeof initialState;

export const slice = createSlice<
    LoginAppState,
    {
        setLoading: CaseReducer<LoginAppState, PayloadAction<boolean>>;
        setStayLoggedIn: CaseReducer<LoginAppState, PayloadAction<boolean>>;
        setEmail: CaseReducer<LoginAppState, PayloadAction<string>>;
        setEmailError: CaseReducer<LoginAppState, PayloadAction<string>>;
        setPassword: CaseReducer<LoginAppState, PayloadAction<string>>;
        setPasswordError: CaseReducer<LoginAppState, PayloadAction<string>>;
        setLoginError: CaseReducer<LoginAppState, PayloadAction<string>>;
        resetState: CaseReducer<LoginAppState>;
    }
>({
    name: 'login',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setStayLoggedIn: (state, { payload }) => {
            state.stayLoggedIn = payload;
        },
        setEmail: (state, { payload }) => {
            state.email = payload;
        },
        setEmailError: (state, { payload }) => {
            state.emailError = payload;
        },
        setPassword: (state, { payload }) => {
            state.password = payload;
        },
        setPasswordError: (state, { payload }) => {
            state.passwordError = payload;
        },
        setLoginError: (state, { payload }) => {
            state.loginError = payload;
        },
        resetState: (state) => {
            return initialState;
        },
    },
});

export const useLoginSelector = buildSubStateSelector<LoginAppState>((state) => state.login);
export const loginActions = slice.actions;
export const loginReducer = slice.reducer;
