import React from 'react';
import { Loader } from 'semantic-ui-react';
import { buttonColors } from '../../utils/types';
import { Spacer } from '../spacer/Spacer';
import styles from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    fullWidth?: boolean;
    inline?: boolean;
    inverted?: boolean;
    iconSrc?: string;
    iconSize?: number;
    iconPosition?: 'left' | 'right';
    color?: buttonColors;
    small?: boolean;
    loading?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const {
        children,
        className = '',
        iconSize = 20,
        iconPosition = 'left',
        color = 'blue',
        fullWidth,
        inline,
        inverted,
        small,
        iconSrc,
        loading,
        ...passthroughProps
    } = props;

    let classList = `${styles.button} ${styles[color]}`;

    if (fullWidth) classList += ` ${styles.fullWidth}`;
    if (inline) classList += ` ${styles.inline}`;
    if (inverted) classList += ` ${styles.inverted}`;
    if (small) classList += ` ${styles.small}`;

    let renderContents;
    if (iconSrc) {
        classList += ` ${styles.iconButton}`;
        const renderIcon = (
            <img src={iconSrc} style={{ width: iconSize, height: iconSize }} alt="" />
        );
        const renderSpacer = <Spacer size={iconSize} horizontal />;
        renderContents = (
            <>
                {iconPosition === 'left' ? renderIcon : renderSpacer}
                <span>{children}</span>
                {iconPosition === 'right' ? renderIcon : renderSpacer}
            </>
        );
    } else {
        renderContents = children;
    }

    return (
        <button className={`${classList} ${className}`} {...passthroughProps}>
            {loading && <Loader active size="small" />}
            {renderContents}
        </button>
    );
};
