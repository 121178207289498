import React from 'react';
import { FiX } from 'react-icons/fi';
import { voidFn } from '../../utils/types';

import styles from './SettingsContainer.module.scss';

interface SettingsContainerPopoutProps {
    title: string;
    onClickClose: voidFn;
    padded?: boolean;
}

export const SettingsContainerPopout: React.FunctionComponent<SettingsContainerPopoutProps> = ({
    children,
    title,
    onClickClose,
    padded = true,
}) => {
    let renderChildren = children;
    if (padded) renderChildren = <div className={styles.paddedContentContainer}>{children}</div>;

    return (
        <div className={styles.background}>
            <div className={styles.settingsContainerPopout}>
                <div className={styles.headerBar}>
                    <FiX onClick={onClickClose} size={24} className={styles.closeIcon} />
                    {title}
                </div>
                {renderChildren}
            </div>
        </div>
    );
};
