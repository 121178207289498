// export const config = {
//     apiUrl: 'https://localhost:21002',
//     webUrl: 'http://localhost:3000',
//     firebase: {
//         apiKey: 'AIzaSyB5jKxEAOJTjy9D2vUXCT8WZ9YvUM-c26Y',
//         appId: '1:419716885166:web:715a729a4931f1decf1b3f',
//         authDomain: 'teeq-devel.firebaseapp.com',
//         measurementId: 'G-BW58HFEKTH',
//         messagingSenderId: '419716885166',
//         projectId: 'teeq-devel',
//         storageBucket: 'teeq-devel.appspot.com',
//     },
//     stripe: {
//         publicKey:
//             'pk_test_51IkpkVL4GaHLr4NvquAXM6OgeM6YCd0RC6TGgx5LFVwmLgoJvtxf5nzMONQJ3aoNDxjhd0wghEecXqnmnTjOUJVM00nraHztg7',
//     },
//     version: process.env.REACT_APP_TEEQ_VERSION || 'dev',
// };

export const config = {
    // TODO: Update these URLs with custom domain
    // apiUrl: 'https://teeq-api-staging.azurewebsites.net',
    apiUrl: process.env.REACT_APP_API_URL,
    // webUrl: 'https://d2kiflvxl3d4mn.cloudfront.net',
    webUrl: process.env.REACT_APP_CLOUDFRONT_URL,
    firebase: {
        // apiKey: 'AIzaSyB5jKxEAOJTjy9D2vUXCT8WZ9YvUM-c26Y',
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        // appId: '1:419716885166:web:715a729a4931f1decf1b3f',
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        // authDomain: 'teeq-devel.firebaseapp.com',
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        // measurementId: 'G-BW58HFEKTH',
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || undefined,
        // messagingSenderId: '419716885166',
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        // projectId: 'teeq-devel',
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        // storageBucket: 'teeq-devel.appspot.com',
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    },
    stripe: {
        publicKey: process.env.REACT_APP_STRIPE_API_KEY,
    },
    version: process.env.REACT_APP_TEEQ_VERSION || 'dev',
};
