import React, { useState, useEffect } from 'react';
import { FiCheck, FiUserPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import styles from './AddContact.module.scss';
import { axios } from '../../services/axiosService';
import { updateContactRequests } from '../inbox/inboxActionsCreator';
import { Search } from '../../components/search/Search';
import { Spacer } from '../../components/spacer/Spacer';
import { useAppStateSelector } from '../../store/utilities/useAppStateSelector';

const NameFormatter = (name: String) => {
    var matches = name.match(/\b(\w)/g);
    if (matches) {
        return matches.join('');
    } else {
        return 'NN';
    }
};

interface UsersToContact {
    firstName: string;
    lastName: string;
    username: string;
    id: string;
    isContactRequestPending?: boolean;
}

export const AddContact = () => {
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState<UsersToContact[]>([]);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState({ id: '', sent: false });
    const dispatcher = useDispatch();

    const inboxData = useAppStateSelector((state) => state.inbox.inboxUsers);

    const allContacts = inboxData.map((data) => data.users).flat();

    useEffect(() => {
        return () => {
            dispatcher(updateContactRequests());
        };
    }, [dispatcher]);

    const inputOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        var value = event.target.value;
        setSearch(value);
        if (value.length >= 3) {
            var users = (await axios.get('/users/search?searchTerm=' + value)).data;
            setUsers(users.data);
        } else {
            setUsers([]);
        }
    };

    const handleCancelClick = () => {
        setUsers([]);
        setSearch('');
    };

    const handleAddClick = async (user: UsersToContact) => {
        if (!user.isContactRequestPending && !sending) {
            setSending(true);
            const status = (
                await axios.post('/user/contact_request', {
                    userId: user.id,
                })
            ).status;
            if (status === 200) {
                user.isContactRequestPending = true;
            }
            setSent({ id: user.id, sent: true });
            setTimeout(() => {
                setSent({ id: '', sent: false });
            }, 2000);
            setSending(false);
        }
    };

    return (
        <div>
            <div className={styles.headerBar}>
                <label className={styles.headerBarTitle}>Add Contact</label>
            </div>
            <div className={styles.contentContainer}>
                <Search value={search} onChange={inputOnChange} onCancel={handleCancelClick} />
                <Spacer size={15} />
                <div className={styles.searchResultsContainer}>
                    <label className={styles.searchResultsLabel}>Search Results : </label>
                    {users.map((user, index) => {
                        const isContact = !!allContacts.find((contact) => contact.id === user.id);
                        return (
                            <div
                                key={index}
                                className={styles.searchResult}
                                style={{
                                    backgroundColor:
                                        sent.sent && user.id === sent.id ? '#6CC596' : '',
                                }}
                            >
                                <div className={styles.iconDetailsContainer}>
                                    <div className={styles.userIcon}>
                                        {NameFormatter(user.firstName + ' ' + user.lastName)}
                                    </div>
                                    <div className={styles.detailsContainer}>
                                        {sent.sent && user.id === sent.id ? (
                                            <div className={styles.requestSent}>
                                                <label style={{ fontWeight: 'bold' }}>
                                                    Contact Request Sent
                                                </label>
                                            </div>
                                        ) : (
                                            <div className={styles.userNameContainer}>
                                                <label style={{ fontWeight: 'bold' }}>
                                                    {user.firstName + ' ' + user.lastName}
                                                </label>
                                                <label>{user.username}</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.addPending}>
                                    {user.isContactRequestPending ? (
                                        sent.sent && user.id === sent.id ? (
                                            <FiCheck size={24} />
                                        ) : (
                                            <label className={styles.requestPendingText}>
                                                Contact request pending
                                            </label>
                                        )
                                    ) : isContact ? (
                                        <label className={styles.requestPendingText}>
                                            Already a contact
                                        </label>
                                    ) : (
                                        <FiUserPlus
                                            onClick={() => handleAddClick(user)}
                                            className={styles.addIcon}
                                            size={24}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
