import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PersonalOrganization } from './personalInbox/personalInbox';
import { OrganizationInbox } from './organizationInbox/organizationalInbox';
import { ContactRequest } from './contactRequest';
import { MessageActionModal } from '../../components/messageActionModal/messageActionModal';
import { routeUrls } from '../../utils/constants';

export const Inbox = () => {
    return (
        <Fragment>
            <MessageActionModal />
            <Switch>
                <Route exact path={routeUrls.inbox} component={PersonalOrganization} />
                <Route exact path={routeUrls.inboxOrg} component={OrganizationInbox} />
                <Route exact path={routeUrls.inboxContactRequests} component={ContactRequest} />
                <Redirect to="/" />
            </Switch>
        </Fragment>
    );
};
