import React, { useRef, useEffect } from 'react';
import { FiCamera, FiEdit, FiHome, FiMessageSquare, FiSettings } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import styles from './Sidebar.module.scss';
import { routeUrls } from '../../utils/constants';
import { voidFn } from '../../utils/types';
import { Spacer } from '../spacer/Spacer';
import { FeedbackTypePickerModal } from '../feedbackTypePickerModal/FeedbackTypePickerModal';
import { useDispatch } from 'react-redux';
import { videoAnnotateActions } from '../../features/videoAnnotate/videoAnnotateSlice';
import { getConversations, updateConversations } from '../../features/inbox/inboxActionsCreator';
import { useInboxSelector } from '../../features/inbox/inboxSlice';
import { getUnreadOrganizationInboxCount, getUnreadPersonalInboxCount } from '../../utils/helpers';
import Logo from '../../assets/teeq_logo_white@3x.png';
import { initialInboxRoute } from '../../utils/routeHelers';
import { useAuthSelector } from '../../features/auth/authSlice';

interface SidebarItemProps {
    icon?: any;
    label: string;
    url?: any;
    onClick?: voidFn;
    noNavigation?: boolean;
}

const SidebarItem: React.FunctionComponent<SidebarItemProps> = ({
    icon,
    label,
    url,
    noNavigation = false,
    onClick = () => null,
}) => {
    const location = useLocation();

    let classList = styles.sidebarItem;
    if (location.pathname === url) {
        classList += ` ${styles.activeSidebarItem}`;
    }
    const sidebarItem = (
        <div className={classList} onClick={onClick}>
            {icon}
            <Spacer horizontal size={10} />
            {label}
        </div>
    );

    if (noNavigation) return sidebarItem;

    return <Link to={url}>{sidebarItem}</Link>;
};

export const Sidebar = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const inboxData = useInboxSelector((state) => state);
    const user = useAuthSelector((state) => state.databaseUser);
    const unreadPersonalInboxCount = getUnreadPersonalInboxCount(inboxData);
    const unreadOrganizationInboxCount = getUnreadOrganizationInboxCount(inboxData);
    const unreadCount = unreadPersonalInboxCount + unreadOrganizationInboxCount;

    const sourceVideoInputRef = useRef<HTMLInputElement>(null);

    const openFeedbackFilePicker = () => {
        sourceVideoInputRef!.current!.click();
    };

    const handleSourceVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e?.target?.files?.length || !e.target.files[0])
            return console.error('No video found in video change event');

        const sourceVideoUrl = URL.createObjectURL(
            new Blob([e.target.files[0]], { type: e.target.files[0].type })
        );
        history.push({
            pathname: routeUrls.feedback,
            state: { sourceVideoUrl, fileType: e.target.files[0].type },
        });
        // Reset the value, because otherwise if they back out and select the same file again, the onchange event won't fire
        e.target.value = '';
    };

    let interval: any = null;
    useEffect(() => {
        function update(firstTime = false) {
            if (firstTime) {
                let time = new Date(Date.now() - 3500).toISOString();
                dispatch(getConversations(time));
            } else {
                let time = new Date(Date.now() - 3500).toISOString();
                dispatch(updateConversations(time));
            }
        }

        // Call the function immediately on component mount
        update(true);

        const oneMinute = 1 * 60 * 1000;
        const interval = setInterval(update, oneMinute);

        return () => clearInterval(interval);
    }, [interval]);

    return (
        <>
            <FeedbackTypePickerModal openFeedbackFilePicker={openFeedbackFilePicker} />
            <div className={styles.container}>
                <div className={styles.brand}>
                    <img src={Logo} alt="Teeq Logo" />
                </div>
                <Spacer size={10} />

                <SidebarItem
                    label="Home"
                    icon={<FiHome className={styles.icon} />}
                    url={routeUrls.home}
                />
                <div style={{ position: 'relative' }}>
                    <SidebarItem
                        label="Inbox"
                        icon={<FiMessageSquare className={styles.icon} />}
                        url={initialInboxRoute(user)}
                    />
                    {unreadCount !== 0 && <div className={styles.onMessage}>{unreadCount}</div>}
                </div>
                <SidebarItem
                    label="Feedback"
                    icon={<FiEdit className={styles.icon} />}
                    url={routeUrls.feedback}
                    onClick={() =>
                        dispatch(videoAnnotateActions.setIsFeedbackTypePickerModalOpen(true))
                    }
                    noNavigation
                />
                <SidebarItem
                    label="Record"
                    icon={<FiCamera className={styles.icon} />}
                    url={routeUrls.record}
                />

                <div className={styles.settingsSidebarItem}>
                    <SidebarItem
                        label="Settings"
                        icon={<FiSettings className={styles.icon} />}
                        url={routeUrls.settings}
                    />
                </div>

                <input
                    ref={sourceVideoInputRef}
                    onChange={handleSourceVideoChange}
                    id="sourceVideoFileInput"
                    type="file"
                    accept="video/mp4, video/quicktime, video/webm, image/*"
                    style={{ display: 'none' }}
                />
            </div>
        </>
    );
};
