import { Fragment } from 'react';
import commonStyles from './../Settings.module.scss';
import styles from './GetFree.module.scss';
import { useHistory } from 'react-router';
import { FiChevronLeft } from 'react-icons/fi';
import { routeUrls } from '../../../utils/constants';

export const GetFreeDetails = () => {
    const history = useHistory();

    return (
        <Fragment>
            <div className={commonStyles.globalStylingForAccount}>
                <div className={commonStyles.mainHeader}>
                    <FiChevronLeft
                        className={commonStyles.cursorPointer}
                        size={24}
                        onClick={() => history.push(routeUrls.getfree)}
                    />
                    <label className={commonStyles.backTitle}>Referral Details</label>
                </div>
            </div>
            <div className={commonStyles.globalStylingForAccount}>
                <div className={styles.mainBody}>
                    <div style={{ padding: '20px', fontSize: '1.1rem' }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et tortor
                        eget lacus fringilla maximus in a sapien. Duis lorem purus, sollicitudin ut
                        faucibus eget, suscipit id purus. Praesent sed purus bibendum, porttitor
                        purus sit amet, dignissim ipsum. Nulla vulputate lectus diam, sed gravida
                        lectus imperdiet nec. Phasellus sagittis orci in ex sagittis pretium. Etiam
                        placerat tincidunt vehicula. Aenean posuere enim in mauris bibendum iaculis.
                        Fusce in ante justo. Duis vestibulum augue a urna viverra pretium. Mauris
                        risus risus, malesuada et tortor eu, tincidunt malesuada justo. Pellentesque
                        rhoncus velit vel bibendum efficitur. Cras fermentum ipsum nec rhoncus
                        tempus. Curabitur nunc nunc, tempus quis congue sed, pharetra ac mi.
                        Vestibulum metus eros, scelerisque vel ullamcorper sit amet, vestibulum sit
                        amet odio. Nunc in quam nunc. Mauris volutpat rhoncus aliquet. Curabitur
                        imperdiet quam at sem finibus sagittis. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus. Cras sollicitudin ac
                        velit eu egestas. Nullam et volutpat magna, eu vestibulum sem. In fringilla
                        lacus sit amet mi rhoncus maximus sit amet id nunc. Cras lobortis urna eget
                        rutrum commodo. Orci varius natoque penatibus et magnis dis parturient
                        montes, nascetur ridiculus mus. Quisque scelerisque hendrerit velit, sit
                        amet rhoncus velit tristique quis. Suspendisse luctus risus nec sapien
                        mollis, ac lobortis sem feugiat. Pellentesque sapien nibh, volutpat sagittis
                        augue sed, volutpat volutpat purus. Nullam sit amet erat erat. Integer
                        gravida feugiat nulla nec elementum. Nulla ornare purus nulla, in dignissim
                        magna imperdiet fermentum. Integer iaculis suscipit sodales. Sed vulputate,
                        diam eu rutrum egestas, est felis vehicula mauris, a egestas neque metus
                        eget libero. Praesent nec velit facilisis, tincidunt turpis eu, gravida
                        lorem. Sed ac dui tempus, varius velit nec, vulputate est. Vivamus fermentum
                        dignissim magna quis viverra. Mauris gravida nulla in est luctus rutrum.
                        Etiam sagittis, mauris et consectetur feugiat, felis ipsum sollicitudin
                        neque, nec posuere libero nisl non felis. Praesent ultricies, urna a egestas
                        iaculis, arcu nibh malesuada sem, et tincidunt ipsum libero eu tellus.
                        Integer convallis rhoncus volutpat. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Integer accumsan, neque et lobortis dignissim, turpis
                        mauris aliquet arcu, viverra tincidunt risus mauris nec enim. Curabitur
                        facilisis suscipit purus tincidunt efficitur. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Proin placerat justo mi, nec pellentesque
                        lectus imperdiet elementum. Sed eu elementum erat. Quisque feugiat dui
                        consectetur lorem feugiat, ac placerat ex pellentesque. Vivamus vel
                        tincidunt elit, sed eleifend ex. Curabitur vel nisi id sem tincidunt finibus
                        eu ut mi. Donec ligula velit, venenatis non ultrices sed, sodales a mauris.
                        Integer faucibus ultrices eleifend. Aliquam tempus porta nisi. Interdum et
                        malesuada fames ac ante ipsum primis in faucibus. Vestibulum tristique
                        rhoncus pharetra. Sed ut efficitur erat. Donec vitae ligula eget sem viverra
                        facilisis. Vestibulum eu egestas nulla. Sed nisi mauris, elementum eu nulla
                        nec, consequat laoreet purus. Ut a nisi aliquet, viverra mi ac, pulvinar
                        lectus. Duis pharetra elit eu libero pellentesque, pretium tincidunt est
                        posuere. Aliquam fermentum turpis orci, ut bibendum sapien interdum sit
                        amet. Vestibulum varius volutpat dolor.
                    </div>
                </div>{' '}
            </div>
        </Fragment>
    );
};
