export const routeUrls = {
    home: '/',
    inbox: '/inbox',
    inboxOrg: '/inbox/org',
    inboxContactRequests: '/inbox/contact_request',
    feedback: '/feedback',
    record: '/record',
    settings: '/settings',
    addcontact: '/addcontact',
    termsofuse: 'https://teeqapp.com/terms',
    privacypolicy: 'https://teeqapp.com/privacy',
    notificationsettings: '/settings/notifications',
    feedbackpreferences: '/settings/feedbackPreference',
    getfree: '/settings/getFree',
    getfreedetails: '/settings/getFree/details',
    accountsettings: '/settings/account',
    subscriptionsettings: '/subscriptionsettings',
    help: 'https://teeqapp.com/help',
};

export const Preferences = {
    PHOTO: 'INSERT_PHOTO_BEHAVIOR',
    VIDEO: 'INSERT_VIDEO_BEHAVIOR',
    REQUEST_EMAIL: 'NEW_CONTACT_REQUEST_EMAIL_NOTIFICATION',
    MESSAGE_EMAIL: 'NEW_MESSAGE_EMAIL_NOTIFICATION',
    REQUEST_NOTIFICATION: 'NEW_CONTACT_REQUEST_PUSH_NOTIFICATION',
    MESSAGE_NOTIFICATION: 'NEW_MESSAGE_PUSH_NOTIFICATION',
    REQUEST_WEB_NOTIFICATION: 'NEW_CONTACT_REQUEST_PUSH_WEB_NOTIFICATION',
    MESSAGE_WEB_NOTIFICATION: 'NEW_MESSAGE_PUSH_WEB_NOTIFICATION',
};

export enum subOptions {
    Annual = 'Yearly',
    Monthly = 'Monthly',
}
