import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { UAParser } from 'ua-parser-js';

import { useAuthSelector } from './features/auth/authSlice';
import { Routes } from './routes';
import { Sidebar } from './components/sidebar/Sidebar';
import firebase from './index';
import { useDispatch } from 'react-redux';
import { getInboxData } from './features/inbox/inboxActionsCreator';
import { isMobile } from './utils/helpers';
import { OrganizationInviteModal } from './features/organizationInvite/OrganizationInviteModal';
import { getPreferences } from './features/settings/settingActionsCreator';

const App = () => {
    const [orgInvite, setOrgInvite] = useState('');
    const [browserWarningVisible, setBrowserWarningVisible] = useState(false);

    const { databaseUser } = useAuthSelector((s) => s);

    const dispatch = useDispatch();

    const passwordResetRequired = databaseUser ? databaseUser.passwordResetRequired : false;

    const isSignupComplete =
        databaseUser && !passwordResetRequired && databaseUser.subscriptionExpirationDate;

    const isSubcribed = !(databaseUser &&
        dayjs(databaseUser.subscriptionExpirationDate).isBefore(dayjs()) &&
        !databaseUser.orgOnly);

    const parser = UAParser();

    useEffect(() => {
        if (parser.browser.name !== 'Chrome' && parser.browser.name !== 'Brave') {
            setBrowserWarningVisible(true);
        }
    }, [parser.browser.name]);

    useEffect(() => {
        if (isSignupComplete) {
            if (firebase.messaging.isSupported()) {
                const messaging = firebase.messaging();
                messaging
                    .requestPermission()
                    .then((token) => {
                        return messaging.getToken();
                    })
                    .then((token) => {})
                    .catch((error) => {
                        console.log(error);
                    });
                messaging.onMessage((payload) => {});
            }
            dispatch(getInboxData());
            dispatch(getPreferences());
        }
    }, [dispatch, isSignupComplete]);

    useEffect(() => {
        const storageItem = localStorage.getItem('orgInviteToken');
        if (storageItem) {
            setOrgInvite(storageItem);
            localStorage.removeItem('orgInviteToken');
        }
    }, []);

    return (
        <div className="App">
            {!!orgInvite && orgInvite.length > 0 && (
                <OrganizationInviteModal
                    token={orgInvite ?? ''}
                    callback={() => setOrgInvite('')}
                />
            )}
            {isSignupComplete && isSubcribed && !isMobile && <Sidebar />}
            {browserWarningVisible && toast.error('Your browser is not fully supported. Please consider using Chrome.')}
            <ToastContainer limit={1} />
            <Routes />
        </div>
    );
};

export default App;
