import Ios from '../../assets/icon-ios.svg';
import Android from '../../assets/icon-andriod.svg';
import styles from './mobileWeb.module.scss';

export const MobileWeb = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.boxContainer}>
                <label className={styles.heading}>Let's get you using the Teeq mobile app</label>
                <p className={styles.paragraph}>
                    In order to ensure a pleasant experience for you please download our mobile app
                </p>
                <label className={styles.download}>Download now:</label>
                <div>
                    <img className={styles.padding1} src={Android} alt="android" />
                    <br />
                    <img className={styles.padding2} src={Ios} alt="ios" />
                </div>
            </div>
        </div>
    );
};
