import { AppState } from '../appState';
import { useSelector } from 'react-redux';

// this is an extremely light-weight wrapper for useSelector. as far as functional
// javascript is concerned, it is absolutely no different than using useSelector
// directly; the only difference is type assistance. with useAppStateSelector,
// AppState has been hard-coded as the top-level state for use in the selector
// function; this allows implicit type checking to determine the type of the selected item
// (TSelected), meaning that the user of this function doesn't need to explicitly
// define the return type.

// here is an example usage of useSelector, an an equivalent example of useAppStateSelector:

// const currentGroup1 = useSelector<AppState, Group>(state => state.authSession.currentGroup);
// const currentGroup2 = useAppStateSelector(state => state.authSession.currentGroup);

// notice how with useSelector, the types AppState and Group must be manually specified. with
// useAppStateSelector, AppState has been hard-coded into the function definition which
// allows the lambda function `selector` to determine the type of TSelected, which in this
// case which will resolve to Group.

export function useAppStateSelector<TSelected>(
    selector: (state: AppState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
    return useSelector<AppState, TSelected>(selector, equalityFn);
}

// this function allows you to create a partial selector... a selector that already has a path
// pre selected. for example, say you have a state that looks like this:
// {
//     counter: {
//         value: number
//     }
// }
// and instead of having to call useAppStateSelector(state => state.counter.value) you wanted to
// create a function called useCounterAppStateSelector and you wanted to pre-scope that function
// to state.counter so that the usage would be like
// useCounterAppStateSelector(counterAppState => counterAppState.value), you would do that with
// this function. you would use it like:
// const useCounterAppStateSelector = buildSubStateSelector(state => state.counter)

export function buildSubStateSelector<TSubState>(
    baseSubStateSelector: (state: AppState) => TSubState
) {
    return function <TSelected>(
        subSelector: (state: TSubState) => TSelected,
        equalityFn?: (left: TSelected, right: TSelected) => boolean
    ) {
        const selector = (state: AppState) => subSelector(baseSubStateSelector(state));
        return useSelector<AppState, TSelected>(selector, equalityFn);
    };
}
