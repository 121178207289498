import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';

import styles from './SendVideoModal.module.scss';
import { Modal } from '../modal/Modal';
import { Spacer } from '../spacer/Spacer';
import { FiSend, FiDownload } from 'react-icons/fi';
import { voidFn } from '../../utils/types';
import { GreyText } from '../greyText/GreyText';
import { Search } from '../search/Search';
import { useInboxSelector } from '../../features/inbox/inboxSlice';
import { loadSendingList } from '../../features/inbox/inboxActionsCreator';
import { SendingListItem } from '../../types/Conversation';
import { Button } from '../button/Button';
import { routeUrls } from '../../utils/constants';
import { uploadFeedbackVideo, sendFeedbackVideo } from '../../utils/videos';

interface SendVideoModalProps {
    closeModal: voidFn;
    conversationId?: string;
    isFeedback?: boolean;
    isModalOpen: boolean;
    /** Callback when a video has successfully been sent */
    onSuccess?: () => void;
    onSuccessRoute?: string;
    videoUri: string;
}

export const SendVideoModal: React.FunctionComponent<SendVideoModalProps> = ({
    closeModal,
    conversationId,
    isModalOpen,
    isFeedback = false,
    onSuccess,
    onSuccessRoute,
    videoUri,
}) => {
    // If this modal is being displayed on the feedback screen, the user gets to decide whether to send to a contact or to download the file
    // If it is being displayed on the record screen, it goes straight to Send To Contact
    const [showContactList, setShowContactList] = useState(!isFeedback);
    const [searchValue, setSearchValue] = useState('');
    const [showDoneButton, setShowDoneButton] = useState(false);

    const history = useHistory();

    const dispatch = useDispatch();
    const { sendingList } = useInboxSelector((state) => state);

    useEffect(() => {
        dispatch(loadSendingList(searchValue));
    }, [searchValue]);

    const video = useRef<any>();
    const canvas = useRef<any>();
    const [videoId, setVideoId] = useState<string>();

    const onSaveClick = () => {
        setShowDoneButton(true);
        if (onSuccess) {
            onSuccess();
        }
    };

    const onDoneClick = () => {
        // go back to personal or organization inbox with conversationId
        history.push({
            pathname: onSuccessRoute || routeUrls.home,
            state: {
                conversationId,
            },
        });
    };

    useEffect(() => {
        (async () => {
            const videoIdRes: any = await uploadFeedbackVideo(video, canvas, videoUri);
            setVideoId(videoIdRes);
        })();
    }, [videoUri]);

    let modalContents;

    if (isFeedback && !showContactList) {
        modalContents = (
            <>
                <Spacer size={5} />
                <GreyText center>Where would you like to send your feedback?</GreyText>
                <Spacer size={25} />
                <div className={styles.whereToSendOption} onClick={() => setShowContactList(true)}>
                    <FiSend size={24} />
                    Send to Teeq contact
                </div>
                <Spacer size={20} />
                <a
                    href={videoUri}
                    download="teeq-export.webm"
                    className={styles.whereToSendOption}
                    onClick={onSaveClick}
                >
                    <FiDownload size={24} />
                    Save to files
                </a>
            </>
        );
    } else {
        modalContents = (
            <>
                <h2 className={styles.panelTitle}>Send to Contact</h2>
                <Spacer size={5} />
                <Search
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onCancel={() => setSearchValue('')}
                    placeholder="Search"
                />
                <div className={styles.listUserListContainer}>
                    {sendingList.map((sendingListUser, idx) => (
                        <SendingListUser
                            key={idx}
                            user={sendingListUser}
                            videoUri={videoUri}
                            videoId={videoId}
                            isSubItem={false}
                            onSubmitSuccess={() => {
                                if (onSuccess) {
                                    onSuccess();
                                }
                                setShowDoneButton(true);
                            }}
                        />
                    ))}
                </div>
            </>
        );
    }

    return (
        <>
            <Modal isOpen={isModalOpen} onDimmerClick={closeModal} onCloseButtonClick={closeModal}>
                <div className={styles.panelContentsWrapper}>
                    {modalContents}
                    {showDoneButton ? (
                        <Button onClick={onDoneClick} className={styles.doneButton}>
                            Done
                        </Button>
                    ) : null}
                </div>
            </Modal>
            <div style={{ visibility: 'hidden', position: 'absolute' }}>
                <video ref={video} src="" />
                <canvas ref={canvas}></canvas>
            </div>
        </>
    );
};

interface SendingListUserProps {
    isSubItem: boolean;
    onSubmitSuccess: () => void;
    user: SendingListItem;
    videoId?: string;
    videoUri: string;
}

enum SendStatus {
    unsent = 'unsent',
    sending = 'sending',
    sent = 'sent',
}

const SendingListUser: React.FunctionComponent<SendingListUserProps> = ({
    isSubItem,
    onSubmitSuccess,
    user,
    videoId = '',
    videoUri,
}) => {
    const [sendingStatus, setSendingStatus] = useState<SendStatus>(SendStatus.unsent);
    const [progress, setProgress] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const { profileImage, shortName, displayName, sent, conversationId, expandable, subItems } =
        user;
    const messageSent = sent || sendingStatus === SendStatus.sent;

    let iconStyle = user.profileImage
        ? {
              backgroundImage: `url('${profileImage}')`,
          }
        : {};

    return (
        <>
            <div
                className={`${styles.listUserContainer} ${
                    isSubItem ? styles.subItemListUserContainer : ''
                }`}
                key={conversationId}
                onClick={() => setExpanded(!expanded)}
            >
                <div style={iconStyle} className={styles.listUserIcon}>
                    {!profileImage && shortName}
                </div>
                <div className={styles.listUserName}>{displayName}</div>
                <div className={styles.sendProgressWrapper}>
                    {expandable ? (
                        <>
                            {!expanded ? (
                                <FiChevronRight
                                    style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <>
                                    <FiChevronDown
                                        style={{ cursor: 'pointer' }}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {sendingStatus === SendStatus.sending && (
                                <div className={styles.progress}>
                                    {progress !== 100 ? (
                                        <progress value={progress} max={100} />
                                    ) : (
                                        <div>Processing your video...</div>
                                    )}
                                </div>
                            )}
                            <Button
                                disabled={
                                    messageSent || sendingStatus === SendStatus.sending || !videoId
                                }
                                onClick={() => sendFeedbackVideo(videoId, conversationId, onSubmitSuccess, setSendingStatus, setProgress)}
                                className={styles.sendButton}
                                style={messageSent ? { backgroundColor: '#6CC596' } : {}}
                                loading={sendingStatus === SendStatus.sending || !videoId}
                            >
                                {messageSent ? 'Sent' : 'Send'}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {expanded && subItems ? (
                <>
                    {subItems.map((sendingListUser, idx) => (
                        <SendingListUser
                            key={idx}
                            user={sendingListUser}
                            videoUri={videoUri}
                            videoId={videoId}
                            isSubItem={true}
                            onSubmitSuccess={onSubmitSuccess}
                        />
                    ))}
                </>
            ) : null}
        </>
    );
};
