import React from 'react';
import { FiX } from 'react-icons/fi';
import { Dimmer } from 'semantic-ui-react';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/dimmer.min.css';

import styles from './Modal.module.scss';
import { Panel } from '../panel/Panel';

export interface ModalProps {
    isOpen: boolean;
    onDimmerClick?: () => void;
    onCloseButtonClick?: () => void;
    horizontalPanelPadding?: boolean;
}

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
    const {
        children,
        isOpen = false,
        onDimmerClick = () => null,
        onCloseButtonClick,
        horizontalPanelPadding,
    } = props;
    // If the dimmer has a click handler, we don't want panel clicks to bubble up to that
    const preventClickPassthrough = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        e.stopPropagation();

    let panelClassList = styles.panel;
    if (horizontalPanelPadding) panelClassList += ` ${styles.horizontalPanelPadding}`;

    return (
        <Dimmer active={isOpen} onClick={onDimmerClick}>
            <div onClick={preventClickPassthrough}>
                <Panel className={panelClassList}>
                    <>
                        {onCloseButtonClick && (
                            <FiX
                                color="black"
                                size={20}
                                className={styles.closeButton}
                                onClick={onCloseButtonClick}
                            />
                        )}
                        {children}
                    </>
                </Panel>
            </div>
        </Dimmer>
    );
};
