import React from 'react';
import { paintColors } from '../../../utils/types';
import styles from './FeedbackPreference.module.scss';
import { FiCamera, FiVideo } from 'react-icons/fi';

export const Feedback = (props: any) => {
    return (
        <div className={styles.feedbackContainer}>
            <label className={styles.feedbackTypeTitle}>{props.title}</label>
            <div className={styles.feedbackSelectionContainer}>
                <div className={styles.selectionRegion}>
                    <div
                        onClick={() => props.toggle('picture-in-picture', props.isPhoto)}
                        className={styles.selectionLeft}
                        style={
                            props.preference === 'picture-in-picture'
                                ? { backgroundColor: paintColors.blue }
                                : {}
                        }
                    >
                        <div className={styles.optionContainer}>
                            <label
                                className={styles.labelOption}
                                style={
                                    props.preference === 'picture-in-picture'
                                        ? {
                                              color: 'white',
                                          }
                                        : { color: '' }
                                }
                            >
                                Picture in Picture
                            </label>
                            <div className={styles.optionImageContainer}>
                                <div className={styles.imageContainer}>
                                    {props.icon == 'camera' ? (
                                        <FiCamera size={24} />
                                    ) : (
                                        <FiVideo size={24} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.selectionRight}
                        onClick={() => props.toggle('full-canvas', props.isPhoto)}
                        style={
                            props.preference !== 'picture-in-picture'
                                ? { backgroundColor: paintColors.blue }
                                : {}
                        }
                    >
                        <div className={styles.optionContainer}>
                            <label
                                className={styles.labelOption}
                                style={
                                    props.preference !== 'picture-in-picture'
                                        ? {
                                              color: 'white',
                                          }
                                        : { color: '' }
                                }
                            >
                                Full Canvas
                            </label>
                            <div className={styles.optionImageContainerCenter}>
                                <div className={styles.imageContainerCenter}>
                                    <div className={styles.imageContainer}>
                                        {props.icon == 'camera' ? (
                                            <FiCamera size={24} />
                                        ) : (
                                            <FiVideo size={24} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
