import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import qs from 'qs';

export const OrganizationInvite = () => {
    const [loaded, setLoaded] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        localStorage.setItem('orgInviteToken', queryParams.token as any);
        setLoaded(true);
    }, []);

    if (!loaded) {
        return null;
    } else {
        return <Redirect to="/" push={false} />;
    }
};
