import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { TextInput } from '../../../components/textInput/TextInput';
import { Button } from '../../../components/button/Button';
import { Spinner } from '../../../components/spinner/Spinner';
import { handleVerifyEmail } from '../authActionCreators';
import { Link } from 'react-router-dom';

const PasswordReset = ({ oobCode, continueUrl }: { oobCode: string; continueUrl: string }) => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            toast.error('Passwords must match');
            return;
        }
        try {
            await firebase.auth().confirmPasswordReset(oobCode, password);
            toast.success('Your password has been reset. Redirecting to login screen...', {
                onClose: () => window.location.assign('login-email'),
            });
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    return (
        <OnboardingContainer>
            <Panel>
                <h2>Enter your new password</h2>
                <form onSubmit={handleSubmit}>
                    <TextInput
                        value={password}
                        placeholder="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />
                    <TextInput
                        value={confirmPassword}
                        placeholder="confirm password"
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                    />
                    <Button type="submit" fullWidth>
                        Log In
                    </Button>
                </form>
                <p>
                    Not working?{' '}
                    <Link
                        to="/initiate-password-reset"
                        style={{
                            color: '#1B5FEA',
                            textDecoration: 'underline',
                        }}
                    >
                        Click here
                    </Link>{' '}
                    to send a new reset password link.
                </p>
            </Panel>
        </OnboardingContainer>
    );
};

export const AuthRedirect = () => {
    const dispatch = useDispatch();
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    const { mode, oobCode, continueUrl } = queryParams;

    useEffect(() => {
        if (mode === 'verifyEmail') {
            dispatch(handleVerifyEmail(oobCode));
        }
    }, [dispatch, mode, oobCode]);

    if (mode === 'resetPassword')
        return <PasswordReset oobCode={oobCode} continueUrl={continueUrl} />;
    if (mode === 'verifyEmail') {
        return <Spinner fullPage isLoading={true} />;
    }
    alert('Redirect mode not supported');
    return null;
};
