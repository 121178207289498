import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { FiChevronLeft, FiUserCheck, FiXCircle } from 'react-icons/fi';

import { useAppStateSelector } from './../../store/utilities/useAppStateSelector';
import styles from './inboxContactRequest.module.scss';
import { getNamesCharacters } from './../../utils/helpers';
import { acceptRejectRequest, getInboxData, updateContactRequests } from './inboxActionsCreator';
import { Spacer } from '../../components/spacer/Spacer';
import { routeUrls } from '../../utils/constants';

export const ContactRequest = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        inbox: { contactRequests: requests },
    } = useAppStateSelector((state) => state);

    const handleAcceptRejectRequest = (id: number, isAccepted: boolean) => {
        dispatch(acceptRejectRequest(id, isAccepted));
        dispatch(updateContactRequests());
        dispatch(getInboxData());
    };

    return (
        <div className={styles.mainDiv}>
            <div className={styles.mainHeader}>
                <FiChevronLeft size={24} onClick={() => history.push(routeUrls.inbox)} />
                <label className={styles.heading}>Contact Requests</label>
            </div>
            <div style={{ padding: '10px' }}>
                {requests.map((item, index) => {
                    return (
                        <div key={index} className={styles.requestContainer}>
                            <div className={styles.requestLeftContainer}>
                                <div className={styles.roundName}>
                                    {getNamesCharacters(item.firstName + ' ' + item.lastName)}
                                </div>
                                <div className={styles.requestMiddleContainer}>
                                    <label className={styles.bold}>
                                        {item.firstName + ' ' + item.lastName}
                                    </label>
                                    <label className={styles.name}>{item.username}</label>
                                </div>
                            </div>
                            <div className={styles.requestRightContainer}>
                                <FiXCircle
                                    size={24}
                                    className={styles.iconReject}
                                    onClick={() => handleAcceptRejectRequest(item.id, false)}
                                />
                                <Spacer horizontal size={20} />
                                <FiUserCheck
                                    size={24}
                                    className={styles.iconAccept}
                                    onClick={() => handleAcceptRejectRequest(item.id, true)}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
