import { Fragment } from 'react';
import commonStyles from './../Settings.module.scss';
import styles from './Notifications.module.scss';
import { useHistory } from 'react-router';
import { Toggle } from './../../../components/Toggle/Toggle';
import { useDispatch } from 'react-redux';
import { FiChevronLeft } from 'react-icons/fi';
import { updatePreference } from '../settingActionsCreator';
import { useSettingsSelector } from '../settingsSlice';
import { routeUrls } from '../../../utils/constants';

export const Notifications = () => {
    const history = useHistory();
    const dispatcher = useDispatch();

    const messageEmail = useSettingsSelector((state) => state.messageEmailPreference);
    const requestEmail = useSettingsSelector((state) => state.newRequestEmailPreference);
    const requestPush = useSettingsSelector((state) => state.newRequestPushNotificationPreference);
    const messagePush = useSettingsSelector((state) => state.messagePushNotificationPreference);
    const requestWebPush = useSettingsSelector(
        (state) => state.newRequestPushWebNotificationPreference
    );
    const messageWebPush = useSettingsSelector(
        (state) => state.messagePushWebNotificationPreference
    );

    const handleClick = (number: Number) => {
        switch (number) {
            case 1:
                dispatcher(
                    updatePreference(
                        messageWebPush.id,
                        number,
                        messageWebPush.value === 'off' ? 'on' : 'off'
                    )
                );
                break;
            case 2:
                dispatcher(
                    updatePreference(
                        requestWebPush.id,
                        number,
                        requestWebPush.value === 'off' ? 'on' : 'off'
                    )
                );
                break;
            case 3:
                dispatcher(
                    updatePreference(
                        messagePush.id,
                        number,
                        messagePush.value === 'off' ? 'on' : 'off'
                    )
                );
                break;
            case 4:
                dispatcher(
                    updatePreference(
                        requestPush.id,
                        number,
                        requestPush.value === 'off' ? 'on' : 'off'
                    )
                );
                break;
            case 5:
                dispatcher(
                    updatePreference(
                        messageEmail.id,
                        number,
                        messageEmail.value === 'off' ? 'on' : 'off'
                    )
                );
                break;
            case 6:
                dispatcher(
                    updatePreference(
                        requestEmail.id,
                        number,
                        requestEmail.value === 'off' ? 'on' : 'off'
                    )
                );
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <div className={commonStyles.globalStylingForAccount}>
                <div className={commonStyles.mainHeader}>
                    <FiChevronLeft
                        className={commonStyles.cursorPointer}
                        size={24}
                        onClick={() => history.push(routeUrls.settings)}
                    />
                    <label className={commonStyles.backTitle}>Notifications</label>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.subContainer}>
                    <label className={styles.subContainerHeading}>Web Push Notifications</label>
                    <div className={styles.padder}>
                        <div className={styles.container}>
                            <label>New Message</label>
                            <Toggle
                                selected={messageWebPush.value === 'on' ? true : false}
                                onClick={() => handleClick(1)}
                            />
                        </div>
                    </div>
                    <div className={styles.padder}>
                        <div className={styles.container}>
                            <label>New Contact Request</label>
                            <Toggle
                                selected={requestWebPush.value === 'on' ? true : false}
                                onClick={() => handleClick(2)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.subContainer}>
                    <label className={styles.subContainerHeading}>Mobile Push Notifications</label>
                    <div className={styles.padder}>
                        <div className={styles.container}>
                            <label>New Message</label>
                            <Toggle
                                selected={messagePush.value === 'on' ? true : false}
                                onClick={() => handleClick(3)}
                            />
                        </div>
                    </div>
                    <div className={styles.padder}>
                        <div className={styles.container}>
                            <label>New Contact Request</label>
                            <Toggle
                                selected={requestPush.value === 'on' ? true : false}
                                onClick={() => handleClick(4)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
