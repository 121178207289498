import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

const initialState = {
    usingWebcam: false,
    currentVideoTime: 0,
    isVideoModalOpen: false,
    isImageModalOpen: false,
    isDeleteModalOpen: false,
    isFeedbackTypePickerModalOpen: false,
    isDownloadSendModalOpen: false,
    isRecording: false,
    isRecordingInitialized: false,
    isReviewing: false,
    isSourceVideoPlaying: false,
    isDrawToolEnabled: false,
    isMicrophoneEnabled: true,
    isShowingPIPImage: false,
    isLoadingSourceVideo: true,
    isPhotoFullSize: false,
    isVideoFullSize: false,
    paintColor: 'red' as string,
};

export type VideoAnnotateAppState = typeof initialState;

export const slice = createSlice<
    VideoAnnotateAppState,
    {
        setIsUsingWebcam: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setCurrentVideoTime: CaseReducer<VideoAnnotateAppState, PayloadAction<number>>;
        setIsVideoModalOpen: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsImageModalOpen: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsDeleteModalOpen: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsFeedbackTypePickerModalOpen: CaseReducer<
            VideoAnnotateAppState,
            PayloadAction<boolean>
        >;
        setIsDownloadSendModalOpen: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsRecording: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsRecordingInitialized: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsReviewing: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsSourceVideoPlaying: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsDrawToolEnabled: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsMicrophoneEnabled: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsShowingPIPImage: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsLoadingSourceVideo: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsPhotoFullSize: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setIsVideoFullSize: CaseReducer<VideoAnnotateAppState, PayloadAction<boolean>>;
        setPaintColor: CaseReducer<VideoAnnotateAppState, PayloadAction<string>>;
        resetState: CaseReducer<VideoAnnotateAppState>;
    }
>({
    name: 'videoAnnotate',
    initialState,
    reducers: {
        setIsUsingWebcam: (state, { payload }) => {
            state.usingWebcam = payload;
        },
        setCurrentVideoTime: (state, { payload }) => {
            state.currentVideoTime = payload;
        },
        setIsVideoModalOpen: (state, { payload }) => {
            state.isVideoModalOpen = payload;
        },
        setIsImageModalOpen: (state, { payload }) => {
            state.isImageModalOpen = payload;
        },
        setIsDeleteModalOpen: (state, { payload }) => {
            state.isDeleteModalOpen = payload;
        },
        setIsFeedbackTypePickerModalOpen: (state, { payload }) => {
            state.isFeedbackTypePickerModalOpen = payload;
        },
        setIsDownloadSendModalOpen: (state, { payload }) => {
            state.isDownloadSendModalOpen = payload;
        },
        setIsRecording: (state, { payload }) => {
            state.isRecording = payload;
        },
        setIsRecordingInitialized: (state, { payload }) => {
            state.isRecordingInitialized = payload;
        },
        setIsReviewing: (state, { payload }) => {
            state.isReviewing = payload;
        },
        setIsSourceVideoPlaying: (state, { payload }) => {
            state.isSourceVideoPlaying = payload;
        },
        setIsDrawToolEnabled: (state, { payload }) => {
            state.isDrawToolEnabled = payload;
        },
        setIsMicrophoneEnabled: (state, { payload }) => {
            state.isMicrophoneEnabled = payload;
        },
        setIsShowingPIPImage: (state, { payload }) => {
            state.isShowingPIPImage = payload;
        },
        setIsLoadingSourceVideo: (state, { payload }) => {
            state.isLoadingSourceVideo = payload;
        },
        setIsPhotoFullSize: (state, { payload }) => {
            state.isPhotoFullSize = payload;
        },
        setIsVideoFullSize: (state, { payload }) => {
            state.isVideoFullSize = payload;
        },
        setPaintColor: (state, { payload }) => {
            state.paintColor = payload;
        },
        resetState: (state) => {
            return {
                ...initialState,
                isPhotoFullSize: state.isPhotoFullSize,
                isVideoFullSize: state.isVideoFullSize,
            };
        },
    },
});

export const useVideoAnnotateSelector = buildSubStateSelector<VideoAnnotateAppState>(
    (state) => state.videoAnnotate
);
export const videoAnnotateActions = slice.actions;
export const videoAnnotateReducer = slice.reducer;
