import { Fragment, useEffect, useState, useRef } from 'react';
import styles from './../Settings.module.scss';
import Default from './../../../assets/default.png';
import { TextInput } from '../../../components/textInput/TextInput';
import { Spacer } from '../../../components/spacer/Spacer';
import { Button } from '../../../components/button/Button';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAuthSelector } from '../../auth/authSlice';
import { changePassword, updateProfile } from '../settingActionsCreator';
import { updateUser } from '../../auth/authActionCreators';
import { toast } from 'react-toastify';
import { FiChevronLeft, FiX, FiChevronRight } from 'react-icons/fi';
import { routeUrls } from '../../../utils/constants';

export const Account = () => {
    const { databaseUser, firebaseUser } = useAuthSelector((state) => state);
    const history = useHistory();
    const dispatcher = useDispatch();

    const id = databaseUser?.id;

    const [profileImage, setProfileImage] = useState<string | undefined>(
        databaseUser?.profileImage
    );
    const [email, setEmail] = useState<string | null | undefined>(firebaseUser?.email);
    const [firstName, setFirstName] = useState<string | undefined>(databaseUser?.firstName);
    const [lastName, setLastName] = useState<string | undefined>(databaseUser?.lastName);
    const [username, setUsername] = useState<string | undefined>(databaseUser?.username);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [showPasswordContainer, setShowPasswordContainer] = useState<Boolean>(false);

    const inputFile = useRef<any>(null);

    useEffect(() => {
        setProfileImage(databaseUser?.profileImage === null ? Default : databaseUser?.profileImage);
    }, [databaseUser]);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleFileUpload = async (e: any) => {
        const files = e.target?.files;
        if (files && files.length) {
            const filename = files[0].name;
            var parts = filename.split('.');
            const fileType = parts[parts.length - 1];
            const mimeTypes = ['jpeg', 'png', 'jpg'];
            const isImage = mimeTypes.includes(fileType);
            if (isImage) {
                const file = files[0];
                dispatcher(updateUser(file, databaseUser));
            } else {
                toast.error('Wrong Image File');
            }
        }
    };

    return (
        <Fragment>
            <input
                style={{ display: 'none' }}
                ref={inputFile}
                onChange={handleFileUpload}
                type="file"
            ></input>
            <div className={styles.globalStylingForAccount}>
                <div className={styles.mainHeader}>
                    <FiChevronLeft
                        className={styles.cursorPointer}
                        size={24}
                        onClick={() => history.push(routeUrls.settings)}
                    />
                    <label className={styles.backTitle}>My Account</label>
                </div>
                {showPasswordContainer ? (
                    <div className={styles.changePasswordHeader}>
                        <FiX
                            size={24}
                            className={styles.cursorPointer}
                            onClick={() => setShowPasswordContainer(false)}
                        />

                        <label className={styles.backTitle}>Change Password</label>
                    </div>
                ) : null}
            </div>
            <div className={styles.globalStylingForAccount}>
                <div className={styles.mainBody}>
                    <div className={styles.mainBodyContainer}>
                        <div className={styles.accountBody}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img
                                    style={{
                                        borderRadius: '50%',
                                        border: '1px solid #d9d9d954',
                                        marginBottom: '10px',
                                        objectFit: 'cover',
                                    }}
                                    src={profileImage}
                                    width={100}
                                    height={100}
                                    alt="profile"
                                />
                            </div>
                            <label
                                className={styles.changeProfileButton}
                                onClick={() => onButtonClick()}
                            >
                                Change Profile Picture
                            </label>
                        </div>
                        <div className={styles.widthAdjustement}>
                            <TextInput
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Username"
                            />
                            <Spacer size={5} />
                            <TextInput
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                            />

                            <Spacer size={5} />
                            <TextInput
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                            <Spacer size={5} />
                            <TextInput
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your Email"
                                readOnly={true}
                            />
                            <Spacer size={10} />
                            <Button
                                fullWidth={true}
                                onClick={() =>
                                    dispatcher(
                                        updateProfile(
                                            id,
                                            firstName,
                                            lastName,
                                            username,
                                            profileImage
                                        )
                                    )
                                }
                            >
                                Save Changes
                            </Button>
                            <Spacer size={10} />
                            <div
                                onClick={() => setShowPasswordContainer(true)}
                                className={styles.changepasswordContainer}
                            >
                                <label className={styles.changeTitle}>Change Password</label>
                                <FiChevronRight size={24} className={styles.changeTitle} />
                            </div>
                        </div>
                    </div>
                </div>
                {showPasswordContainer ? (
                    <div className={styles.passwordContainer}>
                        <TextInput
                            value={currentPassword}
                            type="password"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder="Current Password"
                        />
                        <Spacer size={5} />
                        <TextInput
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="New Password"
                        />
                        <Spacer size={5} />
                        <Button
                            onClick={() => dispatcher(changePassword(currentPassword, newPassword))}
                        >
                            Change Password
                        </Button>
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
};
