import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import commonStyles from './../Settings.module.scss';
import styles from './GetFree.module.scss';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useAuthSelector } from '../../auth/authSlice';
import { getNamesCharacters } from '../../../utils/helpers';
import { ReferralCodeModal } from '../../../components/referralModal/ReferralModal';
import { routeUrls } from '../../../utils/constants';
import { settingActions, useSettingsSelector } from '../settingsSlice';

export const GetFree = () => {
    const history = useHistory();
    const dispatcher = useDispatch();

    const referralCode = useAuthSelector((state) => state.databaseUser?.referralCode);
    const referrers: any[] = useSettingsSelector((state) => state.referrers);

    return (
        <>
            <ReferralCodeModal />
            <div className={commonStyles.globalStylingForAccount}>
                <div className={commonStyles.mainHeader}>
                    <FiChevronLeft
                        className={commonStyles.cursorPointer}
                        size={24}
                        onClick={() => history.push(routeUrls.settings)}
                    />
                    <label className={commonStyles.backTitle}>Refer a Friend</label>
                </div>
            </div>
            <div className={commonStyles.globalStylingForAccount}>
                <div className={styles.mainBody}>
                    <div className={styles.greenContainer}>
                        <label className={styles.containerHeading}>
                            Refer 6 friends to Teeq and get Teeq <i>for free</i>
                        </label>
                        <br />
                        <div
                            className={styles.subHeading}
                            onClick={() => history.push(routeUrls.getfreedetails)}
                        >
                            <a href="https://teeqapp.com/refer-a-friend" target="_blank">
                                <label>Learn details</label>
                            </a>
                            <FiChevronRight className={styles.padder} size={20}></FiChevronRight>
                        </div>
                        <div className={styles.myPadder}>
                            <div className={styles.referralContainer}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Your Referral Code</label>
                                    <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                                        {referralCode}
                                    </label>
                                </div>
                                <label
                                    className={styles.buttonStyle}
                                    onClick={() =>
                                        dispatcher(settingActions.setReferralModal(true))
                                    }
                                >
                                    Share
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.myPadder2}>
                        <div className={styles.myPadder}>
                            <label className={styles.header}>Your Referrals</label>
                            <label
                                className={styles.buttonStyle}
                                style={{ marginLeft: '20px' }}
                                onClick={() => dispatcher(settingActions.setReferralModal(true))}
                            >
                                Invite More Friends
                            </label>
                        </div>
                        <div className={styles.myPadder}>
                            {referrers.map((item, index) => {
                                return (
                                    <div key={index} className={styles.listViewItemContainer}>
                                        <div className={styles.roundName}>
                                            {getNamesCharacters(
                                                item.firstName + ' ' + item.lastName
                                            )}
                                        </div>
                                        <label style={{ paddingLeft: '10px' }}>
                                            {item.firstName + ' ' + item.lastName}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>{' '}
            </div>
        </>
    );
};
