import React from 'react';
import { clickableTextColors, voidFn } from '../../utils/types';

import styles from './ClickableText.module.scss';

interface ClickableTextProps {
    className?: string;
    color?: clickableTextColors;
    onClick: voidFn;
}

export const ClickableText: React.FunctionComponent<ClickableTextProps> = (props) => {
    const { children, className = '', color = 'blue', onClick, ...passthroughProps } = props;

    let classList = `${styles.clickableText} ${styles[color]} ${className}`;

    return (
        <div className={classList} onClick={onClick}>
            {children}
        </div>
    );
};
