import firebase from 'firebase';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { signupActions } from './signupSlice';
import { authActions } from '../authSlice';
import { axios } from '../../../services/axiosService';
import { User } from '../../../types/User';
import { AppState } from '../../../store/appState';

export const checkIfUsernameAvailable = (username: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(signupActions.setLoading(true));
        const isAvailable = (await axios.get(`/Users/UsernameAvailable/${username}`)).data;
        if (isAvailable) {
            dispatch(signupActions.setUsername(username));
        } else {
            toast.error('Username unavailable');
        }
    } catch (err) {
        console.error(err);
        if (err.response && err.response.data === 'profane')
            toast.error('Username was rejected, please try another one');
        else toast.error('Sorry, something went wrong. Please try again');
    } finally {
        dispatch(signupActions.setLoading(false));
    }
};

export const addReferralCode =
    (referralCode: string, callback: () => void) => async (dispatch: Dispatch) => {
        try {
            dispatch(signupActions.setLoading(true));
            const result = (await axios.get(`/Users?referralcode=${referralCode}`)).data;
            if (result.length) {
                dispatch(signupActions.setReferralCode(referralCode));
                callback();
            } else {
                toast.error('Invalid Referral Code');
            }
        } catch (err) {
            console.error(err);
            toast.error('Sorry, something went wrong. Please try again');
        } finally {
            dispatch(signupActions.setLoading(false));
        }
    };

export const signupWithGoogle = () => async (dispatch: Dispatch) => {
    try {
        dispatch(signupActions.setLoading(true));
        const provider = new firebase.auth.GoogleAuthProvider();
        await firebase.auth().signInWithPopup(provider);
    } catch (err) {
        console.error(err);
        toast.error(`Could not create account: ${err.message}`);
    } finally {
        dispatch(signupActions.setLoading(false));
    }
};

export const signupWithFacebook = () => async (dispatch: Dispatch) => {
    try {
        dispatch(signupActions.setLoading(true));
        const provider = new firebase.auth.FacebookAuthProvider();
        await firebase.auth().signInWithPopup(provider);
    } catch (err) {
        console.error(err);
        toast.error(`Could not create account: ${err.message}`);
    } finally {
        dispatch(signupActions.setLoading(false));
    }
};

export const signupWithApple = () => async (dispatch: Dispatch) => {
    try {
        dispatch(signupActions.setLoading(true));
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        await firebase.auth().signInWithPopup(provider);
    } catch (err) {
        console.error(err);
        toast.error(`Could not create account: ${err.message}`);
    } finally {
        dispatch(signupActions.setLoading(false));
    }
};

export const signupWithEmailAndPassword =
    (email: string, password: string) => async (dispatch: Dispatch) => {
        try {
            dispatch(signupActions.setLoading(true));
            const userCredential = await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password);
        } catch (err) {
            console.error(err);
            toast.error(`Could not create account: ${err.message}`);
        } finally {
            dispatch(signupActions.setLoading(false));
        }
    };

export const finishSignup =
    (firstName: string, lastName: string) =>
    async (dispatch: Dispatch, getState: () => AppState) => {
        try {
            const state = getState();
            const { username, referralCode } = state.signup;
            const firebaseId = firebase.auth().currentUser!.uid;
            const email = firebase.auth().currentUser!.email;

            const payload = {
                firebaseId,
                firstName,
                lastName,
                username,
                email,
                signupReferrer: referralCode,
            };

            const response = await axios.post<User>('/Users', payload);
            const createUserResponse = response.data;
            dispatch(authActions.setDatabaseUser(createUserResponse));
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data === 'profane')
                toast.error('Username was rejected, please try another one');
            else toast.error('Sorry, something went wrong. Please try again');
        } finally {
        }
    };
