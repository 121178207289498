import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { FiPlay, FiPause, FiTrash } from 'react-icons/fi';
import { connect, DispatchProp } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Prompt } from 'react-router-dom';

import styles from './VideoAnnotate.module.scss';
import { videoAnnotateActions, VideoAnnotateAppState } from './videoAnnotateSlice';
import { DrawingCanvas } from '../../components/drawingCanvas/DrawingCanvas';
import { Button } from '../../components/button/Button';
import { ReactComponent as MicrophoneIcon } from '../../assets/icon-microphone.svg';
import { ReactComponent as VideoIcon } from '../../assets/icon-video.svg';
import { ReactComponent as PhotoIcon } from '../../assets/icon-photo.svg';
import { ReactComponent as DrawIcon } from '../../assets/icon-draw.svg';
import { ReactComponent as SizeIcon } from '../../assets/icon-size.svg';
import { ReactComponent as SizeIconFull } from '../../assets/icon-size-full.svg';
import { LabeledIcon } from '../../components/labeledIcon/LabeledIcon';
import { CanvasElement, paintColors } from '../../utils/types';
import { uploadFeedbackVideo, sendFeedbackVideo } from '../../utils/videos';
import { routeUrls } from '../../utils/constants';
import { AppState } from '../../store/appState';
import { CircleSelector } from '../../components/circleSelector/CircleSelector';
import { Spinner } from '../../components/spinner/Spinner';
import { RoundIconButton } from '../../components/roundIconButton/RoundIconButton';
import { Spacer } from '../../components/spacer/Spacer';
import { DeleteModal } from './DeleteModal';
import { SendVideoModal } from '../../components/sendVideoModal/SendVideoModal';
import { Loader } from 'semantic-ui-react';

const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds - minutes * 60);
    let strSeconds = seconds.toString();
    strSeconds = strSeconds.length === 1 ? '0' + strSeconds : strSeconds;
    return `${minutes}:${strSeconds}`;
};

const {
    setIsUsingWebcam,
    setCurrentVideoTime,
    setIsDeleteModalOpen,
    setIsDownloadSendModalOpen,
    setIsRecording,
    setIsRecordingInitialized,
    setIsReviewing,
    setIsSourceVideoPlaying,
    setIsDrawToolEnabled,
    setIsMicrophoneEnabled,
    setIsShowingPIPImage,
    setPaintColor,
    setIsLoadingSourceVideo,
    resetState,
} = videoAnnotateActions;

const SECOND_IN_MS = 1000;
const FRAMES_PER_SECOND = 30;
const FRAMERATE_IN_MS = SECOND_IN_MS / FRAMES_PER_SECOND;

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

// https://github.com/microsoft/TypeScript/issues/32912 ¯\_(ツ)_/¯
const wheelEventListenerOptions = { passive: false } as AddEventListenerOptions &
    EventListenerOptions;

// Make sure the video is 'activated' on iOS
function once(el: any, event: any, fn: any, opts?: any) {
    const onceFn = function (this: any, e: any) {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
    };
    el.addEventListener(event, onceFn, opts);
    return onceFn;
}

export function drawImageScaled(
    image: HTMLVideoElement | HTMLImageElement,
    imageWidth: number,
    imageHeight: number,
    ctx: CanvasRenderingContext2D,
    canvasWidth: number,
    canvasHeight: number,
    inCorner = false
) {
    const hRatio = canvasWidth / imageWidth;
    const vRatio = canvasHeight / imageHeight;
    const ratio = Math.min(hRatio, vRatio);
    if (inCorner) {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.drawImage(
            image,
            0,
            0,
            imageWidth,
            imageHeight,
            0,
            0,
            imageWidth * ratio * 2,
            imageHeight * ratio * 2
        );
    } else {
        const centerShiftX = (canvasWidth - imageWidth * ratio) / 2;
        const centerShiftY = (canvasHeight - imageHeight * ratio) / 2;
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.drawImage(
            image,
            0,
            0,
            imageWidth,
            imageHeight,
            centerShiftX,
            centerShiftY,
            imageWidth * ratio * 2,
            imageHeight * ratio * 2
        );
    }
}

interface VideoAnnotatorLocalState {
    chunks: Blob[];
    conversationId: string | undefined;
    fileType: 'video' | 'image';
    onSuccessRoute: string | undefined;
    pipFullscreen: boolean;
    showPromptWhenLeaving: boolean;
    videoUriForSending: string;
    vipFullscreen: boolean;
    webcamStream: MediaStream | undefined;
    isSendingToContact: boolean;
}

class VideoAnnotator extends React.Component<
    VideoAnnotateAppState & DispatchProp,
    VideoAnnotatorLocalState
> {
    sourceVideo: React.RefObject<HTMLVideoElement>;
    sourceImage: React.RefObject<HTMLImageElement>;
    webcamVideo: React.RefObject<HTMLVideoElement>;
    playbackVideo: React.RefObject<HTMLVideoElement>;
    uploadVideo: React.RefObject<HTMLVideoElement>;

    timelineContainer: React.RefObject<HTMLDivElement>;
    canvasContainer: React.RefObject<HTMLDivElement>;
    playButtonContainer: React.RefObject<HTMLDivElement>;
    pageRef: React.RefObject<HTMLDivElement>;

    sourceVideoCanvas: React.RefObject<CanvasElement>;
    sourceImageCanvas: React.RefObject<CanvasElement>;
    drawingCanvas: React.RefObject<CanvasElement>;
    recorderCanvas: React.RefObject<CanvasElement>; // canvas that everything gets painted onto
    imageCanvas: React.RefObject<CanvasElement>;
    webcamCanvas: React.RefObject<CanvasElement>;
    timelineCanvas: React.RefObject<CanvasElement>;
    uploadCanvas: React.RefObject<CanvasElement>;

    imageFileInput: React.RefObject<HTMLInputElement>;

    sourceVideoCanvasContext?: CanvasRenderingContext2D; // So that we don't have to look it up every time we draw a frame
    sourceVideoCanvasRect?: DOMRect;
    recorderCanvasContext?: CanvasRenderingContext2D; // So that we don't have to look it up every time we draw a frame

    sourceVideoStream?: MediaStream;
    drawingStream?: MediaStream;
    recorderStream?: MediaStream;
    mediaRecorder?: MediaRecorder; // recorder which combines canvas stream and browser audio

    timeline?: gsap.core.Timeline;
    scrollTrigger?: gsap.core.Tween;
    autoScroller?: gsap.core.Tween;

    drawSourceVideoInterval?: NodeJS.Timeout;
    drawWebcamInterval?: NodeJS.Timeout;
    recordingInterval?: NodeJS.Timeout;
    updateCurrentVideoTimeInterval?: NodeJS.Timeout;
    timelinePosition = { left: 0, x: 0 };

    activeImage?: HTMLImageElement;
    mediaStream: MediaStream;
    micInput?: MediaStreamAudioSourceNode;

    constructor(props: any) {
        super(props);
        this.sourceVideo = React.createRef();
        this.sourceImage = React.createRef();
        this.webcamVideo = React.createRef();
        this.canvasContainer = React.createRef();
        this.playButtonContainer = React.createRef();
        this.pageRef = React.createRef();
        this.playbackVideo = React.createRef();
        this.sourceVideoCanvas = React.createRef();
        this.sourceImageCanvas = React.createRef();
        this.drawingCanvas = React.createRef();
        this.recorderCanvas = React.createRef();
        this.imageCanvas = React.createRef();
        this.webcamCanvas = React.createRef();
        this.timelineContainer = React.createRef();
        this.timelineCanvas = React.createRef();
        this.imageFileInput = React.createRef();
        this.uploadVideo = React.createRef();
        this.uploadCanvas = React.createRef();
        this.mediaStream = new MediaStream();

        this.state = {
            chunks: [],
            conversationId: props.location.state.conversationId,
            fileType:
                props.location &&
                props.location.state &&
                props.location.state.fileType &&
                props.location.state.fileType.indexOf('video') > -1
                    ? 'video'
                    : 'image',
            onSuccessRoute: props.location.state.onSuccessRoute,
            pipFullscreen: props.isPhotoFullSize ?? false,
            showPromptWhenLeaving: true,
            vipFullscreen: props.isVideoFullSize ?? false,
            videoUriForSending: '',
            webcamStream: undefined,
            isSendingToContact: false,
        };

        this.startRecording = this.startRecording.bind(this);
        this.pauseRecording = this.pauseRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.reviewRecording = this.reviewRecording.bind(this);
        this.deleteRecording = this.deleteRecording.bind(this);
        this.clearDrawings = this.clearDrawings.bind(this);
        this.handleDeleteRecordingClick = this.handleDeleteRecordingClick.bind(this);
        this.handleReviewAndSendClick = this.handleReviewAndSendClick.bind(this);
        this.handleImageFileChange = this.handleImageFileChange.bind(this);
        this.clearImageFile = this.clearImageFile.bind(this);
        this.toggleWebcam = this.toggleWebcam.bind(this);
        this.drawWebcamVideo = this.drawWebcamVideo.bind(this);
        this.drawSourceVideoFrame = this.drawSourceVideoFrame.bind(this);
        this.recordingLoop = this.recordingLoop.bind(this);
        this.clearWebcamVideo = this.clearWebcamVideo.bind(this);
        this.startAutoplay = this.startAutoplay.bind(this);
        this.stopAutoplay = this.stopAutoplay.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.toggleMicrophoneActive = this.toggleMicrophoneActive.bind(this);
        this.toggleDrawToolActive = this.toggleDrawToolActive.bind(this);
        this.togglePhotoSize = this.togglePhotoSize.bind(this);
        this.toggleVideoSize = this.toggleVideoSize.bind(this);
        this.timelineMouseMove = this.timelineMouseMove.bind(this);
        this.timelineMouseDown = this.timelineMouseDown.bind(this);
        this.timelineMouseUp = this.timelineMouseUp.bind(this);
        this.transferVerticalScrollToTimeline = this.transferVerticalScrollToTimeline.bind(this);
        this.togglePlayPause = this.togglePlayPause.bind(this);
        this.onSourceVideoLoaded = this.onSourceVideoLoaded.bind(this);
        this.onSourceImageLoaded = this.onSourceImageLoaded.bind(this);
        this.updateCurrentVideoTime = this.updateCurrentVideoTime.bind(this);
        this.mountScrollListener = this.mountScrollListener.bind(this);
        this.unmountScrollListener = this.unmountScrollListener.bind(this);
        this.onSentSuccess = this.onSentSuccess.bind(this);
        this.handleSendFeedbackVideo = this.handleSendFeedbackVideo.bind(this);
    }

    onSentSuccess() {
        this.setState({ showPromptWhenLeaving: false });
    }

    updateSizes(width: number, height: number, elements: React.RefObject<any>[]) {
        elements.forEach((element) => {
            const currentElement = element.current;
            currentElement.width = width;
            currentElement.height = height;
        });
    }

    updateSizesDoubleRes(width: number, height: number, elements: React.RefObject<any>[]) {
        elements.forEach((element) => {
            const currentElement = element.current;
            if (currentElement.nodeName.toLowerCase() === "canvas") {
                currentElement.width = width;
                currentElement.height = height;
            }
            currentElement.style.width = width / 2 + "px";
            currentElement.style.height = height / 2 + "px";
        });
    }

    drawSourceVideoFrame() {
        const sourceVideo = this.sourceVideo.current!;
        drawImageScaled(
            sourceVideo,
            sourceVideo.videoWidth,
            sourceVideo.videoHeight,
            this.sourceVideoCanvasContext!,
            this.sourceVideoCanvasRect!.width,
            this.sourceVideoCanvasRect!.height,
            true
        );
    }

    recordingLoop() {
        const recCtx = this.recorderCanvasContext!;

        if (this.state.fileType === 'video') {
            recCtx.drawImage(this.sourceVideoCanvas.current!, 0, 0);
        } else {
            recCtx.drawImage(this.sourceImageCanvas.current!, 0, 0);
        }
        recCtx.drawImage(this.imageCanvas.current!, 0, 0);
        recCtx.drawImage(this.webcamCanvas.current!, 0, 0);

        const drawCanvasWidth = this.drawingCanvas.current!.width;
        const drawCanvasHeight = this.drawingCanvas.current!.height;
        recCtx.drawImage(this.drawingCanvas.current!, 0, 0, drawCanvasWidth, drawCanvasHeight, 0, 0, drawCanvasWidth * 2, drawCanvasHeight * 2);
    }

    async startRecording() {
        // Only run this once and not every time the user plays the recording after pausing
        if (!this.mediaRecorder) {
            await this.initializeStreams();
        }
        if (this.mediaRecorder!.state === 'paused') {
            this.mediaRecorder!.resume();
        } else {
            this.mediaRecorder!.start();
            this.props.dispatch(setIsRecordingInitialized(true));
        }

        this.props.dispatch(setIsRecording(true));
        this.recordingInterval = setInterval(this.recordingLoop, FRAMERATE_IN_MS);
    }

    pauseRecording() {
        this.mediaRecorder!.pause();
        if (this.state.fileType === 'video') {
            this.stopAutoplay();
        }
        clearInterval(this.recordingInterval!);
        this.recordingInterval = undefined;
        this.props.dispatch(setIsRecording(false));
    }

    stopRecording() {
        this.recorderStream!.getAudioTracks().forEach((track) => track.stop());
        // Stopping audio
        this.micInput?.mediaStream.getAudioTracks().forEach((track) => track.stop());
        // Stopping video if it existss
        this.state.webcamStream?.getTracks().forEach((track) => track.stop());

        this.drawingStream?.getTracks().forEach((track) => track.stop());
        clearInterval(this.recordingInterval!);
        this.recordingInterval = undefined;
        this.props.dispatch(setIsRecording(false));
        this.props.dispatch(setIsRecordingInitialized(false));
    }

    handleDeleteRecordingClick() {
        if (this.state.fileType === 'video') {
            this.stopAutoplay();
        }
        this.props.dispatch(setIsDeleteModalOpen(true));
    }

    async deleteRecording() {
        this.mediaRecorder = undefined; // Just destroy rather than calling stop() since we don't want the ondataavailable event to fire
        this.stopRecording();
        await this.initializeStreams();
        this.timelineContainer.current?.scrollTo(0, 0); // Seeking the timeline wasn't working here
        this.clearDrawings();
        this.props.dispatch(setIsReviewing(false));
        if (this.playbackVideo.current) {
            this.playbackVideo.current.pause();
        }
        if (this.playbackVideo.current!.src) {
            URL.revokeObjectURL(this.playbackVideo.current!.src);
        }
    }

    handleReviewAndSendClick() {
        this.mediaRecorder!.stop();
        if (this.state.fileType === 'video') {
            this.stopAutoplay();
        }
        this.stopRecording();
        this.props.dispatch(setIsReviewing(true));
    }

    reviewRecording() {
        console.log('review');
    }

    togglePhotoSize() {
        const img = this.activeImage!;
        const recorderCanvasRect = this.recorderCanvas.current!.getBoundingClientRect();

        // In both cases we double the canvas size to increase quality
        if (!this.state.pipFullscreen) {
            this.imageCanvas.current!.width = recorderCanvasRect.width * 2;
            this.imageCanvas.current!.height = recorderCanvasRect.height * 2;
        } else {
            // Scale it so that it is at most 1/3 the width or 1/2 the height, whichever is greater
            const maxWidthScale = recorderCanvasRect.width / 3 / img.width;
            const maxHeightScale = recorderCanvasRect.height / 2 / img.height;
            const scale = Math.min(maxWidthScale, maxHeightScale) * 2;
            this.imageCanvas.current!.width = img.width * scale;
            this.imageCanvas.current!.height = img.height * scale;
        }
        // Return canvas to desired dimensions
        this.imageCanvas.current!.style.width = this.imageCanvas.current!.width / 2 + "px";
        this.imageCanvas.current!.style.height = this.imageCanvas.current!.height / 2 + "px";

        const imageCanvasRect = this.imageCanvas.current!.getBoundingClientRect();
        const ctx = this.imageCanvas.current!.getContext('2d')!;

        drawImageScaled(
            img,
            img.width,
            img.height,
            ctx,
            imageCanvasRect.width,
            imageCanvasRect.height,
            !this.state.pipFullscreen
        );

        this.setState({ pipFullscreen: !this.state.pipFullscreen });
        URL.revokeObjectURL(img.src);
    }

    toggleVideoSize() {
        const videoWidth = this.webcamVideo.current!.videoWidth;
        const videoHeight = this.webcamVideo.current!.videoHeight;
        const recorderCanvasRect = this.recorderCanvas.current!.getBoundingClientRect();

        // In both cases we double the canvas size to increase quality
        if (!this.state.vipFullscreen) {
            this.webcamCanvas.current!.width = recorderCanvasRect.width * 2;
            this.webcamCanvas.current!.height = recorderCanvasRect.height * 2;
        } else {
            // Scale it so that it is at most 1/3 the width or 1/2 the height, whichever is greater
            const maxWidthScale = recorderCanvasRect.width / 3 / videoWidth;
            const maxHeightScale = recorderCanvasRect.height / 2 / videoHeight;
            const scale = Math.min(maxWidthScale, maxHeightScale) * 2;
            this.webcamCanvas.current!.width = videoWidth * scale;
            this.webcamCanvas.current!.height = videoHeight * scale;
        }
        // Return canvas to desired dimensions
        this.webcamCanvas.current!.style.width = this.webcamCanvas.current!.width / 2 + "px";
        this.webcamCanvas.current!.style.height = this.webcamCanvas.current!.height / 2 + "px";

        const context = this.webcamCanvas.current!.getContext('2d');
        context?.translate(context.canvas.width, 0);
        context?.scale(-1, 1);

        this.setState({ vipFullscreen: !this.state.vipFullscreen });
    }

    async toggleMicrophoneActive() {
        const currentlyEnabled = this.props.isMicrophoneEnabled;
        if (this.recorderStream && this.micInput) {
            this.micInput.mediaStream.getAudioTracks()[0].enabled = !currentlyEnabled;
        }
        this.props.dispatch(setIsMicrophoneEnabled(!currentlyEnabled));
    }

    toggleDrawToolActive() {
        const { isDrawToolEnabled } = this.props;
        this.props.dispatch(setIsDrawToolEnabled(!isDrawToolEnabled));
        this.clearDrawings();
    }

    clearDrawings() {
        const canvasW = this.drawingCanvas.current!.getBoundingClientRect().width;
        const canvasH = this.drawingCanvas.current!.getBoundingClientRect().height;
        this.drawingCanvas.current!.getContext('2d')!.clearRect(0, 0, canvasW, canvasH);
    }

    drawWebcamVideo() {
        if (!this.props.usingWebcam) return;

        this.drawWebcamInterval = setInterval(() => {
            const webcamCanvasRect = this.webcamCanvas.current!.getBoundingClientRect();
            const webcamVideoWidth = this.webcamVideo.current!.videoWidth;
            const webcamVideoHeight = this.webcamVideo.current!.videoHeight;
            drawImageScaled(
                this.webcamVideo.current!,
                webcamVideoWidth,
                webcamVideoHeight,
                this.webcamCanvas.current!.getContext('2d')!,
                webcamCanvasRect.width,
                webcamCanvasRect.height,
                this.state.vipFullscreen
            );
        }, FRAMERATE_IN_MS);
    }

    clearWebcamVideo() {
        clearInterval(this.drawWebcamInterval!);
        this.drawWebcamInterval = undefined;
        let canvasW, canvasH;
        if (this.state.fileType === 'video') {
            canvasW = this.sourceVideoCanvas.current!.getBoundingClientRect().width;
            canvasH = this.sourceVideoCanvas.current!.getBoundingClientRect().height;
        } else {
            canvasW = this.sourceImageCanvas.current!.getBoundingClientRect().width;
            canvasH = this.sourceImageCanvas.current!.getBoundingClientRect().height;
        }
        this.webcamCanvas.current!.getContext('2d')!.clearRect(0, 0, canvasW, canvasH);
    }

    async toggleWebcam() {
        if (!this.props.usingWebcam) {
            const webcamStream = (this.webcamVideo.current!.srcObject ??
                (await navigator.mediaDevices.getUserMedia({ video: true }))) as MediaStream;
            this.setState({ webcamStream })
            this.props.dispatch(setIsUsingWebcam(true));

            if (this.props.isShowingPIPImage) {
                this.clearImage();
                this.props.dispatch(setIsShowingPIPImage(false));
            }

            this.webcamVideo.current!.srcObject = webcamStream;
            this.webcamVideo.current!.onloadedmetadata = (e) => {
                const videoWidth = this.webcamVideo.current!.videoWidth;
                const videoHeight = this.webcamVideo.current!.videoHeight;
                const recorderCanvasRect = this.recorderCanvas.current!.getBoundingClientRect();

                // In both cases we double the canvas size to increase quality
                if (this.state.vipFullscreen) {
                    this.webcamCanvas.current!.width = recorderCanvasRect.width * 2;
                    this.webcamCanvas.current!.height = recorderCanvasRect.height * 2;
                } else {
                    // Scale it so that it is at most 1/3 the width or 1/2 the height, whichever is greater
                    const maxWidthScale = recorderCanvasRect.width / 3 / videoWidth;
                    const maxHeightScale = recorderCanvasRect.height / 2 / videoHeight;
                    const scale = Math.min(maxWidthScale, maxHeightScale) * 2;
                    this.webcamCanvas.current!.width = videoWidth * scale;
                    this.webcamCanvas.current!.height = videoHeight * scale;
                }
                // This brings the webcam size back to the desired dimensions
                this.webcamCanvas.current!.style.width = this.webcamCanvas.current!.width / 2 + "px";
                this.webcamCanvas.current!.style.height = this.webcamCanvas.current!.height / 2 + "px";
                const context = this.webcamCanvas.current!.getContext('2d');
                context?.translate(context.canvas.width, 0);
                context?.scale(-1, 1);

                this.webcamVideo.current!.play();
            };
            this.webcamVideo.current!.addEventListener('play', () => this.drawWebcamVideo(), false);
        } else {
            this.state.webcamStream?.getTracks().forEach((track) => track.stop());

            this.webcamVideo.current!.pause();
            this.webcamVideo.current!.currentTime = 0;
            this.webcamVideo.current!.srcObject = null;
            this.clearWebcamVideo();
            this.props.dispatch(setIsUsingWebcam(false));
        }
    }

    clearImage() {
        this.props.dispatch(setIsShowingPIPImage(false));
        this.clearImageFile();
        return;
    }

    handleImageFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files?.length) {
            this.clearImage();
            this.clearImageFile();
            return;
        }

        if (this.props.usingWebcam) {
            this.toggleWebcam();
        }

        const ctx = this.imageCanvas.current!.getContext('2d')!;
        const img = new Image();
        img.onload = () => {
            const recorderCanvasRect = this.recorderCanvas.current!.getBoundingClientRect();

            // In both cases we double the canvas size to increase quality
            if (this.state.pipFullscreen) {
                this.imageCanvas.current!.width = recorderCanvasRect.width * 2;
                this.imageCanvas.current!.height = recorderCanvasRect.height * 2;
            } else {
                // Scale it so that it is at most 1/3 the width or 1/2 the height, whichever is greater
                const maxWidthScale = recorderCanvasRect.width / 3 / img.width;
                const maxHeightScale = recorderCanvasRect.height / 2 / img.height;
                const scale = Math.min(maxWidthScale, maxHeightScale);
                this.imageCanvas.current!.width = img.width * scale * 2;
                this.imageCanvas.current!.height = img.height * scale * 2;
            }
            // This brings the image size back to the desired dimensions
            this.imageCanvas.current!.style.width = this.imageCanvas.current!.width / 2 + "px";
            this.imageCanvas.current!.style.height = this.imageCanvas.current!.height / 2 + "px";
            const imageCanvasRect = this.imageCanvas.current!.getBoundingClientRect();

            drawImageScaled(
                img,
                img.width,
                img.height,
                ctx,
                imageCanvasRect.width,
                imageCanvasRect.height,
                this.state.pipFullscreen
            );
            URL.revokeObjectURL(img.src);
        };
        img.src = URL.createObjectURL(e.target.files[0]);
        this.activeImage = img;
        this.props.dispatch(setIsShowingPIPImage(true));
    }

    clearImageFile() {
        this.activeImage = undefined;
        const imageCanvasRect = this.imageCanvas.current!.getBoundingClientRect();
        this.imageCanvas
            .current!.getContext('2d')!
            .clearRect(0, 0, imageCanvasRect.width * 2, imageCanvasRect.height * 2);
    }

    setScrollTrigger() {
        this.timeline = gsap.timeline({
            defaults: {
                ease: 'none', // Removing this property will cause scrollbar position to be inconsistent between autoplay and scrubbing
            },
            scrollTrigger: {
                scroller: '#timelineContainer',
                start: 'left center',
                end: 'right center',
                trigger: '#startTrigger',
                endTrigger: '#endTrigger',
                horizontal: true,
                scrub: true,
            },
        });
        // this uses gsap scroll trigger to build a scroller the size of the video.
        // each time a scroll event happens, it'll re-draw the frame onto the canvas
        this.timeline!.fromTo(
            this.sourceVideo.current,
            {
                currentTime: 0,
            },
            {
                id: 'scroller',
                currentTime: this.sourceVideo.current?.duration ?? 1,
                immediateRender: true,
                duration: this.sourceVideo.current?.duration ?? 1,
            }
        );
        this.scrollTrigger = this.timeline!.getById('scroller') as gsap.core.Tween;

        // If you load the page, play the video, pause it, and play it again, it restarts the video
        // If you scroll first, you can play/pause all day and it won't restart
        this.scrollTrigger.seek(0.000001);
    }

    stopAutoplay() {
        this.sourceVideo.current!.pause();
        if (this.autoScroller) {
            this.autoScroller.kill();
        }
        this.scrollTrigger!.play(this.sourceVideo.current!.currentTime);
        this.props.dispatch(setIsSourceVideoPlaying(false));
    }

    async startAutoplay() {
        this.scrollTrigger!.pause();
        const totalVideoDuration = this.sourceVideo.current!.duration;
        const currentVideoTime = this.sourceVideo.current!.currentTime;
        await this.sourceVideo.current!.play();
        this.autoScroller = gsap.to(this.timelineContainer.current!, {
            duration: totalVideoDuration - currentVideoTime,
            scrollTo: {
                x: 'max',
                autoKill: true, // If the user scrolls, take back control from the autoScroller
                onAutoKill: this.stopAutoplay,
            },
            ease: 'none',
        });
        this.props.dispatch(setIsSourceVideoPlaying(true));
    }

    handleKeydown(e: KeyboardEvent) {
        if (e.code !== 'Space') return;
        e.preventDefault();
        this.togglePlayPause();
    }

    togglePlayPause() {
        if (this.props.isSourceVideoPlaying) {
            this.stopAutoplay();
        } else {
            if (this.sourceVideo.current!.ended) {
                return toast.error('Cannot play, video ended');
            }
            this.startAutoplay();
        }
    }

    async generateTimeline(video: HTMLVideoElement, videoWidth: number, videoHeight: number) {
        return new Promise((resolve, reject) => {
            const generate = () => {
                const aspectRatio = videoWidth / videoHeight;
                const frameHeight = 48;
                const frameWidth = frameHeight * aspectRatio;
                const totalFrames = 30;
                this.timelineCanvas.current!.height = frameHeight;
                this.timelineCanvas.current!.width = frameWidth * totalFrames;
                const timelineCanvasContext = this.timelineCanvas.current!.getContext('2d');
                let i = 0;

                const drawTimelineFrame = (e?: Event) => {
                    timelineCanvasContext!.drawImage(
                        video,
                        0,
                        0,
                        videoWidth,
                        videoHeight,
                        i * frameWidth,
                        0,
                        frameWidth,
                        frameHeight
                    );
                    i++;

                    if (video.duration === Infinity) {
                        video.currentTime = Number.MAX_SAFE_INTEGER;
                        video.currentTime = 0;
                    } else if (i < totalFrames) {
                        video.currentTime = i * (video.duration / totalFrames);
                    } else {
                        video.removeEventListener('seeked', drawTimelineFrame);
                        video.currentTime = 0.0001;
                        this.timelineContainer.current!.style.visibility = 'visible';
                        resolve(true);
                    }
                };

                video.addEventListener('seeked', drawTimelineFrame);
            };
            if (video.duration === Infinity) {
                // Workaround for chrome bug. blob videos (which is what you get from the Record feature) do not have their duration set properly
                // https://stackoverflow.com/questions/50586612/seeking-is-not-working-in-recorded-video-with-mediarecorder-api
                // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
                video.ontimeupdate = function () {
                    this.ontimeupdate = () => null;
                    video.currentTime = 0;
                    generate();
                };
                video.currentTime = 1e101;
            } else {
                generate();
            }
        });
    }

    mountScrollListener() {
        document.addEventListener(
            'wheel',
            this.transferVerticalScrollToTimeline,
            wheelEventListenerOptions
        );
    }

    unmountScrollListener() {
        document.removeEventListener(
            'wheel',
            this.transferVerticalScrollToTimeline,
            wheelEventListenerOptions
        );
    }

    async onSourceVideoLoaded() {
        const videoWidth = this.sourceVideo.current!.videoWidth;
        const videoHeight = this.sourceVideo.current!.videoHeight;
        const pageWidth = this.pageRef.current!.clientWidth;
        const pageHeight = this.pageRef.current!.clientHeight;

        const maxWidthScale = (pageWidth * 0.8) / videoWidth;
        const maxHeightScale = (pageHeight * 0.8) / videoHeight;
        // Doubling all canvas sizes to increase resolution
        const scale = Math.min(maxWidthScale, maxHeightScale);
        const useWidth = videoWidth * scale;
        const useHeight = videoHeight * scale;
        this.updateSizes(useWidth, useHeight, [
            this.drawingCanvas,
            this.playbackVideo,
        ]);
        // To increase resolution of an image on a canvas:
        // Double the canvas size and use css to bring the canvas dimensions back to the desired size
        this.updateSizesDoubleRes(useWidth * 2, useHeight * 2, [
            this.sourceVideoCanvas,
            this.canvasContainer,
            this.recorderCanvas,
        ]);
        this.sourceVideoCanvasContext = this.sourceVideoCanvas.current!.getContext('2d')!;
        this.sourceVideoCanvasRect = this.sourceVideoCanvas.current?.getBoundingClientRect();
        this.recorderCanvasContext = this.recorderCanvas.current!.getContext('2d')!;

        try {
            await this.generateTimeline(this.sourceVideo.current!, videoWidth, videoHeight);
            // The 'ended' event listener has to be attached after generating the timeline, since we may be
            // seeking the video to it's end in order to work around a chrome bug
            this.sourceVideo.current!.addEventListener('ended', this.stopAutoplay);
        } catch (err) {
            this.props.dispatch(setIsLoadingSourceVideo(false));
            //@ts-ignore
            toast.error(err.message);
            return;
        }

        window.addEventListener('keydown', this.handleKeydown);
        this.timelineContainer.current!.onscroll = (e) => e.preventDefault();
        this.timelineContainer.current!.addEventListener('mousedown', this.timelineMouseDown);
        this.timelineContainer.current!.addEventListener('mouseup', this.timelineMouseUp);
        this.mountScrollListener();
        this.setScrollTrigger();

        this.props.dispatch(setIsLoadingSourceVideo(false));
        this.drawSourceVideoInterval = setInterval(this.drawSourceVideoFrame, FRAMERATE_IN_MS);
        this.updateCurrentVideoTimeInterval = setInterval(this.updateCurrentVideoTime, 100);
    }

    async onSourceImageLoaded() {
        const videoWidth = this.sourceImage.current!.width;
        const videoHeight = this.sourceImage.current!.height;
        const pageWidth = this.pageRef.current!.clientWidth;
        const pageHeight = this.pageRef.current!.clientHeight;

        const maxWidthScale = (pageWidth * 0.8) / videoWidth;
        const maxHeightScale = (pageHeight * 0.8) / videoHeight;
        // Double the canvas size to increase quality
        const scale = Math.min(maxWidthScale, maxHeightScale);
        const useWidth = videoWidth * scale;
        const useHeight = videoHeight * scale;

        this.updateSizes(useWidth, useHeight, [
            this.drawingCanvas,
            this.playbackVideo,
        ]);
        // To increase resolution of an image on a canvas:
        // Double the canvas size and use css to bring the canvas dimensions back to the desired size
        this.updateSizesDoubleRes(useWidth * 2, useHeight * 2, [
            this.sourceImageCanvas,
            this.canvasContainer,
            this.recorderCanvas,
        ]);
        this.sourceVideoCanvasContext = this.sourceImageCanvas.current!.getContext('2d')!;
        this.sourceVideoCanvasRect = this.sourceImageCanvas.current?.getBoundingClientRect();
        this.recorderCanvasContext = this.recorderCanvas.current!.getContext('2d')!;

        this.props.dispatch(setIsLoadingSourceVideo(false));
        const sourceImage = this.sourceImage.current!;
        drawImageScaled(
            sourceImage,
            sourceImage.width,
            sourceImage.height,
            this.sourceVideoCanvasContext!,
            this.sourceVideoCanvasRect!.width,
            this.sourceVideoCanvasRect!.height,
            true
        );
    }

    updateCurrentVideoTime() {
        if (!this.sourceVideo || !this.sourceVideo.current) return;
        const videoTime = this.sourceVideo.current.currentTime ?? 0;
        this.props.dispatch(setCurrentVideoTime(videoTime));
    }

    timelineMouseDown(e: MouseEvent) {
        this.timelineContainer.current!.addEventListener('mousemove', this.timelineMouseMove);
        this.timelinePosition.left = this.timelineContainer.current!.scrollLeft;
        this.timelinePosition.x = e.clientX;
        this.timelineContainer.current!.style.cursor = 'grabbing';
    }

    timelineMouseMove(e: MouseEvent) {
        if (!e.buttons) return;
        const moveDistance = e.clientX - this.timelinePosition.x;
        this.timelineContainer.current!.scrollLeft = this.timelinePosition.left - moveDistance;
    }

    timelineMouseUp(e: MouseEvent) {
        e.stopPropagation();
        this.timelineContainer.current!.removeEventListener('mousemove', this.timelineMouseMove);
        this.timelineContainer.current!.style.cursor = 'grab';
    }

    transferVerticalScrollToTimeline(e: any) {
        e.preventDefault();
        if (this.timelineContainer && this.timelineContainer.current) {
            this.timelineContainer.current!.scrollLeft += e.deltaY;
        }
    }

    async initializeStreams() {
        // these are needed due to browser bugs
        this.sourceVideoCanvas.current?.getContext('2d');
        this.drawingCanvas.current?.getContext('2d');
        this.recorderCanvas.current?.getContext('2d');

        // setup the streams from each of the canvases to then stream onto the recorder canvas
        if (this.state.fileType === 'video') {
            this.sourceVideoStream = this.sourceVideoCanvas.current!.captureStream(30);
        } else {
            //todo - setup image
        }
        this.drawingStream = this.drawingCanvas.current!.captureStream(30);

        // setup the stream from the recorder canvas to be combined with the computer audio so it can be made into a video
        this.recorderStream = this.recorderCanvas.current!.captureStream(30);

        // Get the audio stream *now*, before we need it. If you call `getUserMedia` too shortly before calling `mediaRecorder.start`, it causes a video/audio sync latency on mac
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioStreamTrack = audioStream.getTracks()[0];
        audioStreamTrack.enabled = this.props.isMicrophoneEnabled;

        // add track from the video
        const videoStream =
            // @ts-ignore
            this.state.fileType === 'video' ? this.sourceVideo.current!.captureStream() : null;

        // merge audio from remote stream and micStream
        const audioCtx = new AudioContext();
        this.micInput = audioCtx.createMediaStreamSource(audioStream);
        let source2;
        if (videoStream && videoStream.getAudioTracks().length) {
            source2 = audioCtx.createMediaStreamSource(videoStream);
        }
        const destination = audioCtx.createMediaStreamDestination();

        //connect sources to destination
        // you can add gain nodes if you want
        this.micInput.connect(destination);
        if (videoStream && videoStream.getAudioTracks().length && source2) {
            source2.connect(destination);
        }

        const outputStream = new MediaStream();
        outputStream.addTrack(destination.stream.getAudioTracks()[0]);
        outputStream.addTrack(this.recorderStream!.getVideoTracks()[0]);

        this.mediaRecorder = new MediaRecorder(outputStream, {
            // https://www.animotica.com/blog/full-guide-what-is-video-bitrate-and-why-does-it-matter/
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 4000000,
            mimeType: 'video/webm;codecs=h264',
        });

        this.mediaRecorder.ondataavailable = (e) => {
            this.setState((state) => ({ chunks: [...state.chunks, e.data] }));
        };

        this.mediaRecorder.onstop = (e) => {
            // once the media recorder is stopped, convert it to a Blob of video/mp4 and reset the stream chunks.
            const blob = new Blob(this.state.chunks, { type: 'video/webm' });
            let videoURL = '';
            if (webkitURL) {
                videoURL = webkitURL.createObjectURL(blob);
            } else {
                videoURL = URL.createObjectURL(blob);
            }
            console.log('videoUrl', videoURL);
            this.setState({
                chunks: [],
                videoUriForSending: videoURL,
            });
            this.playbackVideo.current!.src = videoURL;
            // This allows us to scrub the entire feedback video instantly after finished recording
            // Otherwise, the feedback video is "streamed" and the progress bar is always at the end
            const player = this.playbackVideo.current!;
            player.addEventListener('loadedmetadata', () => {
                if (player.duration === Infinity) {
                    player.currentTime = 1e101;
                    player.addEventListener('timeupdate', getDuration)
                }
            })

            function getDuration() {
                player.currentTime = 0
                player.removeEventListener('timeupdate', getDuration)
            };
        };

        this.mediaRecorder.onerror = (err) => {
            toast.error('Sorry, we encountered an unexpected error with the recording stream');
            console.error('media recorder error', err);
        };
    }

    componentDidUpdate = () => {
        window.onbeforeunload = () => true;
    };

    async componentDidMount() {
        // @ts-ignore
        let sourceVideoSrc = this.props.location?.state?.sourceVideoUrl;

        if (!sourceVideoSrc) {
            toast.error('No source video was provided');
            // @ts-ignore
            this.props.history.replace('/');
        }

        if (sourceVideoSrc.indexOf('blob://') === -1) {
            const downloadResposne = await axios.get(sourceVideoSrc, {
                responseType: 'blob',
            });

            sourceVideoSrc = window.URL.createObjectURL(new Blob([downloadResposne.data]));
        }

        if (this.state.fileType === 'video') {
            this.sourceVideo.current!.src = sourceVideoSrc;
            once(this.sourceVideo.current, 'loadedmetadata', this.onSourceVideoLoaded);
        } else {
            this.sourceImage.current!.src = sourceVideoSrc;
            once(this.sourceImage.current, 'load', this.onSourceImageLoaded);
        }

        if (this.state.fileType === 'video') {
            // The play button container is on top of the timeline, make sure mouse events do not pass through
            this.playButtonContainer.current!.addEventListener('mousemove', (e) =>
                e.stopPropagation()
            );
            this.playButtonContainer.current!.addEventListener('mousedown', (e) =>
                e.stopPropagation()
            );
            this.playButtonContainer.current!.addEventListener('mouseup', (e) =>
                e.stopPropagation()
            );

            let t = this.sourceVideo.current!.currentTime;

            if (t === Infinity) {
                t = 0;
            }

            this.sourceVideo.current!.currentTime = t + 0.01;
        }
    }

    async componentWillUnmount() {
        if (this.handleKeydown) window.removeEventListener('keydown', this.handleKeydown);

        if (this.timelineContainer.current) {
            this.timelineContainer.current!.removeEventListener(
                'mousemove',
                this.timelineMouseMove
            );
            this.timelineContainer.current!.removeEventListener(
                'mousedown',
                this.timelineMouseDown
            );
            this.timelineContainer.current!.removeEventListener('mouseup', this.timelineMouseUp);
        }

        if (this.state.fileType === 'video') this.unmountScrollListener();

        this.props.dispatch(resetState());

        if (this.drawSourceVideoInterval) clearInterval(this.drawSourceVideoInterval);
        if (this.drawWebcamInterval) clearInterval(this.drawWebcamInterval);
        if (this.recordingInterval) clearInterval(this.recordingInterval);
        if (this.updateCurrentVideoTimeInterval) clearInterval(this.updateCurrentVideoTimeInterval);
        // @ts-ignore
        if (this.props?.location?.state?.sourceVideoUrl) {
            // @ts-ignore
            URL.revokeObjectURL(this.props?.location?.state?.sourceVideoUrl);
        }

        this.drawSourceVideoInterval = undefined;
        this.drawWebcamInterval = undefined;
        this.recordingInterval = undefined;
        this.updateCurrentVideoTimeInterval = undefined;
        this.stopRecording();
        this.mediaRecorder = undefined;
    }

    async handleSendFeedbackVideo() {
        // @ts-ignore
        const { dispatch, history } = this.props;
        this.unmountScrollListener();
        if (this.state.conversationId) {
            this.setState({
                isSendingToContact: true,
            });
            const onSuccess = () => {
                this.onSentSuccess();
                history.push({
                    pathname: routeUrls.inbox,
                    state: {
                        conversationId: this.state.conversationId,
                    },
                });
            }
            const videoId: any = await uploadFeedbackVideo(this.uploadVideo, this.uploadCanvas, this.state.videoUriForSending);
            await sendFeedbackVideo(videoId, this.state.conversationId, onSuccess);
        } else {
            dispatch(setIsDownloadSendModalOpen(true));
        }
    }

    render() {
        const {
            isMicrophoneEnabled,
            isDrawToolEnabled,
            isRecording,
            isRecordingInitialized,
            isReviewing,
            isSourceVideoPlaying,
            isLoadingSourceVideo,
            isShowingPIPImage,
            usingWebcam,
            currentVideoTime,
            paintColor,
            isDownloadSendModalOpen,
            dispatch,
        } = this.props;

        const hideIfReviewing = isReviewing
            ? { visibility: 'hidden' }
            : ({ visibility: 'visible' } as any);

        return (
            <>
                <Spinner fullPage isLoading={isLoadingSourceVideo} />
                <Prompt
                    when={this.state.showPromptWhenLeaving}
                    message="You will lose your feedback, are you sure you want to leave?"
                />
                {/*
                    Removed photo/video picker modals since "new photo" and "upload video" haven't been implemented
                    If we add them, we just need to change the buttons click fn to dispatch setIsVideo/PhotoModalOpen
                    <VideoModal onClickRecordNew={this.toggleWebcam} />
                    <ImageModal onClickUpload={() => this.imageFileInput.current!.click()} />
                */}
                <DeleteModal onConfirmDelete={this.deleteRecording} />
                {isDownloadSendModalOpen && (
                    <SendVideoModal
                        isModalOpen={true}
                        videoUri={this.state.videoUriForSending}
                        isFeedback={true}
                        closeModal={() => {
                            this.mountScrollListener();
                            dispatch(setIsDownloadSendModalOpen(false));
                        }}
                        conversationId={this.state.conversationId}
                        onSuccess={this.onSentSuccess}
                        onSuccessRoute={this.state.onSuccessRoute}
                    />
                )}
                {this.state.fileType === 'video' ? (
                    <video
                        playsInline
                        preload="auto"
                        id="sourceVideo"
                        ref={this.sourceVideo}
                        muted={false}
                        className={styles.backgroundVideo}
                    />
                ) : (
                    <img
                        alt=""
                        id="sourceImage"
                        ref={this.sourceImage}
                        className={styles.backgroundVideo}
                    />                    
                )}
                <video
                    playsInline
                    id="feedbackWebcamVideo"
                    ref={this.webcamVideo}
                    className={styles.backgroundVideo}
                />

                {/* Only used for uploading and sending feedback video */}
                <div style={{ visibility: 'hidden', position: 'absolute' }}>
                    <video ref={this.uploadVideo} src="" />
                    <canvas ref={this.uploadCanvas}></canvas>
                </div>

                <div ref={this.pageRef} className={styles.videoAnnotator}>
                    <div className={styles.outerCanvasContainer}>
                        <div className={styles.toolbarLeft}>
                            {isShowingPIPImage && (
                                <LabeledIcon
                                    IconComponent={this.state.pipFullscreen ? SizeIconFull : SizeIcon}
                                    text="Size"
                                    onClick={this.togglePhotoSize}
                                    isActive={this.state.pipFullscreen}
                                />
                            )}
                            {usingWebcam && (
                                <LabeledIcon
                                    IconComponent={SizeIcon}
                                    text="Size"
                                    onClick={this.toggleVideoSize}
                                    isActive={this.state.vipFullscreen}
                                />
                            )}
                        </div>
                        <div className={styles.fillHeight}>
                            <div className={styles.canvasContainer} ref={this.canvasContainer}>
                                <div
                                    className={styles.recordingBorderAnimation}
                                    style={isRecording ? { backgroundColor: '#EC0819AA' } : {}}
                                />
                                <canvas
                                    ref={this.recorderCanvas}
                                    className={styles.recorderCanvas}
                                />
                                {this.state.fileType === 'video' ? (
                                    <canvas
                                        ref={this.sourceVideoCanvas}
                                        className={styles.sourceVideoCanvas}
                                    />
                                ) : (
                                    <canvas
                                        ref={this.sourceImageCanvas}
                                        className={styles.sourceVideoCanvas}
                                    />
                                )}
                                <canvas
                                    ref={this.imageCanvas}
                                    className={
                                        this.state.pipFullscreen
                                            ? styles.imageCanvasFull
                                            : styles.imageCanvas
                                    }
                                />
                                <canvas
                                    ref={this.webcamCanvas}
                                    className={
                                        this.state.vipFullscreen
                                            ? styles.webcamCanvasFull
                                            : styles.webcamCanvas
                                    }
                                />
                                <DrawingCanvas
                                    ref={this.drawingCanvas}
                                    index={3}
                                    onPaint={() => null}
                                    isEnabled={isDrawToolEnabled}
                                    paintColor={paintColor}
                                />
                                <video
                                    ref={this.playbackVideo}
                                    controls
                                    className={styles.playbackVideo}
                                    style={isReviewing ? { zIndex: 4 } : { display: 'none' }}
                                />
                            </div>
                        </div>
                        <div className={styles.toolbarRight} style={hideIfReviewing}>
                            <LabeledIcon
                                IconComponent={MicrophoneIcon}
                                text="Voice"
                                onClick={this.toggleMicrophoneActive}
                                isActive={isMicrophoneEnabled}
                            />
                            <LabeledIcon
                                IconComponent={VideoIcon}
                                text="Video"
                                onClick={this.toggleWebcam}
                                isActive={usingWebcam}
                            />
                            <LabeledIcon
                                IconComponent={PhotoIcon}
                                text="Photo"
                                onClick={() =>
                                    isShowingPIPImage
                                        ? this.clearImage()
                                        : this.imageFileInput.current!.click()
                                }
                                isActive={isShowingPIPImage}
                            />
                            <LabeledIcon
                                IconComponent={DrawIcon}
                                text="Draw"
                                onClick={this.toggleDrawToolActive}
                                isActive={isDrawToolEnabled}
                            />
                            <div
                                className={styles.drawToolOptions}
                                data-is-enabled={this.props.isDrawToolEnabled}
                            >
                                <CircleSelector
                                    isSelected={paintColor === paintColors.cream}
                                    onClick={(color) => dispatch(setPaintColor(color))}
                                    color={paintColors.cream}
                                />
                                <CircleSelector
                                    isSelected={paintColor === paintColors.black}
                                    onClick={(color) => dispatch(setPaintColor(color))}
                                    color={paintColors.black}
                                />
                                <CircleSelector
                                    isSelected={paintColor === paintColors.blue}
                                    onClick={(color) => dispatch(setPaintColor(color))}
                                    color={paintColors.blue}
                                />
                                <CircleSelector
                                    isSelected={paintColor === paintColors.green}
                                    onClick={(color) => dispatch(setPaintColor(color))}
                                    color={paintColors.green}
                                />
                                <CircleSelector
                                    isSelected={paintColor === paintColors.yellow}
                                    onClick={(color) => dispatch(setPaintColor(color))}
                                    color={paintColors.yellow}
                                />
                                <CircleSelector
                                    isSelected={paintColor === paintColors.red}
                                    onClick={(color) => dispatch(setPaintColor(color))}
                                    color={paintColors.red}
                                />
                                <div
                                    className={styles.clearAll}
                                    role="button"
                                    onClick={this.clearDrawings}
                                >
                                    Clear{'\n'}All
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.controlContainer}>
                        <div className={styles.recordButtonsContainer}>
                            {isReviewing ? (
                                <>
                                    <Button
                                        inline
                                        inverted
                                        small
                                        onClick={() => dispatch(setIsDeleteModalOpen(true))}
                                    >
                                        Re-Record
                                    </Button>
                                    <Spacer horizontal size={12} />
                                    <Button
                                        inline
                                        small
                                        onClick={this.handleSendFeedbackVideo}
                                    >
                                        {this.state.isSendingToContact ? <Loader active size="small"/> : "Send"}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {isRecordingInitialized && (
                                        <RoundIconButton
                                            inverted
                                            small
                                            color="gray"
                                            onClick={this.handleDeleteRecordingClick}
                                        >
                                            <FiTrash size={20} />
                                        </RoundIconButton>
                                    )}
                                    <Spacer horizontal size={12} />
                                    <Button
                                        inline
                                        small
                                        color={isRecording ? 'red' : 'blue'}
                                        onClick={
                                            isRecording ? this.pauseRecording : this.startRecording
                                        }
                                    >
                                        {isRecording ? 'Pause' : 'Record'}
                                    </Button>
                                    <Spacer horizontal size={12} />
                                    {isRecordingInitialized && (
                                        <Button
                                            inline
                                            small
                                            inverted
                                            onClick={this.handleReviewAndSendClick}
                                        >
                                            Review & Send
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                        {this.state.fileType === 'video' ? (
                            <>
                                <div className={styles.timelineTime} style={hideIfReviewing}>
                                    {formatTime(currentVideoTime)}
                                </div>
                                <div
                                    className={styles.timelineContainer}
                                    ref={this.timelineContainer}
                                    id="timelineContainer"
                                    style={hideIfReviewing}
                                >
                                    <div
                                        className={styles.playButtonContainer}
                                        ref={this.playButtonContainer}
                                    >
                                        <RoundIconButton onClick={this.togglePlayPause}>
                                            {isSourceVideoPlaying ? (
                                                <FiPause size={20} color="white" />
                                            ) : (
                                                <FiPlay size={20} color="white" />
                                            )}
                                        </RoundIconButton>
                                    </div>
                                    <div className={styles.centerLine} />
                                    <span className={styles.triggerSpacer} />
                                    <span id="startTrigger" />
                                    <canvas
                                        ref={this.timelineCanvas}
                                        className={styles.timelineCanvas}
                                        height="48"
                                        width="100%"
                                    />
                                    <span id="endTrigger" />
                                    <span className={styles.triggerSpacer} />
                                </div>
                            </>
                        ) : null}
                        <input
                            ref={this.imageFileInput}
                            type="file"
                            onChange={this.handleImageFileChange}
                            onClick={(event) => {
                                // @ts-ignore
                                event.target.value = null;
                            }}
                            accept="image/png, image/jpeg"
                            className={styles.hiddenFileInput}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => ({ ...state.videoAnnotate });

export const VideoAnnotate = connect(mapStateToProps)(VideoAnnotator);
