import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './conversationModal.module.scss';
import { Modal } from '../modal/Modal';
import { inboxActions, useInboxSelector } from '../../features/inbox/inboxSlice';

export const ConversationModal = (props: any) => {
    const dispatch = useDispatch();
    const isDeleteModal = useInboxSelector((state) => state.setDeleteModal);

    const removeUser = () => {
        props.removeConversation();
        dispatch(inboxActions.setDeleteModal(false));
    };

    return (
        <Modal
            isOpen={isDeleteModal}
            onDimmerClick={() => dispatch(inboxActions.setDeleteModal(false))}
        >
            <div className={styles.mainContainer}>
                Are you sure you want to remove This Conversation from your contacts ?
                <div className={styles.buttonsContainer}>
                    <label className={styles.redLabel} onClick={() => removeUser()}>
                        Remove
                    </label>
                    <label
                        className={styles.blueLabel}
                        onClick={() => dispatch(inboxActions.setDeleteModal(false))}
                    >
                        Cancel
                    </label>
                </div>
            </div>
        </Modal>
    );
};
