import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import facebookIconSrc from '../../../assets/icon-facebook.svg';
import appleIconSrc from '../../../assets/icon-apple.svg';
import googleIconSrc from '../../../assets/icon-google.svg';
import emailIconSrc from '../../../assets/icon-email.svg';

import styles from './Signup.module.scss';
import { Button } from '../../../components/button/Button';
import { Link } from '../../../components/link/Link';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { Spacer } from '../../../components/spacer/Spacer';
import { signupActions, useSignupSelector } from './signupSlice';
import { GreyText } from '../../../components/greyText/GreyText';
import { signupWithApple, signupWithFacebook, signupWithGoogle } from './signupActionCreators';
import { Spinner } from '../../../components/spinner/Spinner';
import { SignupDisclaimer } from '../../../components/signupDisclaimer/SignupDisclaimer';

export const SignupMethods = () => {
    const dispatch = useDispatch();
    const { loading } = useSignupSelector((state) => state);

    useEffect(() => {
        // User may have gotten here by clicking "back", in which case we should probably refresh the state
        dispatch(signupActions.resetState());
    }, []);

    return (
        <>
            <Spinner fullPage isLoading={loading} />
            <OnboardingContainer>
                <Panel>
                    <h2 style={{ textAlign: 'center' }}>Create Your Teeq Account</h2>
                    <SignupDisclaimer />
                    <Spacer size={5} />
                    <Link to="/signup-email">
                        <Button fullWidth iconSrc={emailIconSrc}>
                            Sign up with email
                        </Button>
                    </Link>
                    <GreyText center style={{ fontSize: 10 }}>
                        OR:
                    </GreyText>
                    <Button
                        onClick={() => dispatch(signupWithFacebook())}
                        fullWidth
                        inverted
                        iconSrc={facebookIconSrc}
                    >
                        Continue with Facebook
                    </Button>
                    <Button
                        onClick={() => dispatch(signupWithApple())}
                        fullWidth
                        inverted
                        iconSrc={appleIconSrc}
                    >
                        Continue with Apple
                    </Button>
                    <Button
                        onClick={() => dispatch(signupWithGoogle())}
                        fullWidth
                        inverted
                        iconSrc={googleIconSrc}
                    >
                        Continue with Google
                    </Button>
                    <Spacer size={38} />
                    <p className={styles.bottomText}>
                        Already have an account? &nbsp;
                        <Link to="/login">Log In</Link>
                    </p>
                    <Spacer size={20} />
                </Panel>
            </OnboardingContainer>
        </>
    );
};
