import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import { updateDeviceToken } from '../auth/authActionCreators';
import { videoAnnotateActions } from '../videoAnnotate/videoAnnotateSlice';

import styles from './Home.module.scss';
import firebase from 'firebase';
import { useAuthSelector } from '../auth/authSlice';
import { initialInboxRoute } from '../../utils/routeHelers';

export const Home = () => {
    const dispatch = useDispatch();
    const { databaseUser } = useAuthSelector((s) => s);

    useEffect(() => {
        if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            messaging
                .requestPermission()
                .then((token) => {
                    return messaging.getToken();
                })
                .then((token) => {
                    dispatch(updateDeviceToken(token));
                })
                .catch((error) => {
                    console.log(error);
                });
            messaging.onMessage((payload) => {
                console.log('Payload :', payload);
            });
        }
    }, [dispatch]);

    return (
        <div className={styles.container}>
            <div className={styles.squaresContainer}>
                <Link to={initialInboxRoute(databaseUser)} className={styles.square}>
                    Go to my inbox
                </Link>
                <div
                    className={styles.square}
                    onClick={() =>
                        dispatch(videoAnnotateActions.setIsFeedbackTypePickerModalOpen(true))
                    }
                >
                    Give feedback
                </div>
                <Link to={routeUrls.record} className={styles.square}>
                    Record new photo or video
                </Link>
                <Link
                    to={routeUrls.addcontact}
                    className={styles.square}
                    style={
                        databaseUser?.orgOnly ? { pointerEvents: 'none', opacity: 0.3 } : undefined
                    }
                >
                    Add a contact
                </Link>
            </div>
        </div>
    );
};
