import { axios } from '../services/axiosService';
import { b64toBlob } from './videos';

type FileType = 'video/webm';

interface UploaderOptions {
    chunkSize?: number;
    threadsQuantity?: number;
    file: File | Blob;
    fileType?: FileType;
    thumbnailBase64String?: string;
}

interface Part {
    partNumber: number;
    signedUrl: string;
}

interface UploadedPart {
    PartNumber: number;
    ETag: string;
}

interface CustomProgressEvent {
    sent: number;
    total: number;
    percentage: number;
}

interface ApiResponse<T> {
    data: T;
}

class MultipartUploader {
    private chunkSize: number;
    private threadsQuantity: number;
    private file: File | Blob;
    private fileType: FileType | undefined;
    private thumbnailBase64String: string | undefined;
    private thumbnailPresignedUrl: string | undefined;
    private aborted: boolean;
    private uploadedSize: number;
    private progressCache: Record<number, number>;
    private activeConnections: Record<number, XMLHttpRequest>;
    private parts: Part[];
    private uploadedParts: UploadedPart[];
    private fileId: string | null;
    private fileKey: string | null;
    private videoId: string | null;
    private onProgressFn: (event: CustomProgressEvent) => void;
    private onErrorFn: (error: any) => void;
    private onCompleteFn: (videoId: string) => void;

    constructor(options: UploaderOptions) {
        this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
        this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
        this.file = options.file;
        this.fileType = options.fileType;
        this.thumbnailBase64String = options.thumbnailBase64String;
        this.aborted = false;
        this.uploadedSize = 0;
        this.progressCache = {};
        this.activeConnections = {};
        this.parts = [];
        this.uploadedParts = [];
        this.fileId = null;
        this.fileKey = null;
        this.videoId = null;
        this.onProgressFn = () => {};
        this.onErrorFn = () => {};
        this.onCompleteFn = () => {};
    }

    start(): void {
        this.initialize();
    }

    private async initialize(): Promise<void> {
        try {
            const requestData = this.fileType ? { fileType: this.fileType } : {};

            const initializeResponse: ApiResponse<{
                fileId: string;
                fileKey: string;
                thumbnailPresignedUrl: string;
                videoId: string;
            }> = await axios.request({
                url: '/videos/url-multipart',
                method: 'POST',
                data: requestData,
            });

            const AWSFileDataOutput = initializeResponse.data;

            this.fileId = AWSFileDataOutput.fileId;
            this.fileKey = AWSFileDataOutput.fileKey;
            this.thumbnailPresignedUrl = AWSFileDataOutput.thumbnailPresignedUrl;
            this.videoId = AWSFileDataOutput.videoId;

            await this.uploadThumbnail();

            const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

            const AWSMultipartFileDataInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: numberOfParts,
            };

            const urlsResponse: ApiResponse<{ parts: Part[] }> = await axios.request({
                url: '/videos/multipart-urls',
                method: 'POST',
                data: AWSMultipartFileDataInput,
            });

            const newParts = urlsResponse.data.parts;
            this.parts.push(...newParts);

            this.sendNext();
        } catch (error) {
            await this.complete(error);
        }
    }

    private sendNext(): void {
        const activeConnections = Object.keys(this.activeConnections).length;

        if (activeConnections >= this.threadsQuantity) {
            return;
        }

        if (!this.parts.length) {
            if (!activeConnections) {
                this.complete();
            }

            return;
        }

        const part = this.parts.pop();
        if (this.file && part) {
            const sentSize = (part.partNumber - 1) * this.chunkSize;
            const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

            const sendChunkStarted = () => {
                this.sendNext();
            };

            this.sendChunk(chunk, part, sendChunkStarted)
                .then(() => {
                    this.sendNext();
                })
                .catch((error) => {
                    this.parts.push(part);

                    this.complete(error);
                });
        }
    }

    private async complete(error?: any): Promise<void> {
        if (error && !this.aborted) {
            this.onErrorFn(error);
            return;
        }

        if (error) {
            this.onErrorFn(error);
            return;
        }

        try {
            await this.sendCompleteRequest();
            this.onCompleteFn(this.videoId || '');
        } catch (error) {
            this.onErrorFn(error);
        }
    }

    private async sendCompleteRequest(): Promise<void> {
        if (this.fileId && this.fileKey) {
            const videoFinalizationMultiPartInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: this.uploadedParts,
            };

            await axios.request({
                url: '/videos/complete-multipart-upload',
                method: 'POST',
                data: videoFinalizationMultiPartInput,
            });
        }
    }

    private sendChunk(chunk: Blob, part: Part, sendChunkStarted: () => void): Promise<void> {
        return new Promise((resolve, reject) => {
            this.upload(chunk, part, sendChunkStarted)
                .then((status) => {
                    if (status !== 200) {
                        reject(new Error('Failed chunk upload'));
                        return;
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private handleProgress(part: number, event: any): void {
        if (this.file) {
            if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
                this.progressCache[part] = event.loaded;
            }

            if (event.type === 'uploaded') {
                this.uploadedSize += this.progressCache[part] || 0;
                delete this.progressCache[part];
            }

            const inProgress = Object.keys(this.progressCache)
                .map(Number)
                .reduce((memo, id) => (memo += this.progressCache[id]), 0);

            const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

            const total = this.file.size;

            const percentage = Math.round((sent / total) * 100);

            this.onProgressFn({
                sent: sent,
                total: total,
                percentage: percentage,
            });
        }
    }

    private uploadThumbnail(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.thumbnailPresignedUrl && this.thumbnailBase64String) {
                const blob = b64toBlob(this.thumbnailBase64String, 'image/png');
                const xhr = new XMLHttpRequest();

                xhr.open('PUT', this.thumbnailPresignedUrl);

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        resolve();
                    }
                };

                xhr.onerror = (error) => {
                    reject(error);
                };

                xhr.onabort = () => {
                    reject(new Error('Upload canceled by user'));
                };

                xhr.send(blob);
            }
        });
    }

    private upload(file: Blob, part: Part, sendChunkStarted: () => void): Promise<number> {
        // uploading each part with its pre-signed URL
        return new Promise((resolve, reject) => {
            if (this.fileId && this.fileKey) {
                // - 1 because partNumber is an index starting from 1 and not 0
                const xhr = (this.activeConnections[part.partNumber - 1] = new XMLHttpRequest());

                sendChunkStarted();

                const progressListener = this.handleProgress.bind(this, part.partNumber - 1);

                xhr.upload.addEventListener('progress', progressListener);

                xhr.addEventListener('error', progressListener);
                xhr.addEventListener('abort', progressListener);
                xhr.addEventListener('loadend', progressListener);

                xhr.open('PUT', part.signedUrl);

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        // retrieving the ETag parameter from the HTTP headers
                        const ETag = xhr.getResponseHeader('ETag');

                        if (ETag) {
                            const uploadedPart = {
                                PartNumber: part.partNumber,
                                // removing the " enclosing characters from
                                // the raw ETag
                                ETag: ETag.replaceAll('"', ''),
                            };

                            this.uploadedParts.push(uploadedPart);

                            resolve(xhr.status);
                            delete this.activeConnections[part.partNumber - 1];
                        }
                    }
                };

                xhr.onerror = (error) => {
                    reject(error);
                    delete this.activeConnections[part.partNumber - 1];
                };

                xhr.onabort = () => {
                    reject(new Error('Upload canceled by user'));
                    delete this.activeConnections[part.partNumber - 1];
                };

                xhr.send(file);
            }
        });
    }

    onProgress(onProgress: (event: CustomProgressEvent) => void): MultipartUploader {
        this.onProgressFn = onProgress;
        return this;
    }

    onError(onError: (error: any) => void): MultipartUploader {
        this.onErrorFn = onError;
        return this;
    }

    onComplete(onComplete: (videoId: string) => void): MultipartUploader {
        this.onCompleteFn = onComplete;
        return this;
    }

    abort(): void {
        Object.keys(this.activeConnections)
            .map(Number)
            .forEach((id) => {
                this.activeConnections[id].abort();
            });

        this.aborted = true;
    }
}

export default MultipartUploader;
