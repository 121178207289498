import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { Loader } from 'semantic-ui-react';

import { useAppStateSelector } from '../../../store/utilities/useAppStateSelector';
import styles from './personalInbox.module.scss';
import commonStyles from './../inbox.module.scss';
import { FiChevronRight, FiPaperclip, FiUserPlus, FiSend, FiInfo } from 'react-icons/fi';
import { formatMessageSentAt, getNamesCharacters } from '../../../utils/helpers';
import {
    getCurrentMessages,
    getInboxData,
    getNewMessages,
    getVideoList,
    loadMoreMessages,
    removeAConversation,
    sendContent,
    unreadCountUpdater,
} from '../inboxActionsCreator';
import { inboxActions } from '../inboxSlice';
import { Fragment } from 'react';
import { toast } from 'react-toastify';

import { routeUrls } from '../../../utils/constants';
import { useAuthSelector } from '../../auth/authSlice';
import { ConversationDetail } from '../ConversationDetail';
import { Message } from '../../../components/message/Message';
import { InboxTabContainer } from '../../../components/inboxTabContainer/InboxTabContainer';
import { GreyText } from '../../../components/greyText/GreyText';
import { axios } from '../../../services/axiosService';
import { FilePicker } from '../../../components/filePicker/FilePicker';
import { Spinner } from '../../../components/spinner/Spinner';
import MultipartUploader from '../../../utils/multipartUploader';

export const PersonalOrganization = () => {
    const [userSelected, setUserSelected] = useState('');
    const [hover, setHover] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [inputMessage, setInputMessage] = useState('');
    const [myInterval, setMyInterval] = useState<any>();
    const [setInfo, setInfoSection] = useState(false);
    const [onload, setOnLoad] = useState(true);
    const [imageUploading, setImageUploading] = useState(false);
    const [isFirstMessageAppearing, setIsFirstMessageAppearing] = useState(true);

    const location = useLocation<{ conversationId?: string }>();

    const initialConversationId = location?.state?.conversationId;

    const inboxData = useAppStateSelector((state) => state.inbox);
    const currentInbox = useAppStateSelector((state) => state.inbox.currentMessages);
    const userId = useAppStateSelector((state) => state.auth.databaseUser?.id);
    const user = useAuthSelector((state) => state.databaseUser);

    const dispatcher = useDispatch();
    const history = useHistory();

    const { contactRequests } = inboxData;

    const emptyInbox = !inboxData.inboxUsers.length;

    // show loading if inbox is empty and data is not loaded
    const messagesLoading =
        !!initialConversationId && !inboxData.loadCompleted && !currentInbox.messages?.length;

    useEffect(() => {
        if (user?.orgOnly) {
            return;
        }

        let conversationId = '';
        if (initialConversationId) {
            conversationId = initialConversationId;
        } else if (inboxData.inboxUsers.length) {
            conversationId = inboxData.inboxUsers[0].conversation.id;
        }

        if (conversationId) {
            getMessages(conversationId);
            dispatcher(getVideoList(conversationId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxData.inboxUsers.length, user, initialConversationId]);

    useEffect(() => {
        return () => {
            clearInterval(myInterval);
        };
    }, [myInterval]);

    const removeConversation = (id: any) => {
        dispatcher(removeAConversation(id));
        setUserSelected('');
        setInfoSection(false);
        clearInterval(myInterval);
    };

    const getMessages = async (id: string) => {
        clearInterval(myInterval);
        setUserSelected(id);
        dispatcher(getCurrentMessages(id, false));
        setPageNumber(1);
        let updatedConversations = inboxData.inboxUsers.map((item) => {
            if (item.conversation.id === id) {
                return { ...item, conversation: { ...item.conversation, unread: 0 } };
            }
            return item;
        });
        dispatcher(inboxActions.setInboxUsers(updatedConversations));
        dispatcher(getInboxData());
        setOnLoad(false);
        await loadMessagesOnInterval(id);
    };

    const loadData = async () => {
        if (!currentInbox.loadCompleted) {
            dispatcher(loadMoreMessages(currentInbox.conversation.id, pageNumber + 1, false));
            setPageNumber(pageNumber + 1);
        }
    };

    const loadMessagesOnInterval = async (id: string) => {
        const interval = setInterval(() => {
            let time = new Date(Date.now() - 3000).toISOString();
            dispatcher(getNewMessages(id, time, userId, false));
        }, 10000);
        setMyInterval(interval);
    };

    const isMe = (id: String) => {
        return id === userId;
    };

    const isOther = (id: String) => {
        return id !== userId;
    };

    const extractMyProfileImageLink = () => {
        return isMe(currentInbox.users[0].id)
            ? currentInbox.users[0].profileImage
            : currentInbox.users[1].profileImage;
    };

    const sendMessage = () => {
        if (!inputMessage) return;
        dispatcher(
            sendContent(
                currentInbox.conversation.id,
                inputMessage,
                userId,
                false,
                getNamesCharacters(user?.firstName + ' ' + user?.lastName),
                extractMyProfileImageLink()
            )
        );

        dispatcher(getInboxData());
        setInputMessage('');
    };

    const extractName = (item: any) => {
        return isMe(item.users[0].id)
            ? item.users[1].firstName + ' ' + item.users[1].lastName
            : item.users[0].firstName + ' ' + item.users[0].lastName;
    };

    const extractProfileImage = (item: any) => {
        return isOther(item.users[0].id) ? item.users[0].profileImage : item.users[1].profileImage;
    };

    const extractID = (item: any) => {
        return isMe(item.users[0].id) ? item.users[1].id : item.users[0].id;
    };

    const toggleInfoSection = () => {
        setInfoSection(!setInfo);
    };

    const afterFileChange = async (file: File, thumbnail: string) => {
        setImageUploading(true);
        const isVideo = file.type.indexOf('video') > -1;

        if (isVideo) {
            // Multiple upload for video
            let percentage: number | undefined = undefined;

            const uploader = new MultipartUploader({ file, thumbnailBase64String: thumbnail });

            uploader
                .onProgress(({ percentage: newPercentage }) => {
                    if (newPercentage !== percentage) {
                        percentage = newPercentage;
                        console.log(`${percentage}%`);
                    }
                })
                .onError((error) => {
                    console.error(error);
                    toast.error('There was a problem uploading the video. Please try again later.');
                })
                .onComplete((videoId) => {
                    createMessage({ videoId });
                });

            uploader.start();
        } else {
            // Single upload for image
            let presignedUrlResponse;
            try {
                presignedUrlResponse = await axios.post('/images', {
                    fileType: file.type,
                });

                await axios.put(presignedUrlResponse.data.url, file, {
                    headers: {
                        'Content-Type': file.type,
                    },
                });

                createMessage({ imageId: presignedUrlResponse.data.imageId });
            } catch {
                console.log('Error uploading image');
                toast.error('There was a problem uploading the image. Please try again later.');
            }
        }
    };

    const createMessage = async ({ videoId, imageId }: { videoId?: string; imageId?: string }) => {
        try {
            const payload = {
                conversationId: currentInbox.conversation.id,
                contents: '',
                generateThumbnail: false,
                videoId,
                imageId,
            };

            await axios.post('/conversations/messages', payload);

            getMessages(currentInbox.conversation.id);
            dispatcher(getVideoList(currentInbox.conversation.id));
            dispatcher(getInboxData());
            setImageUploading(false);
        } catch {
            console.log('Error creating message');
            toast.error('There was a problem sending the message. Please try again later.');
        }
    };

    const contactRequestsPlurality =
        contactRequests.length === 1 ? 'Contact Request' : 'Contact Requests';
    const contactRequestsText = `${contactRequests.length} ${contactRequestsPlurality}`;

    return (
        <div style={{ height: '100%' }}>
            <Spinner fullPage isLoading={messagesLoading} />
            <div className={commonStyles.inboxContainer}>
                <div className={commonStyles.inboxSubContainer}>
                    <label className={commonStyles.inboxHeaderHeading}>Inbox</label>
                    {!user?.orgOnly ? (
                        <FiUserPlus
                            size={24}
                            onClick={() => history.push(routeUrls.addcontact)}
                            className={commonStyles.cursorer}
                        />
                    ) : null}
                </div>
                <InboxTabContainer />
                <div className={styles.centerAlign}>
                    {!user?.orgOnly ? (
                        <div
                            className={
                                !hover
                                    ? styles.contactRequestContainer
                                    : styles.contactRequestContainerHover
                            }
                            onClick={() => history.push(routeUrls.inboxContactRequests)}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <label className={styles.contactRequestHeading}>
                                {contactRequestsText}
                            </label>
                            <FiChevronRight size={20} className={styles.contactRequestRightArrow} />
                        </div>
                    ) : null}
                </div>

                {user?.orgOnly ? (
                    <div></div>
                ) : (
                    <div>
                        {inboxData.inboxUsers.map((item, index) => {
                            return (
                                <div
                                    onClick={async () => {
                                        await getMessages(item.conversation.id);
                                        setInfoSection(false);
                                        dispatcher(getVideoList(item.conversation.id));
                                    }}
                                    key={index}
                                    className={
                                        item.conversation.id === userSelected
                                            ? commonStyles.userTabContainer
                                            : commonStyles.userTabContainerUn
                                    }
                                >
                                    <div className={styles.myUserContainerInbox}>
                                        <div className={commonStyles.containerPadding}>
                                            <div
                                                className={
                                                    item.conversation.unread > 0
                                                        ? item.conversation.id === userSelected
                                                            ? commonStyles.coloringTabSelect
                                                            : commonStyles.coloringTabSelect
                                                        : item.conversation.id === userSelected
                                                        ? commonStyles.coloringTabSelect
                                                        : commonStyles.coloringTabUnSelect
                                                }
                                            ></div>
                                        </div>
                                        {item ? (
                                            extractProfileImage(item) ? (
                                                <img
                                                    className={styles.roundImageList}
                                                    src={extractProfileImage(item)}
                                                    alt="Profile"
                                                />
                                            ) : (
                                                <div className={commonStyles.roundName}>
                                                    {getNamesCharacters(extractName(item))}
                                                </div>
                                            )
                                        ) : (
                                            ''
                                        )}
                                        <div className={commonStyles.requestMiddleContainer}>
                                            <label className={commonStyles.bold}>
                                                {extractName(item)}
                                            </label>
                                            <div
                                                className={
                                                    item.lastMessage?.videoId
                                                        ? commonStyles.italic
                                                        : ''
                                                }
                                            >
                                                <label
                                                    className={
                                                        item.conversation.id !== userSelected
                                                            ? commonStyles.messagePreview
                                                            : styles.selected
                                                    }
                                                >
                                                    {item.lastMessage?.videoId
                                                        ? 'Video'
                                                        : item.lastMessage?.contents
                                                        ? item.lastMessage.contents.slice(0, 20) +
                                                          (item.lastMessage.contents.length > 20
                                                              ? '...'
                                                              : '')
                                                        : ''}{' '}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={commonStyles.rightSideInboxUser}>
                                        <label>
                                            {item.lastMessage
                                                ? formatMessageSentAt(item.lastMessage?.sentAt)
                                                : ''}
                                        </label>
                                        {item.lastMessage?.videoId ? (
                                            <div>
                                                <FiPaperclip color="grey" size={16} />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            {user?.orgOnly ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    You do not have an active subscription but are able to continue using your
                    organizations.
                </div>
            ) : (
                <div style={{ height: '100%' }}>
                    {emptyInbox ? (
                        <div className={commonStyles.emptyInboxContainer}>
                            <GreyText>Empty inbox. Add a contact to liven things up.</GreyText>
                        </div>
                    ) : (
                        <div>
                            {setInfo ? (
                                <Fragment>
                                    {Object.keys(currentInbox).length === 0 ? (
                                        ''
                                    ) : (
                                        <ConversationDetail
                                            name={extractName(currentInbox)}
                                            id={extractID(currentInbox)}
                                            toggle={() => toggleInfoSection()}
                                            type={'personal'}
                                            removeConversation={removeConversation}
                                        />
                                    )}
                                </Fragment>
                            ) : (
                                <div>
                                    <div className={commonStyles.messagesContainers}>
                                        <div className={commonStyles.messageContainerHeader}>
                                            <div className={commonStyles.mcHeaderSetting}>
                                                <div className={commonStyles.mcHeaderLeft}>
                                                    {currentInbox.users ? (
                                                        extractProfileImage(currentInbox) ===
                                                        null ? (
                                                            <div
                                                                className={
                                                                    commonStyles.roundNameSelected
                                                                }
                                                            >
                                                                {getNamesCharacters(
                                                                    currentInbox.users
                                                                        ? extractName(currentInbox)
                                                                        : ''
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <img
                                                                className={
                                                                    styles.roundImageMessageScreen
                                                                }
                                                                src={extractProfileImage(
                                                                    currentInbox
                                                                )}
                                                                alt="profile"
                                                            />
                                                        )
                                                    ) : (
                                                        ''
                                                    )}

                                                    <div className={commonStyles.mcHeaderRight}>
                                                        {currentInbox.users
                                                            ? extractName(currentInbox)
                                                            : ''}
                                                    </div>
                                                </div>
                                                <FiInfo
                                                    size={30}
                                                    style={{
                                                        paddingRight: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setInfoSection(true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {!inboxData.loadCompleted ? (
                                            <Spinner
                                                isLoading={true}
                                                className={commonStyles.messagesContainerBodyLoader}
                                            />
                                        ) : (
                                            <div className={commonStyles.messagesContainerBody}>
                                                {imageUploading && (
                                                    <div
                                                        className={
                                                            commonStyles.messageLoadingContainer
                                                        }
                                                    >
                                                        <Loader active size="small" inline />
                                                    </div>
                                                )}
                                                {(currentInbox.messages ?? []).map(
                                                    (message: any, index: number) => {
                                                        if (message.deleted) return null;
                                                        const renderMessage = (
                                                            <Message
                                                                message={message}
                                                                conversationId={
                                                                    currentInbox.conversation.id
                                                                }
                                                            />
                                                        );
                                                        if (
                                                            currentInbox.messages.length ===
                                                            index + 1
                                                        ) {
                                                            return (
                                                                <InView
                                                                    key={index}
                                                                    onChange={(inView) => {
                                                                        if (inView) {
                                                                            loadData();
                                                                        }
                                                                    }}
                                                                >
                                                                    <div>{renderMessage}</div>
                                                                </InView>
                                                            );
                                                        } else if (index === 0) {
                                                            return (
                                                                <InView
                                                                    key={index}
                                                                    onChange={(inView) => {
                                                                        if (inView) {
                                                                            setIsFirstMessageAppearing(
                                                                                true
                                                                            );
                                                                        } else {
                                                                            setIsFirstMessageAppearing(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <div>{renderMessage}</div>
                                                                </InView>
                                                            );
                                                        }
                                                        return (
                                                            <div key={index}>{renderMessage}</div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                        <div className={commonStyles.messageContainerFooter}>
                                            <div className={commonStyles.flexContainer}>
                                                <FilePicker afterFileChange={afterFileChange} />
                                                <div className={commonStyles.flexExtendToEnd}>
                                                    <div
                                                        className={[
                                                            commonStyles.inputContainer,
                                                            commonStyles.inputMessage,
                                                        ].join(' ')}
                                                    >
                                                        <input
                                                            className={commonStyles.inputField}
                                                            placeholder="Message..."
                                                            value={inputMessage}
                                                            readOnly={onload}
                                                            onKeyPress={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    sendMessage();
                                                                }
                                                            }}
                                                            onChange={(event) =>
                                                                setInputMessage(event.target.value)
                                                            }
                                                        />
                                                        <div
                                                            className={commonStyles.sendMessageIcon}
                                                            style={
                                                                !inputMessage
                                                                    ? {
                                                                          backgroundColor:
                                                                              'transparent',
                                                                      }
                                                                    : {}
                                                            }
                                                        >
                                                            <FiSend
                                                                size={22}
                                                                onClick={() => {
                                                                    sendMessage();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {inboxData.unreadMessages === 0 ? (
                                        <div></div>
                                    ) : (
                                        <div>
                                            {!isFirstMessageAppearing ? (
                                                <div
                                                    onClick={() => dispatcher(unreadCountUpdater())}
                                                    className={commonStyles.newMessages}
                                                >
                                                    New Messages ({inboxData.unreadMessages}) Click
                                                    to View
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
