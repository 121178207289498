import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './messageActionModal.module.scss';
import { Modal } from '../modal/Modal';
import { FiTrash } from 'react-icons/fi';
import { FiFlag } from 'react-icons/fi';
import { actionOnMessage } from '../../features/inbox/inboxActionsCreator';
import { inboxActions, useInboxSelector } from '../../features/inbox/inboxSlice';

const { setMessageModal } = inboxActions;

export const MessageActionModal: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const {
        showMessageAction,
        actionOnOtherUserMessage,
        actionMessageId,
        actionConversationId,
        actionOnType,
    } = useInboxSelector((state) => state);

    const handleActionOnMessage = (action: string) => {
        dispatch(actionOnMessage(actionConversationId, actionMessageId, action, actionOnType));
    };
    return (
        <Modal
            isOpen={showMessageAction}
            onDimmerClick={() => dispatch(setMessageModal({ value: false }))}
        >
            <div>
                <div className={styles.title}>Message Actions</div>
                <div className={styles.buttonssContainer}>
                    {actionOnOtherUserMessage ? (
                        <div
                            className={styles.customHeading}
                            onClick={() => {
                                handleActionOnMessage('delete');
                            }}
                        >
                            <FiTrash size={24} />
                            <label className={styles.paddingLeft}>Delete Message</label>
                        </div>
                    ) : (
                        <div
                            className={styles.customHeading}
                            onClick={() => {
                                handleActionOnMessage('flag');
                            }}
                        >
                            <FiFlag size={24} color="red" />
                            <label className={styles.paddingLeft}>Flag Message</label>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
