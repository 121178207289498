import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from '../../../components/button/Button';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { Spacer } from '../../../components/spacer/Spacer';
import { TextInput } from '../../../components/textInput/TextInput';
import { signupActions, useSignupSelector } from './signupSlice';
import { PanelParagraphText } from '../../../components/panelParagraphText/PanelParagraphText';
import { finishSignup } from './signupActionCreators';
import { SignupDisclaimer } from '../../../components/signupDisclaimer/SignupDisclaimer';

export const SignupAdditionalInformation = () => {
    const dispatch = useDispatch();
    const { loading } = useSignupSelector((state) => state);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const onClickBack = () => {
        dispatch(signupActions.setUsername(''));
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!firstName || !lastName) {
            return toast.error('Please enter a first and last name');
        }
        dispatch(finishSignup(firstName, lastName));
    };

    return (
        <OnboardingContainer>
            <Panel backFn={onClickBack}>
                <h2>Finish Creating Your Teeq Account</h2>
                <PanelParagraphText>Almost there!</PanelParagraphText>
                <Spacer size={20} />
                <form onSubmit={onSubmit}>
                    <TextInput
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                    />
                    <Spacer size={2} />
                    <TextInput
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                    />
                    <Spacer size={10} />
                    <Button disabled={loading}>Continue</Button>
                </form>
                <Spacer size={5} />
                <SignupDisclaimer />
            </Panel>
        </OnboardingContainer>
    );
};
