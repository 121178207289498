import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import styles from './Signup.module.scss';
import { Button } from '../../../components/button/Button';
import { Link } from '../../../components/link/Link';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Panel } from '../../../components/panel/Panel';
import { Spacer } from '../../../components/spacer/Spacer';
import { TextInput } from '../../../components/textInput/TextInput';
import { emailRegex, getPasswordErrorIfInsufficient } from '../../../utils/helpers';
import { signupWithEmailAndPassword } from './signupActionCreators';
import { SignupDisclaimer } from '../../../components/signupDisclaimer/SignupDisclaimer';

export const SignupEmail = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const dispatch = useDispatch();

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValidEmail = emailRegex.test(email);
        if (!isValidEmail) {
            return toast.error('Please enter a valid email');
        }
        const passwordError = getPasswordErrorIfInsufficient(password);
        if (passwordError) {
            return toast.error(passwordError);
        }
        dispatch(signupWithEmailAndPassword(email, password));
    };

    return (
        <OnboardingContainer>
            <Panel backUrl="/signup">
                <h2>Enter your email address and choose a password</h2>
                <Spacer size={5} />
                <form onSubmit={onSubmit}>
                    <TextInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email"
                    />
                    <Spacer size={2} />
                    <TextInput
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Your Password"
                    />
                    <div className={styles.passwordCriteria}>
                        <div>Password Criteria:</div>
                        <div>• 8 characters or longer</div>
                        <div>• At least 1 uppercase letter</div>
                        <div>• At least 1 lowercase letter</div>
                        <div>• At least 1 number</div>
                    </div>
                    <Spacer size={20} />
                    <Button>Next</Button>
                </form>
                <Spacer size={10} />
                <SignupDisclaimer />
                <Spacer size={45} />
                <p className={styles.bottomText}>
                    Already have an account? &nbsp;
                    <Link to="/login">Log In</Link>
                </p>
            </Panel>
        </OnboardingContainer>
    );
};
