import React, { SetStateAction, useState } from 'react';

import styles from './SubscriptionPicker.module.scss';
import { subOptions } from '../../utils/constants';

interface SubscriptionPickerProps {
    selected: string;
    setSelected: React.Dispatch<SetStateAction<subOptions>>;
}

export const SubscriptionPicker: React.FunctionComponent<SubscriptionPickerProps> = ({
    selected,
    setSelected,
}) => {
    console.log('selected', selected);
    const leftClassNames = `${styles.subscriptionOption} ${styles.left} ${
        selected === subOptions.Annual ? styles.selected : ''
    }`;
    const rightClassNames = `${styles.subscriptionOption} ${styles.right} ${
        selected === subOptions.Monthly ? styles.selected : ''
    }`;

    return (
        <div className={styles.subscriptionOptionsContainer}>
            <div className={leftClassNames} onClick={() => setSelected(subOptions.Annual)}>
                <div className={styles.subscriptionOptionTitle}>Annual</div>
                <div className={styles.subscriptionOptionPrimaryPrice}>$59.99/year</div>
                <div className={styles.subscriptionOptionSecondaryPrice}>$5/mo</div>
            </div>
            <div className={rightClassNames} onClick={() => setSelected(subOptions.Monthly)}>
                <div className={styles.subscriptionOptionTitle}>Monthly</div>
                <div className={styles.subscriptionOptionPrimaryPrice}>$5.99/mo</div>
            </div>
        </div>
    );
};
