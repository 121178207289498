import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './VideoAnnotate.module.scss';
import { Modal } from '../../components/modal/Modal';
import { useVideoAnnotateSelector, videoAnnotateActions } from './videoAnnotateSlice';
import { Spacer } from '../../components/spacer/Spacer';
import { ClickableText } from '../../components/clickableText/ClickableText';

const { setIsDeleteModalOpen } = videoAnnotateActions;

interface DeleteModalProps {
    confirmButtonText?: string;
    message?: string;
    onConfirmDelete: () => void;
}

export const DeleteModal: React.FunctionComponent<DeleteModalProps> = ({
    confirmButtonText,
    message,
    onConfirmDelete,
}) => {
    const dispatch = useDispatch();
    const { isDeleteModalOpen } = useVideoAnnotateSelector((state) => state);

    const handleConfirmDelete = async () => {
        await onConfirmDelete();
        dispatch(setIsDeleteModalOpen(false));
    };

    return (
        <Modal
            isOpen={isDeleteModalOpen}
            onDimmerClick={() => dispatch(setIsDeleteModalOpen(false))}
        >
            <div className={styles.deleteModalHeaderText}>
                {message ||
                    'This action will delete your recording. Are you sure you wish to delete your feedback?'}
            </div>
            <ClickableText color="red" onClick={handleConfirmDelete}>
                {confirmButtonText || 'Delete Feedback'}
            </ClickableText>
            <Spacer horizontal size={80} inlineFlex />
            <ClickableText onClick={() => dispatch(setIsDeleteModalOpen(false))}>
                Cancel
            </ClickableText>
        </Modal>
    );
};
