import React from 'react';

import styles from './CircleSelector.module.scss';

interface CircleSelectorProps {
    isSelected: boolean;
    onClick: (color: string) => void;
    color: string;
}

export const CircleSelector: React.FunctionComponent<CircleSelectorProps> = (props) => {
    const { isSelected, onClick, color } = props;

    let innerCircleClassname = styles.innerCircle;
    if (isSelected) innerCircleClassname += ` ${styles.selected}`;

    return (
        <div
            className={styles.outerCircle}
            style={{ backgroundColor: color }}
            onClick={() => onClick(color)}
            role="button"
        >
            <div className={innerCircleClassname} />
        </div>
    );
};
