import { toast } from 'react-toastify';
import { drawImageScaled } from '../features/videoAnnotate/VideoAnnotate';
import { axios } from '../services/axiosService';
import MultipartUploader from './multipartUploader';

/**
 Returns the dimensions of a video asynchrounsly.
 @param {String} url Url of the video to get dimensions from.
 @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
*/
export const getVideoDimensionsOf = (url: string) => {
    return new Promise<any>((resolve) => {
        // create the video element
        const video = document.createElement('video');

        // place a listener on it
        video.addEventListener(
            'loadedmetadata',
            function () {
                // retrieve dimensions
                const height = this.videoHeight;
                const width = this.videoWidth;
                // send back result
                resolve({ height, width });
            },
            false
        );

        // start download meta-datas
        video.src = url;
    });
};

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const uploadFeedbackVideo = (videoRef: any, canvasRef: any, videoUri: string) => {
    return new Promise((resolve, reject) => {
        let videoId;
        videoRef.current.src = videoUri;
        requestAnimationFrame(() => {
            setTimeout(
                () =>
                    (async () => {
                        try {
                            const { height, width } = await getVideoDimensionsOf(videoUri);
                            canvasRef.current.width = width;
                            canvasRef.current.height = height;
                            drawImageScaled(
                                videoRef.current,
                                width * 2,
                                height * 2,
                                canvasRef.current.getContext('2d'),
                                canvasRef.current.width,
                                canvasRef.current.height,
                                false
                            );
                            const thumbnail = canvasRef.current.toDataURL('image/png');

                            const videoBlob = await fetch(videoUri).then((video) => video.blob());

                            let percentage: number | undefined = undefined;

                            const uploader = new MultipartUploader({
                                file: videoBlob,
                                fileType: 'video/webm',
                                thumbnailBase64String: thumbnail,
                            });

                            uploader
                                .onProgress(({ percentage: newPercentage }) => {
                                    if (newPercentage !== percentage) {
                                        percentage = newPercentage;
                                        console.log(`${percentage}%`);
                                    }
                                })
                                .onError((error) => {
                                    console.error(error);
                                    toast.error(
                                        'There was a problem uploading the feedback. Please try again later.'
                                    );
                                    reject(error);
                                })
                                .onComplete((videoId) => {
                                    console.log('Video uploaded successfully');
                                    resolve(videoId);
                                });

                            uploader.start();
                        } catch (err) {
                            reject(err);
                        }
                    })(),
                250
            );
        });
    });
};

enum SendStatus {
    unsent = 'unsent',
    sending = 'sending',
    sent = 'sent',
}

export const sendFeedbackVideo = (
    videoId: string,
    conversationId: string,
    onSubmitSuccess = () => {},
    setSendingStatus = (args: any) => {},
    setProgress = (args: any) => {}
) => {
    return new Promise(async (resolve, reject) => {
        try {
            setSendingStatus(SendStatus.sending);

            const payload = {
                conversationId,
                contents: '',
                videoId: videoId,
                generateThumbnail: false,
            };

            const config = {
                onUploadProgress: (progressEvent: any) => {
                    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                },
            };
            const messageId = (await axios.post('/conversations/messages', payload, config)).data;

            setSendingStatus(SendStatus.sent);
            onSubmitSuccess();
            resolve(messageId);
        } catch (err) {
            setSendingStatus(SendStatus.unsent);
            console.error(err);
            reject(err);
        }
    });
};
