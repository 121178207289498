import React, { useEffect } from 'react';
import { useAuthSelector } from '../authSlice';
import { useDispatch } from 'react-redux';
import { loadUser } from '../authActionCreators';
import { Spinner } from '../../../components/spinner/Spinner';
import firebase from 'firebase';
export const AuthInitializer: React.FunctionComponent = (props) => {
    const dispatch = useDispatch();

    const { initializationState, loading } = useAuthSelector((state) => state);
    const isInitialized = initializationState === 'initialized';

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged(async (user) => {
            dispatch(loadUser());
        });
        return () => {
            unsub();
        };
    }, [dispatch]);

    return (
        <>
            <Spinner fullPage isLoading={loading} />
            {isInitialized === true && <>{props.children}</>}
        </>
    );
};
