import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/dimmer.min.css';

import styles from './Spinner.module.scss';

export interface SpinnerProps {
    fullPage?: boolean;
    isLoading: boolean;
    className?: string;
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({ fullPage = false, isLoading, className = '' }) => {
    if (fullPage)
        return (
            <Dimmer active={isLoading}>
                <Loader active={isLoading} />
            </Dimmer>
        );
    return <Loader className={[styles.spinner, className].join(' ')} active={isLoading} />;
};

export { Spinner };
