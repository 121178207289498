import firebase from 'firebase';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { axios } from '../../services/axiosService';
import { authActions } from './authSlice';
import { loginActions } from './login/loginSlice';

export const logout = () => async (dispatch: Dispatch) => {
    try {
        const response = await firebase.auth().signOut();
        window.location.pathname = '/';
        console.log(response);
    } catch (err) {
        console.error(err);
    }
};

export const loadUser = () => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(authActions.setLoading(true));
        dispatch(authActions.setInitializing());
        await firebase.auth().currentUser?.reload();
        let databaseUser = null;
        let firebaseUser = null;
        const accessToken = await firebase.auth().currentUser?.getIdToken();
        if (accessToken) {
            databaseUser = (await axios.get('/Users/AuthUser')).data;
            firebaseUser = firebase.auth().currentUser?.toJSON();
        }
        return dispatch(authActions.setInitialized({ databaseUser, firebaseUser }));
    } catch (error) {
        dispatch(authActions.setErrorInitializing());
        console.error(error);
    } finally {
        dispatch(authActions.setLoading(false));
    }
};

export const sendEmailVerification =
    (isResend = false) =>
    async (dispatch: Dispatch) => {
        try {
            await firebase.auth().currentUser?.reload();
            const emailVerified = firebase.auth().currentUser?.emailVerified;
            if (!emailVerified) {
                await firebase.auth().currentUser?.sendEmailVerification();
                if (isResend) toast.info('Verification email re-sent');
            }
        } catch (err) {
            console.error(err);

            let errMsg;
            if (err.code && err.message) {
                errMsg = `Error "${err.code}": ${err.message}`;
            } else {
                errMsg = 'Could not send verification email, please try again later';
            }

            toast.error(errMsg, { autoClose: false });
        }
    };

export const handleVerifyEmail = (actionCode: string) => async (dispatch: Dispatch<any>) => {
    try {
        const applyActionCodeResponse = await firebase.auth().applyActionCode(actionCode);
        dispatch(loadUser());
        console.log('apply action code response', applyActionCodeResponse);
    } catch (err) {
        console.error('Could not verify email', err);
        toast.error(`Could not verify email: ${err.message}`);
    }
};

export const sendPasswordResetEmail = (email: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(loginActions.setLoading(true));

        await firebase.auth().sendPasswordResetEmail(email);
        console.log('email sent');
    } catch (err) {
        console.error(`Failed to send password reset email to email: ${email}`);
        console.error(err);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};

export const updateUser = (file: File, databaseUser: any) => async (dispatch: Dispatch) => {
    try {
        const formdata = new FormData();
        formdata.append('', file);

        const imageResponse = await axios.post('/user/upload_image');
        const s3ImageUploadResult = await axios.put(imageResponse.data.url, file, {
            headers: {
                'Content-Type': 'image/jpg',
            },
        });

        dispatch(authActions.setPhotoURL(imageResponse.data.resultUrl));
        const updatedUserData = {
            firstName: databaseUser?.firstName,
            lastName: databaseUser?.lastName,
            profileImage: imageResponse.data.resultUrl,
            username: databaseUser?.username,
        };
        const response = await axios.put('/users/' + databaseUser?.id, updatedUserData);
        if (response.status === 200) {
            toast.success('Profile Image Updated Successfully');
        }
    } catch (err) {
        toast.error('Error While Uploading Picture');
        console.error(err);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};

export const DeleteProfilePicture = (databaseUser: any) => async (dispatch: Dispatch) => {
    try {
        dispatch(authActions.setPhotoURL(null));
        firebase
            .auth()
            .currentUser?.updateProfile({ photoURL: '' })
            .then(() => {})
            .catch((error) => {
                console.log(error);
            });
        const updatedUserData = {
            firstName: databaseUser?.firstName,
            lastName: databaseUser?.lastName,
            profileImage: null,
            username: databaseUser?.username,
        };
        const response = await axios.put('/users/' + databaseUser?.id, updatedUserData);
        if (response.status === 200) {
            toast.success('Profile Image Deleted Successfully');
        }
    } catch (err) {
        toast.error('Error While Deleting Profile Picture');
        console.error(err);
    } finally {
        dispatch(loginActions.setLoading(false));
    }
};

export const updateDeviceToken = (token: any) => async (dispatch: Dispatch) => {
    try {
        await axios.post('/user/devices', { deviceType: 'web', deviceToken: token });
    } catch (err) {
        toast.error(err.message);
        console.error(err);
    }
};
