import { useState } from 'react';

import { Panel } from '../../../components/panel/Panel';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Spacer } from '../../../components/spacer/Spacer';
import { Button } from '../../../components/button/Button';
import { GreyText } from '../../../components/greyText/GreyText';
import { subOptions } from '../../../utils/constants';
import { Link } from '../../../components/link/Link';
import { SubscriptionPicker } from '../../../components/subscriptionPicker/SubscriptionPicker';

export const ChooseSubscription = () => {
    const [selected, setSelected] = useState<subOptions>(subOptions.Annual);
    return (
        <OnboardingContainer>
            <Panel>
                <h2>Choose Your Subscription</h2>
                <p>
                    After you choose your billing cycle, we'll start you off with a{' '}
                    <b>free 2-week trial.</b>
                </p>
                <Spacer size={60} />
                <SubscriptionPicker selected={selected} setSelected={setSelected} />
                <Spacer size={40} />
                <Link to={`/payment/${selected}`}>
                    <Button>Subscribe</Button>
                </Link>
                <Spacer size={10} />
                <GreyText semiTransparent center>
                    Your card will be charged 14 days from today.
                </GreyText>
                <Spacer size={15} />
                <GreyText semiTransparent center>
                    Subscriptions billed as one-time payments. Recurring billing.
                </GreyText>
                <Spacer size={15} />
                <GreyText semiTransparent center>
                    Cancel anytime to stop future charges.
                </GreyText>
            </Panel>
        </OnboardingContainer>
    );
};
