import { Fragment } from 'react';
import styles from './Toggle.module.scss';

export const Toggle = (props: any) => {
    return (
        <Fragment>
            <div
                className={props.selected ? styles.outerSelect : styles.outerUnselect}
                onClick={() => props.onClick()}
            >
                <div className={styles.innerContainer}>
                    {props.selected ? <div></div> : null}
                    <div className={styles.whiteBox}></div>
                </div>
            </div>
        </Fragment>
    );
};
