import React from 'react';

import styles from './GreyText.module.scss';

interface GreyTextProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    bold?: boolean;
    semiTransparent?: boolean;
    block?: boolean;
    center?: boolean;
}

export const GreyText: React.FunctionComponent<GreyTextProps> = (props) => {
    const { bold, semiTransparent, block, center, children, className, ...passThroughProps } =
        props;

    let classList = `${styles.greyText}`;
    if (bold) classList += ` ${styles.bold}`;
    if (semiTransparent) classList += ` ${styles.semiTransparent}`;
    if (block) classList += ` ${styles.block}`;
    if (center) classList += ` ${styles.center}`;
    if (className) classList += ` ${className}`;
    if (passThroughProps.onClick) classList += ` ${styles.clickable}`;
    return (
        <span className={classList} {...passThroughProps}>
            {children}
        </span>
    );
};
