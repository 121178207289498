import React, { useState } from 'react';
import firebase from 'firebase';
import { toast } from 'react-toastify';

import styles from './InitiatePasswordReset.module.scss';
import { TextInput } from '../../../components/textInput/TextInput';
import { Button } from '../../../components/button/Button';
import { Panel } from '../../../components/panel/Panel';
import { OnboardingContainer } from '../../../components/onboardingContainer/OnboardingContainer';
import { Link } from '../../../components/link/Link';
import { Spacer } from '../../../components/spacer/Spacer';
import { config } from '../../../config';
import { emailRegex } from '../../../utils/helpers';

const actionCodeSettings: firebase.auth.ActionCodeSettings = {
    url: `${config.webUrl}/login-email`,
    handleCodeInApp: true,
};

export const InitiatePasswordReset: React.FunctionComponent = () => {
    const [email, setEmail] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const isValidEmail = emailRegex.test(email);
        if (!isValidEmail) {
            toast.error('Please enter a valid email');
            return;
        }
        try {
            await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
            toast.success('Email sent');
            setSubmitSuccess(true);
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const renderPreSubmit = (
        <Panel backUrl="/login-email">
            <form onSubmit={handleSubmit} className={styles.passwordResetForm}>
                <h2 style={{ textAlign: 'center' }}>Reset Your Password</h2>
                <Spacer size={5} />
                <p className={styles.subtitle}>
                    Please enter your email address so we can send you an email to reset your
                    password.
                </p>
                <TextInput
                    value={email}
                    placeholder="Your Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button type="submit">Send Password Reset Email</Button>
            </form>
        </Panel>
    );

    const renderPostSubmit = (
        <Panel>
            <h2 style={{ textAlign: 'center' }}>Please Check Your Email</h2>
            <Spacer size={5} />
            <p className={styles.subtitle}>
                We sent you an email to reset your password. Please check your email inbox and
                follow the link we sent you.
            </p>
            <Spacer size={3} />
            <Link to="/login">
                <Button>Back to Log In Screen</Button>
            </Link>
            <Spacer size={40} />
            <p style={{ textAlign: 'center' }}>
                Didnt receive an email?{' '}
                <Link to="#" onClick={handleSubmit}>
                    Resend email
                </Link>
            </p>
        </Panel>
    );

    return (
        <OnboardingContainer>
            {submitSuccess ? renderPostSubmit : renderPreSubmit}
        </OnboardingContainer>
    );
};
