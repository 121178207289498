import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import styles from './PanelParagraphText.module.scss';

interface PanelParagraphTextProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    center?: boolean;
}

export const PanelParagraphText: React.FunctionComponent<PanelParagraphTextProps> = ({
    children,
    center,
    ...passThroughProps
}) => {
    let classList = styles.panelParagraphText;
    if (center) classList += ` ${styles.center}`;
    return (
        <div className={classList} {...passThroughProps}>
            {children}
        </div>
    );
};
