import firebase from 'firebase';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router } from 'react-router-dom';

import './styles/index.scss';
import App from './App';
import { config } from './config';
import { AuthInitializer } from './features/auth/authInitializer/AuthInitializer';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

const rrfConfig = {};

firebase.initializeApp(config.firebase);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
};

console.log('Version: ' + config.version);

ReactDOM.render(
    <>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <Router>
                    <AuthInitializer>
                        <App />
                    </AuthInitializer>
                </Router>
            </ReactReduxFirebaseProvider>
        </Provider>
    </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export default firebase;
