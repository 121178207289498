import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './FeedbackTypePickerModal.module.scss';
import { Modal } from '../modal/Modal';
import {
    useVideoAnnotateSelector,
    videoAnnotateActions,
} from '../../features/videoAnnotate/videoAnnotateSlice';
import { Spacer } from '../spacer/Spacer';
import { FiCamera, FiUpload } from 'react-icons/fi';
import { Link } from '../link/Link';

const { setIsFeedbackTypePickerModalOpen } = videoAnnotateActions;

interface FeedbackTypePickerModalProps {
    openFeedbackFilePicker: () => void;
}

export const FeedbackTypePickerModal: React.FunctionComponent<FeedbackTypePickerModalProps> = ({
    openFeedbackFilePicker,
}) => {
    const dispatch = useDispatch();
    const { isFeedbackTypePickerModalOpen } = useVideoAnnotateSelector((state) => state);

    const handleUseExisting = () => {
        openFeedbackFilePicker();
        dispatch(setIsFeedbackTypePickerModalOpen(false));
    };

    return (
        <Modal
            isOpen={isFeedbackTypePickerModalOpen}
            onDimmerClick={() => dispatch(setIsFeedbackTypePickerModalOpen(false))}
        >
            <div onClick={handleUseExisting} className={styles.link}>
                <FiUpload className={styles.icon} /> Use saved photo or video
            </div>
            <Spacer size={12} />
            <Link
                unstyled
                to="/record?feedback=true"
                onClick={() => dispatch(setIsFeedbackTypePickerModalOpen(false))}
            >
                <div className={styles.link}>
                    <FiCamera className={styles.icon} /> Record new
                </div>
            </Link>
        </Modal>
    );
};
