import { Fragment, useEffect } from 'react';
import commonStyles from './../Settings.module.scss';
import { useHistory } from 'react-router';

import { Feedback } from './Feedback';
import { Spacer } from '../../../components/spacer/Spacer';
import { useDispatch } from 'react-redux';
import { updatePreference, getPreferences } from '../settingActionsCreator';
import { FiChevronLeft } from 'react-icons/fi';
import { useSettingsSelector } from '../settingsSlice';
import { routeUrls } from '../../../utils/constants';

export const FeedbackPreference = () => {
    const history = useHistory();
    const dispatcher = useDispatch();
    const photo = useSettingsSelector((state) => state.photoPreference);
    const video = useSettingsSelector((state) => state.videoPreference);

    useEffect(() => {
        dispatcher(getPreferences());
    }, [dispatcher]);

    const toggle = (value: String, isPhoto: Boolean) => {
        if (isPhoto) {
            dispatcher(updatePreference(photo.id, 7, value));
        } else {
            dispatcher(updatePreference(video.id, 8, value));
        }
    };

    return (
        <Fragment>
            <div className={commonStyles.globalStylingForAccount}>
                <div className={commonStyles.mainHeader}>
                    <FiChevronLeft
                        className={commonStyles.cursorPointer}
                        size={24}
                        onClick={() => history.push(routeUrls.settings)}
                    />
                    <label className={commonStyles.backTitle}>Feedback Preferences</label>
                </div>
            </div>
            <div className={commonStyles.globalStylingForAccount}>
                <div
                    className={commonStyles.mainBody}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <Feedback
                        toggle={toggle}
                        icon="video"
                        preference={video.value}
                        title={'Insert Video Default Behaviour'}
                        isPhoto={false}
                    />
                    <Spacer size={10} />
                    <Feedback
                        toggle={toggle}
                        icon="camera"
                        preference={photo.value}
                        title={'Insert Photo Default Behaviour'}
                        isPhoto={true}
                    />
                </div>
            </div>
        </Fragment>
    );
};
