import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import styles from '../Settings.module.scss';
import poweredByStripeSrc from '../../../assets/powered-by-stripe.svg';
import { SettingsContainer } from '../../../components/settingsContainer/SettingsContainer';
import { Button } from '../../../components/button/Button';
import { Spacer } from '../../../components/spacer/Spacer';
import { SettingsContainerPopout } from '../../../components/settingsContainer/SettingsContainerPopout';
import { SubscriptionPicker } from '../../../components/subscriptionPicker/SubscriptionPicker';
import { routeUrls, subOptions } from '../../../utils/constants';
import { config } from '../../../config';
import { authActions, useAuthSelector } from '../../auth/authSlice';
import { Modal } from '../../../components/modal/Modal';
import { ClickableText } from '../../../components/clickableText/ClickableText';
import { axios } from '../../../services/axiosService';
import { loadUser } from '../../auth/authActionCreators';

const BillingCyclePopout = ({ setPopout }: any) => {
    const { databaseUser } = useAuthSelector((s) => s);
    const dispatch = useDispatch();

    const [selected, setSelected] = useState<subOptions>(
        databaseUser!.subscriptionCycle === 'Monthly' ? subOptions.Monthly : subOptions.Annual
    );

    const handleSave = async () => {
        dispatch(authActions.setLoading(true));
        try {
            const apiResponse = (
                await axios.put('/User/Subscription', { newCycle: selected, cancel: false })
            ).data;
            toast.success(
                `Subscription updated. You'll see the change reflected on the expiration date below.`
            );
            dispatch(loadUser());
            dispatch(authActions.setLoading(false));
            setPopout(null);
        } catch (error) {
            console.error(error);
            toast.error('Sorry, something went wrong. Please contact Teeq.');
        } finally {
            dispatch(authActions.setLoading(false));
        }
    };

    return (
        <SettingsContainerPopout title="Billing Cycle" onClickClose={() => setPopout(null)}>
            <SubscriptionPicker selected={selected} setSelected={setSelected} />
            <Spacer size={15} />
            <Button onClick={handleSave}>Save</Button>
        </SettingsContainerPopout>
    );
};

const UpdatePaymentPopout = ({ setPopout }: any) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(authActions.setLoading(true));
        try {
            const { token, error } = await stripe!.createToken(
                elements!.getElement(CardElement) as any
            );
            if (error) throw new Error(error.message);
            if (!token || !token.id) throw new Error('Missing token or token id');
            const apiResponse = (await axios.put('/Users/UpdateCard', { tokenId: token.id })).data;
            if (apiResponse) {
                toast.success(`Payment updated successfully`);
                dispatch(loadUser());
            } else {
                toast.error('An unknown error occured.');
            }
        } catch (error) {
            console.error(error);
            toast.error(
                'Sorry, something went wrong. Please check your payment details and try again'
            );
        } finally {
            dispatch(authActions.setLoading(false));
        }
    };

    return (
        <SettingsContainerPopout title="Update Payment" onClickClose={() => setPopout(null)}>
            <form onSubmit={handleSubmit}>
                <Spacer size={5} />
                <CardElement />
                <Spacer size={30} />
                <Button type="submit" disabled={!stripe}>
                    Add
                </Button>
            </form>
            <Spacer size={44} />
            <div className={styles.center}>
                <img src={poweredByStripeSrc} />
            </div>
        </SettingsContainerPopout>
    );
};

const CancelSubscriptionModal = ({
    isCancelSubscriptionModalOpen,
    setIsCancelSubscriptionModalOpen,
    subscriptionExpirationDate,
}: any) => {
    const dispatch = useDispatch();
    const closeModal = () => setIsCancelSubscriptionModalOpen(false);

    const handleClickCancel = async () => {
        dispatch(authActions.setLoading(true));
        try {
            const apiResponse = (
                await axios.put('/User/Subscription', { newCycle: 'None', cancel: true })
            ).data;
            toast.success(`Subscription canceled.`);
            dispatch(loadUser());
            dispatch(authActions.setLoading(false));
            closeModal();
        } catch (error) {
            console.error(error);
            toast.error('Sorry, something went wrong. Please contact Teeq.');
        } finally {
            dispatch(authActions.setLoading(false));
        }
    };

    return (
        <Modal
            isOpen={isCancelSubscriptionModalOpen}
            onDimmerClick={closeModal}
            onCloseButtonClick={closeModal}
            horizontalPanelPadding
        >
            <div>
                Are you sure you wish to cancel your Teeq subscription? You will lose access at the
                end of your current billing cycle (
                {dayjs(subscriptionExpirationDate).format('MMMM D, YYYY')})
            </div>
            <Spacer size={20} />
            <div className={styles.subscriptionModalOptions}>
                <ClickableText color="red" onClick={handleClickCancel}>
                    Cancel Subscription
                </ClickableText>
                <ClickableText color="blue" onClick={closeModal}>
                    Keep Subscription
                </ClickableText>
            </div>
        </Modal>
    );
};

export const SubscriptionSettings = () => {
    const stripeKey = config.stripe.publicKey || '';
    const stripePromise = loadStripe(stripeKey);
    const [popout, setPopout] = useState<any>(null);
    const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
        useState<boolean>(false);
    const { databaseUser } = useAuthSelector((s) => s);
    return (
        <>
            <CancelSubscriptionModal
                isCancelSubscriptionModalOpen={isCancelSubscriptionModalOpen}
                setIsCancelSubscriptionModalOpen={setIsCancelSubscriptionModalOpen}
                subscriptionExpirationDate={databaseUser?.subscriptionExpirationDate}
            />
            <Elements stripe={stripePromise}>
                <SettingsContainer
                    title="My Subscription"
                    padded
                    popout={popout}
                    backUrl={routeUrls.settings}
                >
                    <div className={styles.contentTitle}>Subscription</div>
                    <Spacer size={16} />
                    {databaseUser?.subscriptionCycle !== 'None' && (
                        <>
                            <div className={styles.subscriptionSettingsRow}>
                                <div className={styles.subscriptionSettingTitle}>Billing Cycle</div>
                                <div className={styles.subscriptionSetting}>
                                    {databaseUser?.subscriptionCycle}
                                </div>
                                <Button
                                    style={{
                                        visibility:
                                            databaseUser?.subscriptionPlatform === 'web'
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                    small
                                    onClick={() =>
                                        setPopout(<BillingCyclePopout setPopout={setPopout} />)
                                    }
                                >
                                    Change Billing Cycle
                                </Button>
                            </div>
                            <Spacer size={20} />
                        </>
                    )}
                    <div className={styles.subscriptionSettingsRow}>
                        {databaseUser?.subscriptionCycle === 'None' ? (
                            <>
                                <div className={styles.subscriptionSettingTitle}>
                                    Expiration Date
                                </div>
                                <div className={styles.subscriptionSetting}>
                                    {dayjs(databaseUser?.subscriptionExpirationDate).format(
                                        'MMMM D, YYYY'
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.subscriptionSettingTitle}>Next Payment</div>
                                <div className={styles.subscriptionSetting}>
                                    {databaseUser?.subscriptionCycle === 'Monthly'
                                        ? '$5.99'
                                        : '$59.99'}
                                    {'\n'}
                                    {dayjs(databaseUser?.subscriptionExpirationDate).format(
                                        'MMMM D, YYYY'
                                    )}
                                </div>
                                <Button
                                    small
                                    inverted
                                    onClick={() => setIsCancelSubscriptionModalOpen(true)}
                                >
                                    Cancel Subscription
                                </Button>
                            </>
                        )}
                    </div>
                    {databaseUser?.subscriptionPlatform === 'web' &&
                        databaseUser.subscriptionCycle !== 'None' && (
                            <>
                                <Spacer size={180} />
                                <hr className={styles.hr} />
                                <Spacer size={24} />
                                <div className={styles.contentTitle}>Payment Method</div>
                                <Spacer size={16} />
                                <div className={styles.subscriptionSettingsRow}>
                                    {databaseUser.usedCard && (
                                        <div>
                                            {databaseUser.cardBrand} ending in{' '}
                                            {databaseUser.cardLast4}, exp.:{' '}
                                            {databaseUser.cardExpMonth}/{databaseUser.cardExpYear}
                                        </div>
                                    )}
                                    <Button
                                        small
                                        onClick={() =>
                                            setPopout(<UpdatePaymentPopout setPopout={setPopout} />)
                                        }
                                    >
                                        Update Credit Card
                                    </Button>
                                </div>
                            </>
                        )}
                </SettingsContainer>
            </Elements>
        </>
    );
};
