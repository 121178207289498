import React from 'react';

import styles from './OnboardingContainer.module.scss';

export const OnboardingContainer: React.FunctionComponent = ({ children }) => (
    <>
        <div className={styles.onboardingContainer}>
            {children}
            <div className={styles.footer}>
                <a href="https://teeqapp.com/privacy" target="_blank">
                    Privacy Policy
                </a>
                <a href="https://teeqapp.com/terms" target="_blank">
                    Terms of Use
                </a>
            </div>
        </div>
    </>
);
